import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { InfoIcon } from "../RemixIcons";
import styles from "./GuestLoginHeader.module.scss";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api/Api";
import { signIn } from "aws-amplify/auth";
import { Alert, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import WelcomeHeader from "./WelcomeHeader";

export type Props = {
  providerName?: string;
  previewMode?: boolean;
};

const GuestLoginHeader: FC<Props> = ({ providerName, previewMode }) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation("i18n");

  const handleGuestConnection = async () => {
    setLoading(true);
    const access = await api.createGuestAccess({
      link: location.pathname,
    });

    await signIn({
      password: access.password,
      username: access.userName,
    });
    window.location.reload();
    setLoading(false);
  };

  const onInfoClick = () => {
    toast.info(t("connection.guest.description"), {
      autoClose: 10000,
    });
  };

  return (
    <WelcomeHeader disabled={previewMode}>
      <div className={styles.guest}>
        <Alert key={"info"} variant="info">
          <p className={styles.guestTitle}>
            {t("connection.guest.message.title")}
          </p>
          <p className={styles.guestText}>
            {t("connection.guest.message.text", {
              name: providerName || "Seen-apps",
            })}
          </p>
          <p className={styles.guestFooter}>
            {t("connection.guest.message.footer")}
          </p>
        </Alert>

        <div className={styles.guestContent}>
          <div className={styles.loadingButton}>
            {isLoading ? (
              <Button
                className={styles.button}
                disabled={isLoading || previewMode}
                onClick={() => handleGuestConnection()}
              >
                <div className={styles.loaderContainer}>
                  <ReactLoading
                    className={styles.loader}
                    type={"bars"}
                    color={"#DDD"}
                    height={"20px"}
                    width={"25px"}
                  />
                  {t("common.connecting")}
                </div>
              </Button>
            ) : (
              <>
                <div>
                  <Button
                    className={styles.button}
                    disabled={isLoading || previewMode}
                    onClick={() => handleGuestConnection()}
                  >
                    {t("connection.guest.connection")}
                  </Button>
                </div>
                <div>
                  <span className={styles.guestMode}>
                    {t("connection.guest.mode")}
                  </span>
                  <InfoIcon onClick={onInfoClick} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </WelcomeHeader>
  );
};

export default GuestLoginHeader;
