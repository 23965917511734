import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "./i18n";
import "./index.css";
import "./custom_theme.scss";
import "rc-tree/assets/index.css";
import "./styles/rc-tree.scss";
import "./styles/react-toastify.scss";
import "./styles/react-tags-input.scss";

import "react-datepicker/dist/react-datepicker.min.css";
import "./styles/react-datepicker.scss";

import "react-date-range/dist/styles.css"; // main style file
import "./styles/react-date-range.scss";
import "./styles/reactdata-grid-blue-dark.css";

import config from "./config";
import * as Sentry from "@sentry/react";
import { ServiceWorkerProvider } from "./context/ServiceWorkerProvider";
import { createRoot } from "react-dom/client";

const isLocal = config.Env === "local";

if (!isLocal) {
  Sentry.init({
    dsn: "https://e10c3909288049f5b3adbdfb6e786e31@o311291.ingest.sentry.io/6095112",
    environment: config.Env,
    release: `${import.meta.env.VITE_APP_VERSION}`,
    integrations: [],
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <I18nextProvider i18n={i18next}>
    <ServiceWorkerProvider>
      <App />
    </ServiceWorkerProvider>
  </I18nextProvider>,
);
