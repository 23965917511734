import React, { useEffect, useState } from "react";
import styles from "./TextAnswerComponent.module.scss";
import { DateMeasureType } from "../../../@types/form-api";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import moment from "moment-timezone";
import AutoHeightTextarea from "../../input/AutoHeightTextarea";

interface DateAnswerComponentProps {
  id: string;
  measureType: DateMeasureType;
  value?: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  printMode?: boolean;
}

const DEFAULT_FORMAT = "DD/MM/YYYY";

const DateAnswerComponent: React.FC<DateAnswerComponentProps> = ({
  measureType,
  id,
  printMode,
  value,
  onChange,
  disabled,
}) => {
  const handleDateChange = (date: Date) => {
    if (onChange) {
      onChange(moment(date).format(format));
    }
  };

  const [date, setDate] = useState<Date>();
  const [format, setFormat] = useState<string>();

  useEffect(() => {
    if (!format) {
      return;
    }
    setDate(value ? moment(value, format).toDate() : undefined);
  }, [value, format]);

  useEffect(() => {
    setFormat(measureType.dateOptions.format || DEFAULT_FORMAT);
  }, [measureType.dateOptions]);

  return printMode ? (
    <AutoHeightTextarea
      className={`${styles.text} ${disabled ? styles.disabled : ""}`}
      key={`dateinput-${id}`}
      rows={1}
      value={value || ""}
      disabled
      singleLine
    />
  ) : (
    <div className={`${styles.date} printDateAnswer`}>
      <DatePicker
        customInput={
          format && (
            <Button variant="secondary">
              {date
                ? moment(date).format(format)
                : format.replace(/[A-Z,a-z]/g, "_")}
            </Button>
          )
        }
        disabled={disabled}
        selected={date}
        onChange={handleDateChange}
        className={styles.datePicker}
        yearDropdownItemNumber={10}
        showYearDropdown
      />
    </div>
  );
};

export default DateAnswerComponent;
