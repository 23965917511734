import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Feeling, FeelingGoal } from "../../../@types/seen-apps";
import al from "../../../assets/images/al.webp";

import styles from "./GoalForm.module.scss";
import GlobalStateContext from "../../../context/globalState/GlobalStateContext";
import Card from "../../Card/Card";
import GoalHitBadge, { GoalHitProps } from "./GoalHitBadge";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { GoalIcon, PreviewAccessIcon } from "../../RemixIcons";
import GoalFormModal from "./GoalFormModal";
import AbilityContext, { Can } from "../../../context/AbilityContext";
import { subject } from "@casl/ability";
import { useAbility } from "@casl/react";
import FeelingAIModal from "../../../pages/feeling/FeelingAIModal";
import { ChatSuggestion, ContextQuestion } from "../../Chat/ChatDialog";
import { getWordTranslated } from "../../../tools/feelingTools";

type Report = Record<string, GoalHitProps[]>;

type GoalResumeProps = {
  values: FeelingGoal[];
  disabled?: boolean;
  feeling: Feeling;
  onChange: (value: FeelingGoal) => void;
  onDelete: (goal: FeelingGoal) => void;
};

const getKey = (level: number) => {
  return level === -2
    ? "stronglyAway"
    : level === -1
      ? "away"
      : level === 1
        ? "closer"
        : level === 2
          ? "stronglyCloser"
          : "none";
};

const GoalResume = ({
  reportKey,
  items,
}: {
  reportKey: string;
  items: GoalHitProps[] | undefined;
}) => {
  const { t } = useTranslation("i18n");

  return !items?.length ? (
    <></>
  ) : (
    <div className={styles.goalResumeContainer}>
      <div className={styles.goalResumeTitle}>
        {t(
          `pages.feeling.goal.resume.${reportKey}_${
            items.length > 1 ? "many" : "one"
          }`,
        )}
      </div>
      {items.map((goal, index) => (
        <GoalHitBadge key={index} {...goal} />
      ))}
    </div>
  );
};

const GoalsResume = ({
  values,
  disabled,
  feeling,
  onChange,
  onDelete,
}: GoalResumeProps) => {
  const { t } = useTranslation("i18n");
  const { state } = useContext(GlobalStateContext);
  const ability = useAbility(AbilityContext);

  const [report, setReport] = useState<Report>({});
  const [goalToUpdate, setGoalToUpdate] = useState<FeelingGoal>();
  const [aiContext, setAiContext] = useState<{
    mode: string;
    initiationText: string;
    contextQuestions?: ContextQuestion[];
    suggestions?: ChatSuggestion[];
    disableFreeText?: boolean;
  }>();
  const [aiShow, setAiShow] = useState(false);

  useEffect(() => {
    const report: Report = {};

    values.forEach((goal) => {
      const key = getKey(goal.level);
      const subGoals = goal.subs.filter((s) => s.level);
      const reportRow = report[key] || [];
      if (goal.level || !subGoals.length) {
        reportRow.push({
          goalId: goal.id,
          ranking: goal.ranking,
          subGoalId: undefined,
          level: goal.level,
        });
      }
      report[key] = reportRow;
      subGoals.forEach((subGoal) => {
        if (!subGoal.level) {
          return;
        }
        const key = getKey(subGoal.level);
        const reportRow = report[key] || [];

        reportRow.push({
          goalId: goal.id,
          subGoalId: subGoal.id,
          level: subGoal.level,
          ranking: goal.ranking,
        });
        report[key] = reportRow;
      });
    });
    setReport(report);
  }, [feeling, state, t, values]);

  const handleUpdateGoal = (goal: FeelingGoal) => {
    setGoalToUpdate(goal);
  };

  const handleUpdateGoalClosed = () => {
    setGoalToUpdate(undefined);
  };

  const handleGoalUpdated = (goal: FeelingGoal) => {
    setGoalToUpdate(undefined);
    onChange(goal);
  };
  const handleGoalDeleted = () => {
    if (goalToUpdate) {
      onDelete(goalToUpdate);
      setGoalToUpdate(undefined);
    }
  };

  const handleActionsSuggestion = () => {
    const text = [
      "stronglyAway",
      "stronglyCloser",
      "away",
      "closer",
      "none",
    ].reduce(
      (acc, key) => {
        const goals = report[key];
        if (!goals?.length) {
          return acc;
        }
        return [
          ...acc,
          `- ${t(
            `pages.feeling.goal.resume.${key}_${
              goals.length > 1 ? "many" : "one"
            }`,
          )}: ${goals
            .map(({ goalId, subGoalId }) =>
              subGoalId
                ? t(`report.goalForm.axes.${goalId}.sub.${subGoalId}`)
                : t(`report.goalForm.axes.${goalId}.label`),
            )
            .join(", ")}`,
        ];
      },
      [
        t(`pages.feeling.goal.resume.description`, {
          word: getWordTranslated(state, feeling.wordId),
        }),
      ],
    );

    setAiContext({
      mode: "feeling-goal-action",
      initiationText:
        text.join("\r\n") +
        "\r\n" +
        t("pages.feeling.extra.actions-suggestions"),
      suggestions: [
        {
          id: "close",
          text: t(`pages.feeling.extra.toPersonalNotes`),
          onlyOnce: true,
          closeDialogAfter: true,
        },
      ],
      disableFreeText: false,
    });
    setAiShow(true);
  };

  return (
    <div className={styles.resume}>
      {!Object.keys(report).length && disabled ? (
        t("pages.feeling.goal.noValue")
      ) : !Object.keys(report).length ? (
        <></>
      ) : (
        <Card
          title={t(`pages.feeling.goal.resume.description`, {
            word: getWordTranslated(state, feeling.wordId),
          })}
          actionIcon={
            disabled ? (
              <></>
            ) : (
              <div className={styles.actions}>
                <Can
                  I="post"
                  a={subject("chat-thread", {}) as any}
                  ability={ability}
                >
                  <Button
                    className={styles.chatButton}
                    variant="outline-primary"
                    onClick={handleActionsSuggestion}
                  >
                    <PreviewAccessIcon />
                    <img className={styles.profileImg} src={al} alt="AL" />
                  </Button>
                </Can>
                <DropdownButton
                  title={"..."}
                  className={styles.actionsButton}
                  variant="outline-primary"
                  id="actions-dropdown"
                >
                  {values.map((goal) => (
                    <Dropdown.Item
                      key={`update-goal-${goal}`}
                      onClick={() => {
                        handleUpdateGoal(goal);
                      }}
                    >
                      <GoalIcon /> {t(`report.goalForm.axes.${goal.id}.label`)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            )
          }
        >
          <div className={styles.resumeContent}>
            {["stronglyAway", "stronglyCloser", "away", "closer", "none"].map(
              (key) => (
                <GoalResume
                  key={`goal-report-${key}`}
                  reportKey={key}
                  items={report[key]}
                />
              ),
            )}
          </div>
        </Card>
      )}
      {goalToUpdate && (
        <GoalFormModal
          show
          onClose={handleUpdateGoalClosed}
          value={goalToUpdate}
          onChange={handleGoalUpdated}
          onDelete={handleGoalDeleted}
        />
      )}
      {aiContext && (
        <FeelingAIModal
          show={aiShow}
          mode={aiContext.mode}
          feeling={feeling}
          initiationText={aiContext.initiationText}
          contextQuestions={aiContext.contextQuestions}
          suggestions={aiContext.suggestions}
          disableFreeText={aiContext.disableFreeText}
          onClose={() => setAiShow(false)}
        />
      )}
    </div>
  );
};

export default GoalsResume;
