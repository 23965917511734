import { TFunction } from "i18next";
import { ApiError } from "@aws-amplify/core/internals/utils";

export const getErrorTranslation = ({
  t,
  error,
  defaultKey,
}: {
  t: TFunction;
  error: unknown;
  defaultKey?: string;
}) => {
  const defaultError = defaultKey ? t(defaultKey) : t("common.error");

  if (!error || !(error instanceof ApiError)) {
    return defaultError;
  }

  if (!error.response?.body) {
    if (error.name) {
      return t(`api.error.${error.name}`);
    }
    return defaultError;
  }

  try {
    const response = JSON.parse(error.response.body);

    if (response?.data?.message) {
      return t(response.data.message);
    }

    if (response?.data && Array.isArray(response.data)) {
      const messages = response.data
        .map((data: any) => {
          return data.key
            ? t(`api.error.${data.key}`, data.value ?? {})
            : undefined;
        })
        .filter((d: any) => !!d);
      return messages.length > 0 ? messages.join("; ") : defaultError;
    }

    if (response?.data && response?.data?.key) {
      return t(`api.error.${response.data.key}`, response.data.value ?? {});
    }
  } catch {
    // ignore json parse error
  }

  if (error.name) {
    return t(`api.error.${error.name}`);
  }
  return defaultError;
};
