import { FC } from "react";

type DividerProps = {
  variant?: "flashy" | "default";
} & React.HTMLAttributes<HTMLHRElement>;

const Divider: FC<DividerProps> = ({ variant, className, ...props }) => {
  return (
    <hr
      {...props}
      className={`${className || ""} divider ${`divider_${variant ?? "default"}`}`}
    />
  );
};

export default Divider;
