import React, { FC, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ConfirmToast from "../components/toasts/ConfirmToast";
import { useTranslation } from "react-i18next";
import { useRegisterSW } from "virtual:pwa-register/react"; // -> see: https://vite-pwa-org.netlify.app/frameworks/react.html

const CHECK_UPDATE_INTERVAL_IN_MILLIS = import.meta.env
  .VITE_CHECK_UPDATE_INTERVAL_IN_MILLIS
  ? parseInt(`${import.meta.env.VITE_CHECK_UPDATE_INTERVAL_IN_MILLIS}`)
  : 3600000; // default to every 1 hour if no env variable is set

export type Props = {
  enableCheckForUpdatePolling?: boolean;
};

export const ServiceWorkerProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  enableCheckForUpdatePolling = true,
}) => {
  const { t } = useTranslation("i18n");
  const [fromAutoUpdate, setFromAutoUpdate] = useState(false);

  const {
    updateServiceWorker,
    needRefresh: [needRefresh, setNeedRefresh],
  } = useRegisterSW({
    onRegisteredSW(swUrl, registration) {
      if (process.env.NODE_ENV !== "production") {
        registration?.unregister();
        return;
      }
      console.info(`Service worker registered: ${swUrl}`);
      if (registration && enableCheckForUpdatePolling) {
        setInterval(() => {
          setFromAutoUpdate(true);
          registration.update();
        }, CHECK_UPDATE_INTERVAL_IN_MILLIS);
      }
    },
  });

  const promptUserToRefresh = useCallback(() => {
    toast(
      ({ closeToast }) => (
        <ConfirmToast
          content={t("app.confirmUpdate")}
          onConfirm={updateServiceWorker}
          onCancel={() => {
            setNeedRefresh(false);
            closeToast?.();
          }}
        />
      ),
      {
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        className: "toast-default",
      },
    );
  }, [t, updateServiceWorker, setNeedRefresh]);

  useEffect(() => {
    if (needRefresh && fromAutoUpdate) {
      // A new version was detected by the polling, so we prompt the user to refresh
      promptUserToRefresh();
    } else if (needRefresh && !fromAutoUpdate) {
      // The page was just loaded, so we can force update the service worker
      updateServiceWorker();
    }
  }, [needRefresh, fromAutoUpdate, promptUserToRefresh, updateServiceWorker]);

  return <>{children}</>;
};
