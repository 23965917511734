import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Condition, Definition } from "../AddOrUpdateTagsSelectionPage";

import OperatorInput from "../../../../components/input/OperatorInput";
import GenderInput from "../../../../components/input/GenderInput";
import { DraggableIcon } from "../../../../components/RemixIcons";
import TagsInput from "../../../../components/input/TagsInput";

import styles from "./ConditionInput.module.scss";

export const ConditionInput = ({
  field,
  condition,
  index,
  handleChange,
  handleDelete,
  sphereId,
  definition,
  handleProps,
  operators,
}: {
  condition: Condition;
  field: string;
  sphereId: string;
  index: number;
  definition?: Definition;
  handleChange: (condition: Condition) => void;
  handleDelete: () => void;
  handleProps: object;
  operators: string[];
}) => {
  const { t } = useTranslation("i18n");

  return (
    <div className={styles.conditionLine}>
      <div className={styles.dragHandle} {...handleProps}>
        <DraggableIcon className={styles.icon} />
        <div className={styles.condition}>
          {t(
            `pages.sphere.tags-selections.section.form.labels.${
              index === 0 ? "if" : "elseIf"
            }`,
          )}
        </div>

        <div className={styles.field}>
          {t(`pages.sphere.tags-selections.section.form.values.field.${field}`)}
        </div>
      </div>
      <div className={styles.operatorValue}>
        <div className={styles.operator}>
          <OperatorInput
            onChange={(operator: any) => {
              handleChange({ ...condition, operator });
            }}
            operators={definition?.opertors || operators}
            value={condition.operator}
          />
        </div>
        <div className={styles.value}>
          {field === "user_gender" ? (
            <GenderInput
              onChange={(gender) =>
                handleChange({ ...condition, value: gender })
              }
              value={condition.value ? condition.value : "male"}
            />
          ) : (
            <Form.Control
              type="number"
              value={condition.value}
              onChange={(event) =>
                handleChange({ ...condition, value: event.target.value })
              }
            />
          )}
        </div>
      </div>
      <div className={styles.then}>
        {t(`pages.sphere.tags-selections.section.form.labels.then`)}
      </div>
      <div className={styles.tags}>
        <TagsInput
          sphereId={sphereId}
          onChange={(tags) => handleChange({ ...condition, tags })}
          tagsSelected={condition.tags}
          defaultValueTagWhenCreation={{
            type: definition?.tagType,
            modules: ["member"],
          }}
          module="member"
        />
      </div>

      <div className={styles.conditionAction}>
        <Button size="sm" variant="secondary" onClick={handleDelete}>
          x
        </Button>
      </div>
    </div>
  );
};
