import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { components, ControlProps } from "react-select";
import { useAbility } from "@casl/react";
import { subject } from "@casl/ability";

import { getRelevantTranslationFor } from "../../tools/multiLingualTools";

import { Tag } from "../../@types/sphere-api";

import AbilityContext from "../../context/AbilityContext";

import { AddLineIcon, SubElementIcon } from "../RemixIcons";
import { fetchTags } from "../../pages/session/SessionAdminPage";
import AddOrUpdateTagForm from "../../pages/sphere/Tags/AddOrUpdateTagForm";
import ModuleIcon from "../../pages/sphere/Tags/ModuleIcon";
import TagLabel from "../Labels/TagLabel";

import selectStyles from "../../styles/react-select.module.scss";
import styles from "./SelectInput.module.scss";

export type Item = { label: string; value: string; source?: Tag };

type Props = {
  sphereId: string;
  disableAddTag?: boolean;
  module?: string;
  isDisabled?: boolean;
  required?: boolean;
  defaultValueTagWhenCreation?: Partial<Tag>;
} & (
  | {
      tagsSelected: Item[];
      onChange: (items: Item[]) => void;
      enableNotExist?: false;
    }
  | {
      tagsSelected: Item[] | null;
      onChange: (items: Item[] | null) => void;
      enableNotExist: true;
    }
);

const TagsInput: React.FC<Props> = ({
  tagsSelected,
  sphereId,
  onChange,
  disableAddTag = false,
  module,
  isDisabled,
  defaultValueTagWhenCreation,
  enableNotExist,
  required,
}) => {
  const { t } = useTranslation("i18n");

  const ability = useAbility(AbilityContext);

  const [couldAddTag, setCouldAddTag] = useState<boolean>(false);

  useEffect(() => {
    setCouldAddTag(
      ability.can("post", subject("sphere-tag", { sphereId })) || false,
    );
  }, [ability, sphereId]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleAddTag = () => {
    setShow(true);
  };

  const handleTagSelected = (items: any) => {
    onChange(items);
  };
  const handleDoNotExitsChanged = (value: boolean) => {
    if (enableNotExist) {
      onChange(value ? null : []);
    }
  };

  const loadTagsOptions = (
    search: string,
    callback: (items: Item[]) => void,
  ) => {
    fetchTags({
      sphereId,
      search,
      filters: module ? [`modules=${module}`] : undefined,
    }).then((tags) => {
      callback(tags.map((tag) => tag as Item));
    });
  };

  const handleSaved = (tag: Tag) => {
    handleTagSelected([
      ...(tagsSelected || []),
      {
        value: tag.id,
        label: getRelevantTranslationFor(tag.name),
        source: tag,
      },
    ]);
    handleClose();
  };

  const Control = ({ children, ...props }: ControlProps<Item, true>) => {
    return (
      <components.Control {...props}>
        {module && (
          <div className={styles.module}>
            <ModuleIcon module={module} />
          </div>
        )}
        {children}
      </components.Control>
    );
  };

  return (
    <>
      <div className={styles.global}>
        <InputGroup className={`mb-3 ${styles.root}`}>
          <AsyncSelect
            isDisabled={isDisabled}
            className={`${selectStyles.reactSelect} ${styles.input}`}
            classNamePrefix="custom-react-select"
            loadOptions={loadTagsOptions}
            defaultOptions
            isMulti={true}
            components={{ Control }}
            onChange={handleTagSelected}
            placeholder={t(`pages.sphere.sessions.tags`)}
            autoFocus
            value={tagsSelected || []}
            required={required}
            noOptionsMessage={() => t("common.noValue")}
            formatOptionLabel={(option, labelMeta) => {
              if (!option.source) {
                return option.label;
              }
              if (labelMeta.context === "menu") {
                return <TagLabel tag={option.source} disableBorder />;
              }
              return <TagLabel tag={option.source} disableBorder />;
            }}
          />
          {couldAddTag && !disableAddTag && (
            <Button variant="outline-primary" onClick={handleAddTag}>
              <AddLineIcon />
            </Button>
          )}
        </InputGroup>
        {enableNotExist && (
          <Form.Group
            controlId="tagDoNotExist"
            className={`form-group ${styles.subComponent}`}
          >
            <SubElementIcon />
            <Form.Check
              type="switch"
              disabled={isDisabled}
              label={<>{t(`common.filters.isEmpty`)}</>}
              checked={tagsSelected === null}
              onChange={() => handleDoNotExitsChanged(tagsSelected !== null)}
            />
          </Form.Group>
        )}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        style={{
          zIndex: 999999,
        }}
      >
        <Modal.Header>{t(`pages.sphere.tags.section.add.title`)}</Modal.Header>
        <Modal.Body
          style={{
            position: "relative",
            display: "table",
            overflowY: "auto",
            overflowX: "auto",
            width: "auto",
            minWidth: "300px",
          }}
        >
          <AddOrUpdateTagForm
            defaultValue={defaultValueTagWhenCreation}
            sphereId={sphereId}
            onClose={handleClose}
            onSaved={handleSaved}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TagsInput;
