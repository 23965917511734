export const cardListAnimation = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  },
  item: {
    hidden: { y: 10, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
    },
  },
};
