import { MouseEvent } from "react";
import styles from "./Histogram.module.scss";

interface ColorScheme {
  [key: string]: string[];
}

const colorScheme: ColorScheme = {
  green: [
    "#D0E3C9",
    "#B9D5AE",
    "#A1C793",
    "#8DB480",
    "#71A160",
    "#B3E889",
    "#A6E476",
    "#99FF99",
    "#66FF66",
    "#00FF00",
  ],
  blue: [
    "#E5FCFB",
    "#CCFAF8",
    "#B2F7F4",
    "#99F5F1",
    "#80F2ED",
    "#66EFE9",
    "#4CEDE6",
    "#33EAE2",
    "#19E8DF",
    "#00E5DB",
  ],
  red: [
    "#FFD747",
    "#FFCD6C",
    "#FFC147",
    "#FFB36C",
    "#FFA047",
    "#FF8F26",
    "#FF7B00",
    "#FF5A5A",
    "#FF3333",
    "#FF0000",
  ],
};
const barHeights: number[] = [48, 57, 67, 77, 86, 96, 105, 115, 124, 134];

interface Props {
  title?: string;
  srcIcon?: string;
  colorScheme: string;
  selectedIndex: number;
  onSelectedIndexChange: (index: number) => void;
  legendLowComp: any;
  legendHighComp: any;
  disabled?: boolean;
}

const Histogram = ({
  title,
  srcIcon,
  colorScheme: colorSchemeValue,
  onSelectedIndexChange,
  selectedIndex,
  legendLowComp,
  legendHighComp,
  disabled,
}: Props) => {
  const handleBarSelection = ({
    currentTarget: {
      dataset: { index },
    },
  }: MouseEvent<SVGRectElement>) => {
    if (disabled) {
      return;
    }
    if (index !== undefined) {
      onSelectedIndexChange(+index);
    }
  };

  return (
    <div className={styles.root}>
      {(title || srcIcon) && (
        <div style={{ display: "flex", alignItems: "center", height: "57px" }}>
          {title && <span className={styles.title}>{title}</span>}
          {srcIcon && (
            <img src={srcIcon} alt="icon" style={{ marginRight: "9px" }} />
          )}
          <div className={styles.line} />
        </div>
      )}
      <div>
        <svg
          viewBox="0 0 685 134"
          preserveAspectRatio="xMinYMin slice"
          width="100%"
        >
          {colorScheme[colorSchemeValue].map((color, index) => {
            let colorRender = color;
            if (index > selectedIndex && disabled) {
              colorRender = color + "10";
            }

            const barHeight = barHeights[index];
            const width = 64;
            const xPos = (width + 5) * index;
            const yPos = 134 - barHeight;
            return (
              <rect
                x={xPos + 3}
                y={yPos + 3}
                key={color}
                rx="3"
                ry="3"
                width={width - 6}
                data-index={index}
                height={barHeight - 6}
                stroke={colorRender}
                strokeWidth="2"
                fillOpacity={index > selectedIndex && !disabled ? "0" : "1"}
                fill={colorRender}
                onClick={handleBarSelection}
                vectorEffect="non-scaling-stroke"
              />
            );
          })}
        </svg>
      </div>
      <div style={{ marginTop: "10px" }}>
        <span className={styles.legend}>{legendLowComp}</span>
        <span style={{ float: "right" }} className={styles.legend}>
          {legendHighComp}
        </span>
      </div>
    </div>
  );
};

export default Histogram;
