import React, { useEffect } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import DOMpurify from "dompurify";

import "./MarkdownComponent.module.scss";
import styles from "./MarkdownComponent.module.scss";

type MarkdownComponentProps = {
  text?: string;
  className?: string;
};

const checkIfSupportsLookbehind = () => {
  try {
    new RegExp("(?<=\\s)a");
    return true;
  } catch {
    return false;
  }
};

const cleanText = (input: string | undefined) => {
  const value = (input || "").split(/\n/).reduce((acc, line) => {
    if (line.trim().startsWith("|")) {
      return `${acc}\n${line}`;
    }

    return `${acc}\n\n${line}`;
  }, "");

  return value;
};

const MarkdownComponent: React.FC<MarkdownComponentProps> = ({
  text,
  className,
}) => {
  const [value, setValue] = React.useState(cleanText(text));
  const [supportsLookbehind] = React.useState(checkIfSupportsLookbehind());

  useEffect(() => {
    setValue(DOMpurify.sanitize(cleanText(text)));
  }, [text]);

  return (
    <div className={`${className} ${styles.markdownContainer}`}>
      <Markdown
        rehypePlugins={[remarkMath, rehypeRaw]}
        remarkPlugins={supportsLookbehind ? [remarkGfm] : []}
        components={{
          a({ node, className, children, ...props }) {
            return (
              <a target="_blank" {...props}>
                {children}
              </a>
            );
          },
        }}
      >
        {value}
      </Markdown>
    </div>
  );
};

export default MarkdownComponent;
