interface Props {
  color: string;
  fillColor?: string;
  size: "big" | "small" | "medium" | "tiny" | "micro";
}

const ThinkingPolygon = ({ color, size, fillColor }: Props) => {
  // const width = size === 'tiny' ? 24 : size === 'small' ? 35 : 170;
  const height =
    size === "micro"
      ? 16
      : size === "tiny"
        ? 26
        : size === "small"
          ? 45
          : size === "medium"
            ? 90
            : 130;
  const strokeWidth =
    size === "micro"
      ? 30
      : size === "tiny"
        ? 24
        : size === "small"
          ? 9
          : size === "medium"
            ? 5
            : 6;

  return (
    <svg
      height={`${height}`}
      viewBox="0 0 260 300"
      fill={`${fillColor || "none"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1488 87.732L133 25.4641L240.851 87.732V212.268L133 274.536L25.1488 212.268V87.732Z"
        stroke={`${color}`}
        strokeWidth={`${strokeWidth}`}
      />
      <g filter="url(#filter0_d)">
        <path
          d="M25.1488 87.732L133 25.4641L240.851 87.732V212.268L133 274.536L25.1488 212.268V87.732Z"
          stroke={`${color}`}
          strokeWidth={`${strokeWidth}`}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="284"
          height="284"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur stdDeviation="7" />
        </filter>
      </defs>
    </svg>
  );
};

export default ThinkingPolygon;
