import { useContext, FC, useState, useEffect } from "react";
import linkStyles from "./AddOrUpdateCampaign.module.scss";
import modalStyles from "../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";

import { useTranslation } from "react-i18next";

import * as motion from "motion/react-m";
import { feelingCategory } from "../../@types/webapp-api";
import { ToastOnClose } from "../../components/ModalWrapper/ModalSlideShowWrapper";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import PageTitle from "../../components/PageTitle/PageTitle";

import AddOrUpdateCampaign from "./AddOrUpdateCampaign";

const styles = { ...modalStyles, ...linkStyles };
const dicoPrefix = "pages.sphere.urlAliases";

type Props = {
  onClose?: () => void;
  onSubmit?: (stateProps: any) => void;
  variants?: any;
  onCloseNotifications?: ToastOnClose[];
  disableThinkingCreation?: boolean;
};

const AddUrlAliasPage: FC<Props> = ({ variants, ...props }) => {
  const {
    state: { context },
  } = useContext(GlobalStateContext);

  const { t } = useTranslation("i18n");
  const [category, setCategory] = useState<feelingCategory>(
    context.sessionCtx?.session.category || "contributor",
  );
  useEffect(() => {
    setCategory(context.sessionCtx?.session.category || "contributor");
  }, [context.sessionCtx]);

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={styles.page}
    >
      <div className={styles.page}>
        <PageTitle title={t(`${dicoPrefix}.section.add.title`)} />
        <div className={styles.content}>
          <AddOrUpdateCampaign mode="creation" category={category} {...props} />
        </div>
      </div>
    </motion.div>
  );
};

export default AddUrlAliasPage;
