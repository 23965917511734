import { subject } from "@casl/ability";
import { Session } from "../@types/session-api";
import {
  ReportDefinition,
  ReportDefinitionGroup,
  SessionReportOptions,
} from "../@types/webapp-api";

type Definition = {
  id: string;
  translationKey: string;
  module?: string;
  actionRequired?: string;
  category?: string;
  available?: (session: Session, strategyEnabled?: boolean) => boolean;
};
type Group = {
  id: string;
  translationKey: string;
  definitions?: Definition[];
  groups?: Group[];
};

const REPORTS = {
  "session-group-contribution-summary-report": {
    id: "session-group-contribution-summary-report",
    translationKey: "summary",
    actionRequired: "report",
    category: "contributor",
  },
  "session-group-contribution-engagement-report": {
    id: "session-group-contribution-engagement-report",
    translationKey: "engagement",
    actionRequired: "report",
    category: "contributor",
  },
  "session-group-contribution-thinking-report": {
    id: "session-group-contribution-thinking-report",
    translationKey: "thinking",
    actionRequired: "report",
    category: "contributor",
    available: (session: Session) => {
      return !!session.subscription && session.subscription !== "starter";
    },
  },
  "session-group-contribution-strategyAssessment-report": {
    id: "session-group-contribution-strategyAssessment-report",
    translationKey: "strategyAssessment",
    actionRequired: "report",
    category: "contributor",
    available: (session: Session) => {
      return !!session.subscription && session.subscription !== "starter";
    },
  },
  "session-group-contribution-time-report": {
    id: "session-group-contribution-time-report",
    translationKey: "time",
    actionRequired: "report",
    category: "contributor",
  },
  "session-group-contribution-summary-smartreport": {
    id: "session-group-contribution-summary-smartreport",
    translationKey: "member",
    actionRequired: "smartReport",
    category: "contributor",
  },
  "session-group-contributor-strategy": {
    id: "session-group-contributor-strategy",
    translationKey: "strategy",
    module: "StrategyReport",
    actionRequired: "smartReport",
    category: "contributor",
    available: (session: Session) => {
      return !!session.subscription && session.subscription !== "starter";
    },
  },
  // "session-group-consumer-journey-report": {
  //   id: "session-group-consumer-journey-report",
  //   translationKey: "journey",
  //   actionRequired: "report",
  //   category: "consumer",
  // },
  "session-group-consumer-summary-report": {
    id: "session-group-consumer-summary-report",
    translationKey: "summary",
    actionRequired: "report",
    category: "consumer",
  },
  "session-group-consumer-thinking-report": {
    id: "session-group-consumer-thinking-report",
    translationKey: "thinking",
    actionRequired: "report",
    category: "consumer",
  },
  "session-group-consumer-time-report": {
    id: "session-group-consumer-time-report",
    translationKey: "time",
    actionRequired: "report",
    category: "consumer",
  },
  "session-group-unauth-summary-report": {
    id: "session-group-unauth-summary-report",
    translationKey: "summary",
    actionRequired: "report",
    category: "consumer",
  },
  "session-group-consumer-summary-publicreport": {
    id: "session-group-consumer-summary-publicreport",
    translationKey: "public",
    category: "consumer",
  },
  // 'session-group-consumer-comparison-report': {
  //   id: 'session-group-consumer-comparison-report',
  //   translationKey: 'comparison',
  //   actionRequired: 'report',
  //   category: 'consumer',
  // },
};

const groupConfigs: Group[] = [
  {
    id: "report",
    translationKey: "detailled",
    definitions: [
      REPORTS["session-group-contribution-summary-report"],
      REPORTS["session-group-contribution-time-report"],
      // REPORTS['session-group-contribution-mood-report'],
      REPORTS["session-group-contribution-thinking-report"],
      REPORTS["session-group-contribution-strategyAssessment-report"],
      // REPORTS['session-group-contribution-comparison-report'],
      // REPORTS['session-group-contribution-goals-report'],
      // REPORTS['session-group-contribution-engagement-report'],
      // REPORTS['session-group-contribution-psy-risks-report'],
      // REPORTS['session-group-contribution-tailored-report'],

      REPORTS["session-group-consumer-summary-report"],
      REPORTS["session-group-consumer-time-report"],
      REPORTS["session-group-consumer-thinking-report"],
      // REPORTS['session-group-consumer-journey-report'],
      // REPORTS['session-group-consumer-comparison-report'],
    ],
  },
  REPORTS["session-group-contribution-summary-smartreport"],
  REPORTS["session-group-consumer-summary-publicreport"],
  REPORTS["session-group-contributor-strategy"],
];

const convertDefinition = (
  definition: Definition,
  options: SessionReportOptions,
): ReportDefinition | undefined => {
  let visible;
  if (!options.context.sessionCtx) {
    visible = false;
  } else if (!definition.actionRequired) {
    visible = true;
  } else {
    visible = options.ability.can(
      definition.actionRequired,
      subject("session", options.context.sessionCtx.session),
    );
  }
  if (!visible) {
    return undefined;
  }
  if (
    definition.category &&
    options.context.sessionCtx?.session.category !== definition.category
  ) {
    return undefined;
  }
  if (definition.available) {
    visible = definition.available(
      options.context.sessionCtx.session,
      options.strategyEnabled,
    );
  }
  if (!visible) {
    return undefined;
  }

  return {
    id: definition.id,
    label: options.t(`report.${definition.translationKey}`),
    module: definition.module || "ZohoAnalytics",
    disable: false,
  };
};

const convertGroup = (
  group: Group,
  options: SessionReportOptions,
): ReportDefinitionGroup | undefined => {
  const isGroup =
    (group.definitions && group.definitions.length > 0) ||
    (group.groups && group.groups.length > 0);

  if (!isGroup) {
    return convertDefinition(group, options);
  }

  const definitions = [
    ...(group.groups?.map((d) => convertGroup(d, options)) || []),
    ...(group.definitions?.map((d) => convertDefinition(d, options)) || []),
  ].filter((d) => !!d) as (ReportDefinitionGroup | ReportDefinition)[];

  if (definitions.length === 0) {
    return undefined;
  }

  return {
    id: group.id,
    label: options.t(`report.${group.translationKey}`),
    reports: definitions,
  };
};

export const getSessionReports = (
  options: SessionReportOptions,
): ReportDefinitionGroup[] => {
  const groups = groupConfigs
    .map((group) => convertGroup(group, options))
    .filter((g) => !!g) as ReportDefinitionGroup[];

  const ownerGroup = groups.find((g) => g.id === "report");

  // Add Forms groups
  if (
    ownerGroup &&
    options.context.sessionCtx?.session.category === "contributor" &&
    options.ability.can(
      "report",
      subject("session", options.context.sessionCtx?.session),
    ) &&
    options.forms &&
    options.forms.length > 0 &&
    !!options.context?.sessionCtx?.session.subscription &&
    options.context.sessionCtx.session.subscription !== "starter"
  ) {
    const formsDefinition: ReportDefinitionGroup = {
      id: "myself-forms",
      label: options.t("report.forms"),
      reports: options.forms?.map((form) => ({
        id: `session-form-${form.id}`,
        label: form.name,
        module: "ZohoAnalytics",
        disable: false,
      })),
    };

    ownerGroup.reports = [...(ownerGroup?.reports || []), formsDefinition];
  }
  return groups;
};
