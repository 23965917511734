import { useTranslation } from "react-i18next";

import styles from "./GoalForm.module.scss";
import { SubGoalIcon } from "../../RemixIcons";

export type GoalHitProps = {
  goalId: string;
  subGoalId?: string;
  level: number;
  ranking: number | string;
  size?: "x-small" | "small";
};

const GoalHitBadge = ({
  level,
  goalId,
  subGoalId,
  ranking,
  size,
}: GoalHitProps) => {
  const { t } = useTranslation("i18n");

  return size === "x-small" ? (
    <div
      className={`${styles.goalVerySmallBadge} ${styles[`goalBadge${level}`]}`}
    >
      <span>{ranking}</span>
    </div>
  ) : (
    <div className={`${styles.goalBadge} ${styles[`goalBadge${level}`]}`}>
      <div className={styles.goalBadgeLabel}>
        {subGoalId && <SubGoalIcon />}
        {subGoalId
          ? t(`report.goalForm.axes.${goalId}.sub.${subGoalId}`)
          : t(`report.goalForm.axes.${goalId}.label`)}
      </div>
      {/* {` - `} */}
      <div className={styles.goalBadgeRanking}>{ranking}</div>
    </div>
  );
};

export default GoalHitBadge;
