/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";

import { getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import GuestLoginHeader from "../components/Auth/GuestLoginHeader";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import api from "../api/Api";
import { toast } from "react-toastify";
import styles from "./withAuthentication.module.scss";
import { getRelevantTranslationFor } from "./multiLingualTools";
import { UrlCampaignResponse } from "../@types/shorten-url-api";
import LoadingPage from "../pages/loading/LoadingPage";
import Login from "../components/Auth/Login";
import WelcomeHeader from "../components/Auth/WelcomeHeader";
import EnterCode from "../components/Auth/EnterCode";

type LoadingState = {
  value: "loading";
};
type ConnectedState = {
  value: "connected";
};
type CampaignSelectionState = {
  value: "campaignSelection";
};
type GuestLoginState = {
  value: "guest";
  campaign: UrlCampaignResponse;
  showLogin: boolean;
};
type LoginState = {
  value: "login";
};

type State =
  | LoadingState
  | ConnectedState
  | GuestLoginState
  | LoginState
  | CampaignSelectionState;

export function withAuthentication<T extends React.PropsWithChildren>(
  WrappedComponent: React.ComponentType<T>,
) {
  return function ComponentWithAuthentication(props: T) {
    const { t } = useTranslation("i18n");

    const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(
      window.localStorage.getItem("cookiesAccepted") === "true",
    );
    const [pageState, setPageState] = useState<State>({ value: "loading" });

    const handleCookiesAccepted = async () => {
      window.localStorage.setItem("cookiesAccepted", "true");
      setCookiesAccepted(true);
    };

    const getPageState = async (): Promise<State> => {
      try {
        await getCurrentUser();

        return {
          value: "connected",
        };
      } catch {
        // ignored on purpose
      }

      const url = new URL(window.location.href);
      if (url.searchParams.get("active") === "enterCode") {
        return {
          value: "campaignSelection",
        };
      }

      const validationCode = url.searchParams.get("validationCode") || "";
      const campaignId = url.searchParams.get("campaignId");
      if (campaignId && validationCode) {
        try {
          const campaign = await api.getUrlCampaign(campaignId, validationCode);
          // dispatch({
          //   type: SET_CONTEXT,
          //   payload: {
          //     urlCampaignCtx: {
          //       campaign,
          //       validationCode,
          //     } as CampaignContext,
          //   },
          // });
          if (campaign.category === "consumer") {
            return {
              value: "guest",
              campaign,
              showLogin: false,
            };
          }
        } catch {
          toast.error(t("common.invalid"));
          return {
            value: "campaignSelection",
          };
        }
      }
      return {
        value: "login",
      };
    };

    const recomputePageState = async () => {
      const pageState = await getPageState();
      setPageState(pageState);
    };

    useEffect(() => {
      recomputePageState();
      const stopListenerCallback = Hub.listen("auth", recomputePageState);

      return () => {
        stopListenerCallback();
      };
    }, []);

    const handleHaveAccountClicked = (
      e: React.MouseEvent<HTMLAnchorElement>,
    ) => {
      e.preventDefault();
      if (pageState.value === "guest") {
        setPageState({ ...pageState, showLogin: true });
      }
    };
    return (
      <>
        <ToastContainer
          autoClose={5000}
          hideProgressBar
          newestOnTop
          transition={Slide}
          draggable={false}
        />

        {pageState.value === "loading" ? (
          <LoadingPage />
        ) : pageState.value === "guest" ? (
          <>
            <GuestLoginHeader
              providerName={getRelevantTranslationFor(
                pageState.campaign.providerName,
              )}
            />
            {pageState.showLogin ? (
              <Login />
            ) : (
              <div className={styles.haveAccount}>
                {t("login.Have an account? ")}
                <a href="#" onClick={handleHaveAccountClicked}>
                  {t("login.Sign in")}
                </a>
              </div>
            )}
          </>
        ) : pageState.value === "login" ? (
          <WelcomeHeader>
            <Login />
          </WelcomeHeader>
        ) : pageState.value === "connected" ? (
          <WrappedComponent {...props} />
        ) : pageState.value === "campaignSelection" ? (
          <WelcomeHeader>
            <EnterCode />
          </WelcomeHeader>
        ) : (
          <></>
        )}

        {!cookiesAccepted && (
          <CookieConsent
            disableStyles={true}
            onAccept={handleCookiesAccepted}
            location="bottom"
            containerClasses={`consent alert-warning ${styles.cookie_background}`}
            buttonClasses="btn btn-consent"
            contentClasses="text-consent"
            buttonText={t("app.consent.accept")}
          >
            {t("app.consent.message")}{" "}
            <a
              target="_blank"
              style={{ color: "#857025", textDecoration: "underline" }}
              href="https://www.seen-apps.com/terms"
            >
              {t("app.consent.termsAndConditions")}
            </a>
          </CookieConsent>
        )}
      </>
    );
  };
}
