import { fetchAuthSession } from "aws-amplify/auth";
import { EVENT_RECEIVED } from "../context/globalState/globalStateReducer";

class WebsocketApi {
  webSocket?: WebSocket;
  userId?: string;
  timeout: number = 250;
  connectInterval?: NodeJS.Timeout;
  dispatch?: React.Dispatch<any>;

  connect = async () => {
    const session = await fetchAuthSession();

    const isDev =
      window.location.hostname === "localhost" ||
      window.location.hostname.indexOf("-dev") >= 0;

    // this.webSocket = new WebSocket(`ws://localhost:3001?auth=${jwtToken}`);
    this.webSocket = new WebSocket(
      `wss://ws.seen-apps.com/${isDev ? "dev" : "prod"}?auth=${session.tokens?.idToken?.toString()}`,
    );

    // websocket onopen event listener
    this.webSocket.onopen = () => {
      console.log("connected websocket main component");

      this.timeout = 250; // reset timer to 250 on open of websocket connection

      if (this.connectInterval) {
        clearTimeout(this.connectInterval); // clear Interval on on open of websocket connection
      }
    };

    // websocket onclose event listener
    this.webSocket.onclose = (e) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (this.timeout + this.timeout) / 1000,
        )} second.`,
        e.reason,
      );

      this.timeout = this.timeout + this.timeout; //increment retry interval
      this.connectInterval = setTimeout(
        this.check,
        Math.min(10000, this.timeout),
      ); //call check function after timeout
    };

    // websocket onerror event listener
    this.webSocket.onerror = (err) => {
      console.log(
        "Socket encountered error: ",
        JSON.stringify(err),
        "Closing socket",
      );

      this.webSocket!.close();
    };
    this.webSocket.onmessage = (e) => {
      const { data } = e;
      // console.log('message received ', data);

      if (this.dispatch) {
        this.dispatch({ type: EVENT_RECEIVED, payload: JSON.parse(data) });
      }
    };
  };

  reconnect = (userId: string) => {
    const userUpdated = userId && this.userId !== userId;
    if (userUpdated && this.webSocket) {
      this.webSocket.close();
    }
    this.check();
  };

  check = () => {
    if (!this.webSocket || this.webSocket.readyState === WebSocket.CLOSED) {
      //check if websocket instance is closed, if so call `connect` function.
      this.connect();
    }
  };
  setDispatch = (dispatch: React.Dispatch<any>) => {
    this.dispatch = dispatch;
  };

  getMySelfData = () => {
    if (this.webSocket) {
      this.webSocket.send(
        JSON.stringify({
          action: "myself",
        }),
      );
    }
  };
}

const ws = new WebsocketApi();

export default ws;
