import React from "react";
import {
  CampaignIcon,
  LinkIcon,
  UserConnection2Icon,
} from "../../../components/RemixIcons";
import SessionIcon from "../../../components/session/SessionIcon";

export const MODULES = [
  "session-contributor",
  "session-consumer",
  "member",
  "campaign-contributor",
  "campaign-consumer",
  "direct",
];

type Props = { module: string; className?: string };

const ModuleIcon: React.FC<Props> = ({ module, className }) => {
  return module === "session-contributor" ? (
    <SessionIcon
      session={{
        category: "contributor",
        type: "group",
      }}
      className={className}
    />
  ) : module === "session-consumer" ? (
    <SessionIcon
      session={{
        category: "consumer",
        type: "group",
      }}
      color="#2dfff6"
      className={className}
    />
  ) : module === "campaign-contributor" ? (
    <CampaignIcon className={className} />
  ) : module === "campaign-consumer" ? (
    <CampaignIcon color="#2dfff6" className={className} />
  ) : module === "direct" ? (
    <LinkIcon className={className} />
  ) : module === "member" ? (
    <UserConnection2Icon className={className} />
  ) : (
    <></>
  );
};

export default ModuleIcon;
