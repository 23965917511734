import { useContext, useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import LayoutContext from "../../context/layout/LayoutContext";
import burgerStyles from "./react-burger-menu.module.scss";
import menuStyles from "./Menu.module.scss";
import pageStyles from "./FilterMenu.module.scss";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { useTranslation } from "react-i18next";
import DateRangePickerInput, {
  Range,
} from "../../components/dateRangePicker/DateRangePickerInput";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { CloseLineIcon, DeleteBinLineIcon, SearchIcon } from "../RemixIcons";
import RoundedButton from "../RoundedButton/RoundedButton";
import {
  SET_FEELING_FACETS,
  SET_FILTERS,
  SET_GOAL_RANKINGS,
} from "../../context/globalState/globalStateReducer";
import { Filters } from "../../@types/seen-apps";
import api from "../../api/Api";
import { captureException } from "@sentry/react";
import ColorFacet from "../FacetFilter/ColorFacet";
import SphereMultiSelectFacet from "../FacetFilter/SphereMultiSelectFacet";
import moment from "moment-timezone";
import SessionMultiSelectFacet from "../FacetFilter/SessionMultiSelectFacet";
import GoalMultiSelectFacet from "../FacetFilter/GoalMultiSelectFacet";
import GoalLevelMultiSelectFacet from "../FacetFilter/GoalLevelMultiSelectFacet";
import TopGoalMultiSelectFacet from "../FacetFilter/TopGoalMultiSelectFacet";
import { Action, Subject } from "../../@types/member-api";
import AbilityContext from "../../context/AbilityContext";
import { useAbility } from "@casl/react";
import cn from "classnames";

const styles = { ...burgerStyles, ...menuStyles, ...pageStyles };

const FilterMenu = () => {
  const ability = useAbility(AbilityContext);
  const layoutContext = useContext(LayoutContext);
  const {
    state: { filters, goalRankings },
    dispatch,
  } = useContext(GlobalStateContext);

  const [featureAvailable, setFeatureAvailable] = useState<boolean>(false);
  useEffect(() => {
    setFeatureAvailable(ability.can(Action.Put, Subject.FeelingGoal));
  }, [ability]);

  useEffect(() => {
    const loadGoals = async () => {
      const [survey] = await api.getFormSurveys(
        moment().startOf("year").subtract(3, "year").toDate(),
        moment().endOf("year").toDate(),
        {
          formId: "AQ8579T6LZR",
          max: 1,
        },
      );
      dispatch({
        type: SET_GOAL_RANKINGS,
        payload: survey?.ranking || {},
      });
    };

    if (featureAvailable && !goalRankings) {
      loadGoals();
    }
  }, [dispatch, featureAvailable, goalRankings]);

  const { t } = useTranslation("i18n");

  const [search, setSearch] = useState<string>();

  const loadFeelingFacets = async () => {
    try {
      const dateRange = filters.date || filters.defaultDate;

      const from = moment(dateRange.startDate).startOf("day").toDate();
      const to = moment(dateRange.endDate).endOf("day").toDate();

      const facets = await api.getFeelingFacets(from, to);
      dispatch({
        type: SET_FEELING_FACETS,
        payload: facets,
      });
    } catch (error) {
      captureException(error);
    }
  };

  const changeDateRange = (dateRange: Range) => {
    dispatch({
      type: SET_FILTERS,
      payload: {
        ...filters,
        date: dateRange,
      },
    });
  };

  const onClose = () => {
    layoutContext.toggleFiltersMenu();
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const updates: Filters = {
        ...filters,
        feeling: {
          ...(filters.feeling || {}),
          personalNotesSearch: search,
        },
      };
      if (!search) {
        delete updates.feeling.personalNotesSearch;
      }
      dispatch({
        type: SET_FILTERS,
        payload: updates,
      });
    }, 600);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const changeSearch = async (text: string) => {
    setSearch(text);
  };

  const onResetFilter = async () => {
    setSearch("");
    dispatch({
      type: SET_FILTERS,
      payload: {
        defaultDate: filters.defaultDate,
        feeling: {},
      },
    });
  };

  useEffect(() => {
    loadFeelingFacets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.date]);

  return (
    <div className={`${styles.root}`}>
      <Menu
        width={375}
        customBurgerIcon={false}
        customCrossIcon={false}
        right
        isOpen={layoutContext.isFiltersMenuOpen}
        onStateChange={(state: any) => {
          layoutContext.filtersStateChangeHandler(state);
          if (state.isOpen) {
            loadFeelingFacets();
          }
        }}
      >
        <div className={styles.header}>
          <span className={styles.title}>
            {t("common.filters.title")}{" "}
            {filters.count !== 0 && (
              <Button
                onClick={onResetFilter}
                variant="warning"
                className={styles.filterCount}
              >
                {filters.count}
                <DeleteBinLineIcon className={styles.resetFilter} />
              </Button>
            )}
          </span>
          <div className={styles.button}>
            <RoundedButton className={styles.close} onClick={onClose}>
              <CloseLineIcon />
            </RoundedButton>
          </div>
        </div>
        <div className={styles.filters}>
          <Container className={styles.noMargin}>
            <Row className={cn(styles.noMargin, "mb-3")}>
              <Col sm={12} className={styles.noMargin}>
                <Form.Group className="form-group" controlId="code">
                  <Form.Label>{t("common.filters.date")}</Form.Label>
                  <DateRangePickerInput
                    minDate={new Date(2018, 0, 1)}
                    maxDate={new Date()}
                    value={filters.date || filters.defaultDate}
                    onChange={changeDateRange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className={styles.noMargin}>
              <Col sm={12} className={styles.noMargin}>
                <div className={styles.divider}>
                  {t("common.filters.feeling.title")}
                </div>
              </Col>
            </Row>
            <Row className={cn(styles.noMargin, "mb-3")}>
              <Col sm={12} className={styles.noMargin}>
                <Form.Group className="form-group" controlId="code">
                  <Form.Label>
                    {t("common.filters.feeling.personalNotes")}
                  </Form.Label>
                  <div className={styles.filterContainer}>
                    <div className={styles.searchContainer}>
                      <Form.Control
                        name="filter"
                        autoComplete="off"
                        placeholder={t(
                          `common.filters.feeling.personalNotesPH`,
                        )}
                        type="text"
                        value={search}
                        onChange={(event: any) =>
                          changeSearch(event.target.value)
                        }
                      />
                      <SearchIcon className={styles.searchIcon} />
                      <Button
                        variant="secondary"
                        className={styles.left}
                        onClick={() => setSearch("")}
                      >
                        <CloseLineIcon />
                      </Button>
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className={cn(styles.noMargin, "mb-3")}>
              <Col sm={12} className={styles.noMargin}>
                <ColorFacet />
              </Col>
            </Row>
            <Row className={cn(styles.noMargin, "mb-3")}>
              <Col sm={12} className={styles.noMargin}>
                <SphereMultiSelectFacet />
              </Col>
            </Row>
            <Row className={cn(styles.noMargin, "mb-3")}>
              <Col sm={12} className={styles.noMargin}>
                <SessionMultiSelectFacet />
              </Col>
            </Row>
            {featureAvailable && (
              <>
                <Row className={cn(styles.noMargin, "mb-3")}>
                  <Col sm={12} className={styles.noMargin}>
                    {t("common.filters.feeling.goals.title")}
                  </Col>
                </Row>
                <Row className={cn(styles.noMargin, "mb-3")}>
                  <Col sm={12} className={styles.noMargin}>
                    <Container className={styles.subFilters}>
                      <Row className={styles.noMargin}>
                        <Col sm={12} className={styles.noMargin}>
                          <GoalMultiSelectFacet />
                        </Col>
                      </Row>
                      <Row className={styles.noMargin}>
                        <Col sm={12} className={styles.noMargin}>
                          <TopGoalMultiSelectFacet />
                        </Col>
                      </Row>
                      <Row className={styles.noMargin}>
                        <Col sm={12} className={styles.noMargin}>
                          <GoalLevelMultiSelectFacet />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </Menu>
    </div>
  );
};

export default FilterMenu;
