import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import api from "../../../api/Api";
import { toast } from "react-toastify";
import Select from "react-select";
import selectStyles from "../../../styles/react-select.module.scss";
import differenceWith from "lodash.differencewith";
import modalStyles from "../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import memberPageStyles from "./AddMemberPage.module.scss";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle/PageTitle";
import GlobalStateContext from "../../../context/globalState/GlobalStateContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { ADD_OR_UPDATE_SPHERE_MEMBER } from "../../../context/globalState/globalStateReducer";
import { Forbid2LineIcon } from "../../../components/RemixIcons";
import { captureException } from "@sentry/minimal";
import TagsInput from "../../../components/input/TagsInput";
import { getRelevantTranslationFor } from "../../../tools/multiLingualTools";
import LoadingPage from "../../loading/LoadingPage";
import { extractLocationData } from "../../../tools/pathTools";
import ReactLoading from "react-loading";
import { getErrorTranslation } from "../../../tools/errorTools";
import { confirmWrapper } from "../../../tools/confirm";
import { MemberResponse } from "../../../@types/member-api";
import { Tag } from "../../../@types/sphere-api";
import TagLabel from "../../../components/Labels/TagLabel";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { OptionsType } from "../../../@types/webapp-api";

const styles = { ...modalStyles, ...memberPageStyles };

type Props = {
  currentPageIdx?: number;
  onSubmit?: (state?: any) => void;
};

type FormInputs = {
  id: string;
  email: string;
  name: string;
  roles: OptionsType<OptionType>;
  subscription: OptionType;
  active: boolean;
};

type OptionType = {
  value: string;
  label: string;
  isDisabled?: boolean;
};

const AddMemberPage: React.FC<Props> = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation("i18n");
  const location = useLocation();
  const {
    state: {
      user,
      context: { sphereCtx },
    },
    dispatch,
  } = useContext(GlobalStateContext);
  const [accessMessage, setAccessMessage] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [saving, setSaving] = useState<false | "save" | "disable">(false);
  const [couldActivateMember, setCouldActivateMember] =
    useState<boolean>(false);
  const [tags, setTags] = useState<
    { value: string; label: string; source?: Tag }[]
  >([]);
  const [autoTags, setAutoTags] = useState<Tag[]>([]);
  const [firstDayOfWork, setFirstDayOfWork] = useState<Date>();
  const [memberId, setMemberId] = useState<string>();
  const [sphereId, setSphereId] = useState<string>();
  const [member, setMember] = useState<MemberResponse>();

  const [subscriptionOptions, setSubscriptionOptions] = useState<
    OptionsType<OptionType>
  >([
    {
      value: "starter",
      label: t(`pages.user.subscription.starter.title`),
      isDisabled: false,
    },
    {
      value: "essentials",
      label: t(`pages.user.subscription.essentials.title`),
      isDisabled: true,
    },
  ]);

  const { register, handleSubmit, reset, formState, control } =
    useForm<FormInputs>({
      defaultValues: {
        id: "",
        email: "",
        name: "",
        roles: [],
        subscription: subscriptionOptions[0],
        active: true,
      },
    });

  const roleOptions = useMemo<OptionsType<OptionType>>(() => {
    const eOptions = [{ value: "admin", label: t(`common.roles.admin`) }];
    // if (sphereCtx?.sphere.entitlements?.engagementUp?.active) {
    eOptions.push({
      value: "contributor",
      label: t(`common.roles.contributor`),
    });
    // }
    if (sphereCtx?.sphere.entitlements?.salesUp?.active) {
      eOptions.push({ value: "sales-up", label: t(`common.roles.sales-up`) });
    }
    return eOptions;
  }, [sphereCtx, t]);

  useEffect(() => {
    const options = subscriptionOptions.map((option) =>
      option.value === "essentials"
        ? {
            ...option,
            isDisabled:
              !sphereCtx?.sphere.subscriptionOptions?.essentials?.licenseCount,
          }
        : option,
    );
    console.log(sphereCtx);
    setSubscriptionOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sphereCtx, t]);

  useEffect(() => {
    const fetchMember = async (sphereId: string, memberId: string) => {
      setLoading(true);
      const member = await api.getSphereMember(sphereId, memberId);
      const formToReset: FormInputs = {
        ...member,
        roles: roleOptions.filter((option: OptionType) =>
          (member.roles as any[])?.find((role) => role === option.value),
        ),
        subscription:
          subscriptionOptions.find((s) => s.value === member.subscription) ||
          subscriptionOptions[0],
      };
      setMember(member);
      setFirstDayOfWork(
        member.firstDayOfWork
          ? moment(member.firstDayOfWork).toDate()
          : undefined,
      );
      setTags(
        member?.tags
          ?.filter((tag) => !tag.autoTag)
          .map((tag) => {
            return {
              value: tag.id,
              label: getRelevantTranslationFor(tag.name),
              source: tag,
            };
          }),
      );
      setAutoTags(
        member?.tags?.filter((tag) => tag.autoTag).map((tag) => tag as Tag) ||
          [],
      );

      const result = differenceWith(
        member.roles as string[],
        roleOptions as OptionType[],
        (roleStr: string, { value }: OptionType) => roleStr === value,
      );
      setCouldActivateMember(!member.removedByUser);
      result.forEach((missingRole: string) =>
        formToReset.roles.push({
          value: missingRole,
          label: t(`common.roles.${missingRole}`),
        }),
      );
      reset(formToReset);

      if (!member.userLinked) {
        setAccessMessage(t("pages.sphere.members.section.access.userNotFound"));
      } else if (member.removedByUser) {
        setAccessMessage(t("pages.sphere.members.section.access.userLeave"));
      } else if (member.removedByAdmin) {
        setAccessMessage(t("pages.sphere.members.section.access.adminRevoke"));
      } else {
        setAccessMessage(undefined);
      }

      setLoading(false);
    };

    const data = extractLocationData(location);
    setSphereId(data.params?.sphereId);
    setMemberId(data.queryString?.memberId);

    if (data.params?.sphereId && data.queryString?.memberId) {
      fetchMember(data.params.sphereId, data.queryString.memberId);
    } else {
      setMember(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDisable = async () => {
    if (!member || !sphereCtx) {
      return;
    }
    if (
      await confirmWrapper(
        t(`pages.sphere.members.section.delete.confirm`, {
          name: member.name,
          sphereName: sphereCtx.sphere.name,
        }),
        {
          btnPrimaryLabel: t(
            `pages.sphere.members.section.delete.confirmAction`,
          ),
          btnPrimaryVariant: "danger",
          title: t(`pages.sphere.members.section.delete.confirmAction`),
        },
      )
    ) {
      setSaving("disable");
      try {
        const updatedMember = await api.updateSphereMember(
          sphereCtx.sphere.id,
          member.id,
          {
            active: false,
            roles: member.roles,
          },
        );
        toast.success(t(`pages.sphere.members.section.delete.succeed`));
        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: updatedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);

        toast.error(
          getErrorTranslation({
            t,
            error,
            defaultKey: "pages.sphere.members.section.delete.error",
          }),
        );
      }
      setSaving(false);
    }
  };

  const onSubmitForm = async (form: FormInputs) => {
    if (!sphereId) {
      toast.error(t("common.error"));
      return;
    }
    setSaving("save");
    if (form.id) {
      try {
        const updatedMember = await api.updateSphereMember(sphereId, form.id, {
          name: form.name,
          roles: (form.roles! as any[]).map((role: any) => role.value),
          subscription: form.subscription?.value || "starter",
          active: form.active,
          tags: tags?.map((t) => ({ key: t.value, value: t.label })) as any,
          firstDayOfWork: firstDayOfWork
            ? firstDayOfWork.toISOString()
            : undefined,
        });
        toast.success(t(`pages.sphere.members.section.update.succeed`));
        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: updatedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);

        toast.error(
          getErrorTranslation({
            t,
            error,
            defaultKey: "pages.sphere.members.section.add.error",
          }),
        );
      }
    } else {
      try {
        const addedMember = await api.addSphereMember({
          name: form.name,
          email: form.email,
          roles: (form.roles! as any[]).map((role: any) => role.value),
          requestByUserId: user.userId,
          sphereId,
          firstDayOfWork: firstDayOfWork
            ? firstDayOfWork.toISOString()
            : undefined,
          subscription: form.subscription?.value || "starter",
          tags: tags?.map((t) => ({ key: t.value, value: t.label })) as any,
        });
        toast.success(t(`pages.sphere.members.section.add.succeed`));
        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: addedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);
        toast.error(
          getErrorTranslation({
            t,
            error,
            defaultKey: "pages.sphere.members.section.add.error",
          }),
        );
      }
    }
    setSaving(false);
  };

  return (
    <div className={styles.page}>
      <PageTitle
        title={t(
          `pages.sphere.members.section.${memberId ? "update" : "add"}.title`,
        )}
      />{" "}
      {isLoading ? (
        <LoadingPage />
      ) : !sphereId ? (
        <div></div>
      ) : (
        <div className={styles.content}>
          <Container>
            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              autoComplete="off"
              noValidate
            >
              <input type="hidden" {...register("id")} />
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="name">
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.name`)} *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t(
                        `pages.sphere.members.section.form.labels.name`,
                      )}
                      {...register("name", {
                        required: t(
                          `pages.sphere.members.section.form.validations.required`,
                        ) as string,
                      })}
                      isInvalid={
                        formState.isSubmitted && !!formState.errors.name
                      }
                    />
                    {formState.errors.name && (
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="email">
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.email`)} *
                    </Form.Label>
                    <Form.Control
                      disabled={!!memberId}
                      placeholder={t(
                        `pages.sphere.members.section.form.labels.email`,
                      )}
                      {...register("email", {
                        required: t(
                          `pages.sphere.members.section.form.validations.required`,
                        ) as string,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t(
                            `pages.sphere.members.section.form.validations.valid_email`,
                          ),
                        },
                      })}
                      isInvalid={
                        formState.isSubmitted && !!formState.errors.email
                      }
                    />
                    {formState.errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.email.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="subscription">
                    <Form.Label>
                      {t(
                        `pages.sphere.members.section.form.labels.subscription`,
                      )}{" "}
                      *
                    </Form.Label>
                    <div className={selectStyles.reactSelect}>
                      <Controller
                        name="subscription"
                        control={control}
                        rules={{
                          required: t(
                            `pages.sphere.members.section.form.validations.required`,
                          ) as string,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={subscriptionOptions}
                            isSearchable={false}
                            isClearable={false}
                            isMulti={false}
                            classNamePrefix="custom-react-select"
                            className={selectStyles.reactSelect}
                            placeholder={t(
                              `pages.sphere.members.section.form.placeholder`,
                            )}
                          />
                        )}
                      />
                      {formState.errors.subscription &&
                        formState.errors.subscription.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="roles">
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.roles`)} *
                    </Form.Label>
                    <div className={selectStyles.reactSelect}>
                      <Controller
                        name="roles"
                        control={control}
                        rules={{
                          required: t(
                            `pages.sphere.members.section.form.validations.required`,
                          ) as string,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={roleOptions}
                            isSearchable
                            isClearable
                            isMulti
                            className={selectStyles.reactSelect}
                            classNamePrefix="custom-react-select"
                            noOptionsMessage={() =>
                              t(
                                `pages.sphere.members.section.form.noOptionMessage`,
                              )
                            }
                            placeholder={t(
                              `pages.sphere.members.section.form.placeholder`,
                            )}
                          />
                        )}
                      />
                      {formState.errors.roles && formState.errors.roles.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      {t(
                        `pages.sphere.members.section.form.labels.firstDayOfWork`,
                      )}
                    </Form.Label>
                    <InputGroup>
                      <DatePicker
                        customInput={
                          <Form.Control
                            type="text"
                            placeholder=""
                            onChange={() => {}}
                            value={
                              firstDayOfWork
                                ? moment(firstDayOfWork).format("LL")
                                : "-"
                            }
                          />
                        }
                        selected={firstDayOfWork}
                        onChange={(date: Date) =>
                          setFirstDayOfWork(date ?? undefined)
                        }
                        minDate={moment()
                          .add(-100, "years")
                          .startOf("year")
                          .toDate()}
                        isClearable={true}
                        dateFormat="dd MMMM yyyy"
                        className={styles.datePicker}
                        yearDropdownItemNumber={10}
                        showYearDropdown
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="tags">
                    <Form.Label>{t(`pages.sphere.sessions.tags`)}</Form.Label>
                    <TagsInput
                      module={`member`}
                      sphereId={sphereId}
                      onChange={setTags}
                      tagsSelected={tags}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="tags">
                    <Form.Label>
                      {t(`pages.sphere.sessions.autoTags`)}
                    </Form.Label>
                    <div className={styles.autoTags}>
                      {" "}
                      {autoTags.map((tag) => (
                        <TagLabel key={tag.id} tag={tag} />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {member && !member?.active && (
                <Row style={{ display: memberId ? "block" : "none" }}>
                  <Col sm={6}>
                    <Form.Group className="form-group" controlId="active">
                      <Form.Check
                        disabled={!couldActivateMember}
                        id="active"
                        label={t(
                          `pages.sphere.members.section.form.labels.active`,
                        )}
                        {...register("active")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {accessMessage && (
                <Row style={{ display: memberId ? "block" : "none" }}>
                  <Col sm={6}>
                    <div className={styles.comment}>
                      <Forbid2LineIcon className={styles.noAccess} />{" "}
                      {accessMessage}
                    </div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col sm={11}>
                  <div className={styles.buttons}>
                    {!!member?.active && (
                      <Button
                        disabled={!!saving || isLoading}
                        variant="danger"
                        className={styles.right}
                        onClick={() => handleDisable()}
                      >
                        {saving === "disable" && (
                          <div className={styles.submitContent}>
                            <ReactLoading
                              type={"bars"}
                              color={"#DDD"}
                              height={"30px"}
                              width={"40px"}
                            />
                            <div className={styles.submitText}>
                              {t("common.action.saving")}
                            </div>
                          </div>
                        )}
                        {!saving &&
                          t(
                            "pages.sphere.members.section.delete.confirmAction",
                          )}
                      </Button>
                    )}

                    <Button
                      variant="secondary"
                      className={styles.right}
                      onClick={() => onSubmit()}
                    >
                      {t("common.action.close")}
                    </Button>

                    <Button
                      disabled={!!saving || isLoading}
                      variant="primary"
                      className={styles.left}
                      name="submit"
                      type="submit"
                    >
                      {saving === "save" && (
                        <div className={styles.submitContent}>
                          <ReactLoading
                            type={"bars"}
                            color={"#DDD"}
                            height={"30px"}
                            width={"40px"}
                          />
                          <div className={styles.submitText}>
                            {t("common.action.saving")}
                          </div>
                        </div>
                      )}
                      {!saving && t("common.action.save")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      )}
    </div>
  );
};

export default AddMemberPage;
