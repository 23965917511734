import { useContext } from "react";
import ErrorNotificationContext from "../context/errorNotificationContext";
import * as Sentry from "@sentry/browser";
import config from "../config";

const isLocal = config.Env === "local";

export function useShowError() {
  const { setError } = useContext(ErrorNotificationContext);
  const showError = (
    description: string,
    detail: string,
    timestamp: string,
  ) => {
    setError({ description, detail, timestamp });

    if (!isLocal) {
      Sentry.captureMessage(description);
    }
  };

  return showError;
}
