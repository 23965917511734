import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import * as motion from "motion/react-m";
import api from "../../api/Api";
import happyFace from "../../assets/images/HappyFace.svg";
import notHappyFace from "../../assets/images/NotHappyFace.svg";
import stylesSalesUp from "./SalesUpPage.module.scss";
import stylesModal from "../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import { useNavigate, useLocation } from "react-router";
import { SphereIcon } from "../../components/header/Header";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { extractLocationData } from "../../tools/pathTools";
import { SessionResponse } from "../../@types/session-api";
import { Sphere } from "../../@types/sphere-api";
import { parse } from "query-string";
import { getSessionName } from "../../tools/sessionTools";
import AbilityContext from "../../context/AbilityContext";

const styles = { ...stylesModal, ...stylesSalesUp };

type Props = {
  onSubmit?: (stateProps: any) => void;
  variants?: any;
};

const SalesUpPage: React.FC<Props> = ({ variants, onSubmit = () => {} }) => {
  const location = useLocation();
  const ability = useContext(AbilityContext);
  const [sphere, setSphere] = useState<Sphere>();
  const [session, setSession] = useState<SessionResponse>();
  const [label, setLabel] = useState<string>();
  const [context, setContext] = useState<Record<string, string>>();
  const [validationCode, setValidationCode] = useState<string>();
  const navigateTo = useNavigate();
  const { t } = useTranslation("i18n");

  const getContextFromValidationCode = (
    validationCode: string,
  ): Record<string, string> => {
    const queryString = Buffer.from(
      decodeURIComponent(validationCode || ""),
      "base64",
    ).toString("utf8");

    const params = parse(queryString);
    const context = JSON.parse(`${params.context}` || "{}");
    return context;
  };

  useEffect(() => {
    const initialize = async () => {
      const data = extractLocationData(location);

      const sphereId = data.queryString?.sphereId;
      const validationCode = data.queryString?.validationCode;
      const sessionId = data.queryString?.sessionId;

      setContext(getContextFromValidationCode(validationCode));
      if ((!sphereId && !sessionId) || !validationCode) {
        toast.error(t("common.invalid"));
        navigateTo("/");
        return;
      }

      //TODO REPLACE WITH CAMPAIGN
      // const result = sphereId
      //   ? await api.validateSphereGuestCode({
      //       sphereId,
      //       entitlement: 'salesUp',
      //       validationCode: validationCode,
      //     })
      //   : await api.validateSessionGuestCode({
      //       sessionId,
      //       validationCode: validationCode,
      //     });

      // if (!result) {
      //   toast.error(t('common.invalid'));
      //   navigateTo('/');
      //   return;
      // }

      if (sphereId) {
        const sphere = (await api.getSphere(sphereId)) as Sphere;
        setSphere(sphere);
        setValidationCode(validationCode);
        setLabel(sphere.name);
      } else if (sessionId) {
        const session = await api.getSession(sessionId);
        const sphere = await api.getSphere(session.sphere.id);
        setSession(session);
        setSphere(sphere);
        setValidationCode(validationCode);
        setLabel(getSessionName(session, ability));
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (mode: string) => {
    onSubmit({
      mode,
      sphere,
      session,
      context,
      validationCode,
      memberCategory: "consumer",
    });
  };
  const [sphereLogo] = sphere?.photoUrls || [];
  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={styles.page}
    >
      <div className={styles.content}>
        <Container className={styles.salesUpContainer}>
          <div className={styles.sphereTitle}>
            {sphereLogo ? (
              <img alt="logo" src={sphereLogo} className={styles.logo} />
            ) : (
              <SphereIcon />
            )}
            <span>{label}</span>
          </div>
          <div className={styles.salesUpContent}>
            <button
              type="button"
              className={styles.button}
              onClick={() => handleClick("bad")}
            >
              <img
                src={notHappyFace}
                alt="nothappyface"
                width="100%"
                height="100%"
              />
            </button>
            <button
              type="button"
              className={styles.button}
              onClick={() => handleClick("good")}
            >
              <img src={happyFace} alt="happyFace" width="100%" height="100%" />
            </button>
          </div>
        </Container>
      </div>
    </motion.div>
  );
};

export default SalesUpPage;
