import {
  Thinking,
  AxisValue,
  ThinkingEventContent,
  Axis,
} from "../@types/thinking-api";
import { MultiLingual } from "../@types/sphere-api";
import { getRelevantTranslationFor } from "./multiLingualTools";
import { getSessionName } from "./sessionTools";
import { SessionLight } from "../@types/seen-apps";
import { Ability } from "@casl/ability";
import { Session } from "../@types/session-api";

export const getReferenceAxis = (thinking: Thinking) => {
  let axe = (thinking.axes || []).find((a) => a.id === "global");
  if (!axe && thinking.axes && thinking.axes.length > 0) {
    axe = thinking.axes[0];
  }

  return axe;
};

export const convertEventToThinking = (
  thinkingEvent: ThinkingEventContent,
  lang: string,
  ability: Ability,
) => {
  const thinking: Thinking = {
    creationDate: thinkingEvent.creationDate,
    freezeDate: thinkingEvent.expirationDate,
    geoPoint: thinkingEvent.geoPoint,
    id: thinkingEvent.id,
    isBlank: !thinkingEvent.values || thinkingEvent.values.length === 0,
    memberCategory: thinkingEvent.memberCategory,
    sphereId: thinkingEvent.sphereId,
    updateDate: thinkingEvent.updateDate,
    session: thinkingEvent.session,
    sessionIds: thinkingEvent.sessionIds,
    tags: thinkingEvent.tags,
    axes: (thinkingEvent.values || []).map((value) => {
      const axis: AxisValue = {
        id: value.id,
        name: getAxisName({
          axis: value,
          sphere: {
            id: thinkingEvent.sphereId,
            name: thinkingEvent.sphereName,
          },
          ability,
        }),
        value: value.value,
        description: getAxisDescription({
          axis: value,
          sphere: {
            id: thinkingEvent.sphereId,
            name: thinkingEvent.sphereName,
          },
          ability,
        }),
      };
      return axis;
    }),
  };
  return thinking;
};

export const getAxisName = ({
  axis,
  sphere,
  session,
  ability,
}: {
  axis:
    | AxisValue
    | {
        id: string;
        value?: number | undefined;
        axe: Axis;
      };
  sphere: { id: string; name: string };
  session?: Session | SessionLight;
  ability: Ability;
}) => {
  const hybrideAxis = axis as AxisValue;
  return getValue(
    (!hybrideAxis.axe
      ? hybrideAxis.name
      : getRelevantTranslationFor(hybrideAxis.axe.name)) || "",
    {
      SPHERE_NAME: getSessionName(session, ability) || sphere.name,
    },
  );
};
export const getAxisDescription = ({
  axis,
  sphere,
  session,
  ability,
}: {
  axis:
    | AxisValue
    | {
        id: string;
        value?: number | undefined;
        axe: Axis;
      };
  sphere: { id: string; name: string };
  session?: Session | SessionLight;
  ability: Ability;
}) => {
  const hybrideAxis = axis as AxisValue;
  return getValue(
    (!hybrideAxis.axe
      ? hybrideAxis.description
      : getRelevantTranslationFor(hybrideAxis.axe.description)) || "",
    {
      SPHERE_NAME: getSessionName(session, ability) || sphere.name,
    },
  );
};

export const getValue = (
  multiLingual: MultiLingual | string | undefined | null,
  autoFillData?: Record<string, string>,
): string => {
  let result = getRelevantTranslationFor(multiLingual);

  if (autoFillData) {
    result = Object.keys(autoFillData).reduce(
      (acc, key) => acc.replace(`%${key}%`, autoFillData[key]),
      result || "",
    );
  }

  return result || "";
};
