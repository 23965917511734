import {
  AndConditions,
  ContainsCondition,
  DisplayCondition,
  EqualsCondition,
  ExistCondition,
  NotCondition,
  OrConditions,
} from "../@types/form-api";
import { uniq } from "lodash";

const GET_KEY_BETWEEN_BRACKET = /\{(.*?)\}/g;

export const evaluateExistsCondition = (
  condition: ExistCondition,
  data: Record<string, unknown>,
) => {
  const keys = Array.isArray(condition.exists)
    ? condition.exists
    : [condition.exists];
  return keys.reduce((acc, key) => {
    const value = data[key];
    return acc && value !== undefined;
  }, true);
};

export const evaluateEqualsCondition = (
  condition: EqualsCondition,
  data: Record<string, unknown>,
) => {
  return Object.keys(condition.equals).reduce((acc, key) => {
    const value = data[key];
    return acc && value === condition.equals[key];
  }, true);
};

export const evaluateConditions = (
  conditions: DisplayCondition | DisplayCondition[] | undefined,
  data: Record<string, unknown> | undefined,
) => {
  if (!conditions) {
    return true;
  }
  const conditionsToEval = Array.isArray(conditions)
    ? conditions
    : [conditions];
  return conditionsToEval.some((condition) =>
    evaluateCondition(condition, data || {}),
  );
};

export const evaluateOrCondition = (
  condition: OrConditions,
  data: Record<string, unknown>,
) => {
  return condition.or.some((condition) => evaluateCondition(condition, data));
};

export const evaluateAndCondition = (
  condition: AndConditions,
  data: Record<string, unknown>,
) => {
  return condition.and.every((condition) => evaluateCondition(condition, data));
};

export const evaluateNotCondition = (
  condition: NotCondition,
  data: Record<string, unknown>,
) => {
  return !evaluateCondition(condition.not, data);
};

export const evaluateContainsCondition = (
  condition: ContainsCondition,
  data: Record<string, unknown>,
) => {
  return Object.keys(condition.contains).reduce<boolean>((acc, key) => {
    const dataValue = data[key];

    const containsCondition = condition.contains[key];
    const conditionValues = Array.isArray(containsCondition)
      ? containsCondition
      : [containsCondition];

    if (Array.isArray(dataValue)) {
      return acc && dataValue.some((val) => conditionValues.includes(val));
    }

    return (
      acc &&
      !!dataValue &&
      conditionValues.some(
        (conditionValue) => `${dataValue}`.indexOf(conditionValue) >= 0,
      )
    );
  }, true);
};

const evaluateCondition = (
  condition: DisplayCondition,
  data: Record<string, unknown>,
): boolean => {
  let result = true;
  if ((condition as ExistCondition).exists) {
    result = evaluateExistsCondition(condition as ExistCondition, data);
  }
  if ((condition as EqualsCondition).equals) {
    result =
      result && evaluateEqualsCondition(condition as EqualsCondition, data);
  }
  if ((condition as NotCondition).not) {
    result = result && evaluateNotCondition(condition as NotCondition, data);
  }
  if ((condition as OrConditions).or) {
    result = result && evaluateOrCondition(condition as OrConditions, data);
  }
  if ((condition as AndConditions).and) {
    result = result && evaluateAndCondition(condition as AndConditions, data);
  }
  if ((condition as ContainsCondition).contains) {
    result =
      result && evaluateContainsCondition(condition as ContainsCondition, data);
  }
  return result;
};

export const replaceDynamicValues = (
  text: string | undefined,
  data: Record<string, unknown>,
) => {
  if (!text) {
    return "";
  }
  const matches = Array.from(text.matchAll(GET_KEY_BETWEEN_BRACKET));
  const keys = uniq(matches.map((match) => match[1]));

  const result = keys.reduce((acc, key) => {
    return acc.replace(`{${key}}`, `${data[key] || ""}`.trim());
  }, text || "");

  return result || "";
};

export const hasDynamicValues = (text: string | undefined) => {
  if (!text) {
    return false;
  }
  const matches = Array.from(text.matchAll(GET_KEY_BETWEEN_BRACKET));
  const keys = uniq(matches.map((match) => match[1]));

  return keys.length > 0;
};
