import { useContext, useEffect, useState } from "react";
import selectStyles from "../../styles/react-select.module.scss";
import styles from "./ColorFacet.module.scss";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { useTranslation } from "react-i18next";
import { Badge, Form } from "react-bootstrap";
import { SET_FILTERS } from "../../context/globalState/globalStateReducer";
import { Filters } from "../../@types/seen-apps";
import Select from "react-select";

type OptionType = {
  value: string;
  label: string;
  count: number;
};

const ColorFacet = ({ displayCount }: { displayCount?: boolean }) => {
  const {
    state: { filters, feelingFacets },
    dispatch,
  } = useContext(GlobalStateContext);
  const { t } = useTranslation("i18n");

  const [colorsSelected, setColorsSelected] = useState<OptionType[]>([]);
  const [colorOptions, setColorOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    const options = feelingFacets.color?.map((facet) => {
      return {
        value: facet.value,
        label: t(`common.filters.feeling.colors.${facet.value}`),
        count: facet.count,
      };
    });
    setColorOptions(options);
  }, [t, feelingFacets]);

  useEffect(() => {
    setColorsSelected(
      filters.feeling.colors?.length
        ? colorOptions.filter((c) =>
            filters.feeling.colors.some((color: string) => color === c.value),
          )
        : [],
    );
  }, [colorOptions, filters.feeling.colors]);

  const onColorChanged = async (valueSelected: any) => {
    const colors = valueSelected.map((v: any) => v.value);
    const updates: Filters = {
      ...filters,
      feeling: {
        ...(filters.feeling || {}),
        colors,
      },
    };
    if (!colors.length) {
      delete updates.feeling.colors;
    }

    dispatch({
      type: SET_FILTERS,
      payload: updates,
    });
  };

  return (
    <Form.Group className="form-group" controlId="code">
      <Form.Label>{t("common.filters.feeling.color")}</Form.Label>
      <div className={selectStyles.select}>
        <Select
          key={`color`}
          name="color"
          options={colorOptions}
          isSearchable={false}
          isClearable
          isMulti
          className={selectStyles.reactSelect}
          classNamePrefix="custom-react-select"
          placeholder={""}
          onChange={onColorChanged}
          value={colorsSelected}
          formatOptionLabel={(color, labelMeta) => {
            if (labelMeta.context === "menu") {
              return (
                <div className={styles.colorChoice}>
                  <div
                    className={`${styles.rectangle} ${styles[color.value]}`}
                  ></div>
                  <span>{color.label}</span>
                  {displayCount && (
                    <div className={styles.count}>
                      <Badge bg="dark">{color.count}</Badge>
                    </div>
                  )}
                </div>
              );
            }
            return (
              <div className={styles.colorChoice}>
                <div
                  className={`${styles.rectangle} ${styles[color.value]}`}
                ></div>
              </div>
            );
          }}
        />
      </div>
    </Form.Group>
  );
};

export default ColorFacet;
