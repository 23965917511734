import { PaginableList } from "../@types/seen-apps";

export const addOrUpdateToPaginableList = <T extends { id: string }>(
  paginableList: PaginableList<T> | undefined,
  toAdd: T,
  defaultMax?: number,
): PaginableList<T> => {
  if (!paginableList) {
    return {
      count: 1,
      max: defaultMax || 50,
      items: [toAdd],
      offset: 0,
    };
  }

  let added = false;
  const list = paginableList.items.reduce((acc, item) => {
    if (item.id === toAdd.id) {
      added = true;
      return [...acc, toAdd];
    }
    return [...acc, item];
  }, [] as T[]);

  if (!added) {
    list.push(toAdd);
  }

  return {
    count: added ? paginableList.count + 1 : paginableList.count,
    max: Math.max(paginableList.items.length, paginableList.max),
    items: list,
    offset: paginableList.offset,
  };
};

export const removeFromPaginableList = <T extends { id: string }>(
  paginableList: PaginableList<T> | undefined,
  toRemove: T,
): PaginableList<T> | undefined => {
  if (!paginableList) {
    return undefined;
  }
  let removed = false;
  const list = paginableList.items.reduce((acc, item) => {
    if (item.id === toRemove.id) {
      removed = true;
      return [...acc];
    }
    return [...acc, item];
  }, [] as T[]);

  return {
    count: removed ? paginableList.count - 1 : paginableList.count,
    max: paginableList.max,
    items: list,
    offset: paginableList.offset,
  };
};
