import { FC, ReactComponentElement } from "react";
import { Container } from "react-bootstrap";
import styles from "./FeelingModeInput.module.scss";

import happyFace from "../../assets/images/HappyFace.svg";
import notHappyFace from "../../assets/images/NotHappyFace.svg";

type Mode = "good" | "bad";

type Props = {
  onChange: (mode: Mode) => void;
  title: ReactComponentElement<any, any>;
};

const FeelingModeInput: FC<Props> = ({ onChange, title }) => {
  const handleClick = (mode: Mode) => {
    onChange(mode);
  };

  return (
    <div className={styles.content}>
      <Container className={styles.salesUpContainer}>
        {title}

        <div className={styles.salesUpContent}>
          <button
            type="button"
            className={styles.button}
            onClick={() => handleClick("bad")}
          >
            <img
              src={notHappyFace}
              alt="nothappyface"
              width="100%"
              height="100%"
            />
          </button>
          <button
            type="button"
            className={styles.button}
            onClick={() => handleClick("good")}
          >
            <img src={happyFace} alt="happyFace" width="100%" height="100%" />
          </button>
        </div>
      </Container>
    </div>
  );
};

export default FeelingModeInput;
