import { useEffect, useState } from "react";
import Select from "react-select";
import { OptionType } from "../FacetFilter/MultiSelectFacet";
import selectStyles from "../../styles/react-select.module.scss";
import { useTranslation } from "react-i18next";
import { OptionsType } from "../../@types/webapp-api";

interface Props {
  onChange: (value: string) => void;
  value: string | undefined;
  operators: string[];
  className?: string;
  placeholder?: string;
}

const OperatorInput = ({
  value,
  onChange,
  className,
  placeholder,
  operators,
}: Props) => {
  const { t } = useTranslation("i18n");
  const [options, setOptions] = useState<OptionsType<OptionType>>([]);

  useEffect(() => {
    setOptions(
      operators.map((operator) => ({
        label: operator,
        value: operator,
        count: 0,
      })),
    );
  }, [operators]);

  const [selectedValue, setSelectedValue] = useState<OptionType>();

  useEffect(() => {
    const selectedValue = options.find((v) => v.value === value);
    setSelectedValue(selectedValue || options[0]);
  }, [value, options]);

  const handleOnChange = (valueSelected: any) => {
    if (onChange) {
      onChange(valueSelected.value);
    }
  };

  return (
    <Select
      onChange={handleOnChange}
      isSearchable={false}
      className={`${selectStyles.reactSelect} ${className}`}
      classNamePrefix="custom-react-select"
      options={options}
      isMulti={false}
      value={selectedValue as any}
      placeholder={placeholder || ""}
      isClearable={false}
      noOptionsMessage={() => t("common.noValue")}
    />
  );
};

export default OperatorInput;
