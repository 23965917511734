import { useState, useEffect, ReactNode } from "react";
import styles from "./StrategyCard.module.scss";

import { Strategy } from "../../@types/strategy-api";
import { getStrategyIcon } from "../../tools/strategyTools";
import {
  HiddenIcon,
  IndentDecreaseIcon,
  IndentIncreaseIcon,
} from "../RemixIcons";
import Card from "../Card/Card";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MarkdownComponent from "../Markdown/MarkdownComponent";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";
import StrategyGoals from "./StrategyGoals";

interface Props {
  dicoPrefix: string;
  strategy: Strategy;
  disabled?: boolean;
  onChange?: (strategy: Strategy) => void;
  hideSphere?: boolean;
  hideSession?: boolean;
  actionIcon?: ReactNode;
}

const StrategyCard = ({
  strategy,
  dicoPrefix,
  disabled,
  actionIcon,
  onChange,
}: Props) => {
  const [icon, setIcon] = useState<any>();
  const { t } = useTranslation("i18n");

  useEffect(() => {
    setIcon(getStrategyIcon(strategy.type));
  }, [strategy]);

  const handleChange = (strategy: Strategy) => {
    if (onChange) {
      onChange(strategy);
    }
  };

  const handleIndentDecrease = () => {
    const indent = strategy.indent || 0;
    const newIndent = Math.max(0, indent - 1);
    handleChange({ ...strategy, indent: newIndent });
  };

  const handleIndentIncrease = () => {
    const indent = strategy.indent || 0;
    const newIndent = Math.max(0, indent + 1);
    handleChange({ ...strategy, indent: newIndent });
  };

  return (
    <div className={styles.root}>
      <div className={styles.strategyType}>
        {strategy.isHidden && <HiddenIcon />}
        {t(`${dicoPrefix}.type.${strategy.type}`)}
      </div>
      <Card
        variant={strategy.isHidden ? "primary" : "secondary"}
        actionIcon={actionIcon}
        title={
          <div className={styles.header}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.strategyTitle}>
              {
                <MarkdownComponent
                  text={getRelevantTranslationFor(strategy.title)}
                />
              }
            </div>
          </div>
        }
      >
        <div className={styles.content}>
          <div className={styles.descriptionContent}>
            <MarkdownComponent
              text={getRelevantTranslationFor(strategy.description)}
            />
          </div>
          <div className={styles.goalsContent}>
            <StrategyGoals strategy={strategy} />
          </div>
          {!disabled && (
            <div className={styles.actions}>
              <Button
                disabled={!strategy.indent || strategy.indent < 1}
                size="sm"
                variant="secondary"
                onClick={handleIndentDecrease}
              >
                <IndentDecreaseIcon />
              </Button>
              <Button
                disabled={!!strategy.indent && strategy.indent >= 5}
                size="sm"
                variant="secondary"
                onClick={handleIndentIncrease}
              >
                <IndentIncreaseIcon />
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
export default StrategyCard;
