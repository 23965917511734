import { FC, ReactNode } from "react";
import styles from "./Welcome.module.scss";
import Welcome from "./Welcome";
import "react-toastify/dist/ReactToastify.css";

export type Props = {
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
};

const WelcomeHeader: FC<Props> = ({ children, disabled, className }) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Welcome readonly={disabled} />
        <div className={`${styles.component} ${className ?? ""}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default WelcomeHeader;
