/* eslint-disable */
import { useState, useEffect, FC, useRef } from "react";
import styles from "./DateRangePickerInput.module.scss";
import moment from "moment";

import { useTranslation } from "react-i18next";
import {
  InputRange,
  StaticRange,
  RangeKeyDict,
  DefinedRange,
  DateRange,
} from "react-date-range";
import { getCurrentLocale } from "../../i18n";
import { format } from "date-fns";
import { Button, ButtonGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { CloseLineIcon, RangeDatePickerIcon } from "../RemixIcons";

const DEFAULT_STATICS: {
  unit: "day" | "week" | "month" | "quarter" | "year";
  period: "this" | "last";
}[] = [
  {
    unit: "day",
    period: "this",
  },
  {
    unit: "week",
    period: "this",
  },
  {
    unit: "week",
    period: "last",
  },
  {
    unit: "month",
    period: "this",
  },
  {
    unit: "month",
    period: "last",
  },
  {
    unit: "quarter",
    period: "this",
  },
  {
    unit: "quarter",
    period: "last",
  },
  {
    unit: "year",
    period: "this",
  },
  {
    unit: "year",
    period: "last",
  },
];

const DEFAULT_INPUTS: ("day" | "month" | "year")[] = ["day", "month", "year"];

const DATE_FORMAT = "dd/MM/yyyy";

export type Range = { startDate: Date; endDate: Date };

type Props = {
  maxDate: Date;
  minDate: Date;

  compact?: boolean;
  warningIfNotToday?: boolean;
} & (
  | {
      allowUndefined: true;
      onChange: (value: Range | undefined) => void;
      value: Range | undefined;
    }
  | { allowUndefined?: false; onChange: (value: Range) => void; value: Range }
);

const DateRangePickerInput: FC<Props> = ({
  value,
  onChange,
  maxDate,
  minDate,
  compact,
  allowUndefined,
  warningIfNotToday,
}) => {
  const { t } = useTranslation("i18n");

  const fakeEvent = useRef<HTMLDivElement>() as any;
  const [range, setRange] = useState<Range | undefined>({
    startDate: value?.startDate || minDate,
    endDate: value?.endDate || (null as unknown as Date),
  });
  const [todayIsInRange, setTodayIsInRange] = useState<boolean>(true);

  useEffect(() => {
    setTodayIsInRange(
      !value || moment().isBetween(value.startDate, value.endDate),
    );
  }, [value]);

  const [inputRanges] = useState<InputRange[]>(
    DEFAULT_INPUTS.map((unit) => ({
      label: t(`pages.myHistory.filter.last_${unit}s`),
      range(value: number) {
        return {
          startDate: moment().subtract(value, unit).toDate(),
          endDate: moment().toDate(),
        };
      },
      getCurrentValue(range) {
        const isToday = moment(range.endDate).isSame(moment(), "day");
        const response = moment().diff(range.startDate, unit, false);

        const date = new Date().getDate();
        const month = new Date().getMonth();

        const startOf =
          unit === "day"
            ? true
            : unit === "month"
              ? range.startDate?.getDate() === date
              : range.startDate?.getDate() === date &&
                range.startDate?.getMonth() === month;

        if (!isToday || !startOf) return "-";

        return response;
      },
    })),
  );

  const [staticRanges] = useState<StaticRange[]>(
    DEFAULT_STATICS.map((props) => ({
      label: t(`pages.myHistory.filter.${props.period}_${props.unit}`),
      range() {
        return {
          startDate: moment()
            .subtract(props.period === "this" ? 0 : 1, props.unit)
            .startOf(props.unit)
            .toDate(),
          endDate: moment()
            .subtract(props.period === "this" ? 0 : 1, props.unit)
            .endOf(props.unit)
            .toDate(),
        };
      },
      isSelected: (range) => {
        const isToday = moment(range.endDate).isSame(moment(), "day");

        const startDateSame = moment(range.startDate).isSame(
          moment()
            .subtract(props.period === "this" ? 0 : 1, props.unit)
            .startOf(props.unit),
          "day",
        );

        const endDateSame = moment(range.endDate).isSame(
          moment()
            .subtract(props.period === "this" ? 0 : 1, props.unit)
            .endOf(props.unit),
          "day",
        );

        return (
          ((props.period === "this" && isToday) || endDateSame) && startDateSame
        );
      },
    })),
  );

  const closePopup = () => {
    fakeEvent.current.click();
  };

  const onApply = () => {
    onChange(range as Range);
    closePopup();
  };

  const changeDateRange = (item: RangeKeyDict) => {
    const [selection] = Object.values(item);
    setRange({
      startDate: moment(selection.startDate || value?.startDate)
        .startOf("day")
        .toDate(),
      endDate: moment(selection.endDate || value?.endDate)
        .endOf("day")
        .toDate(),
    });
  };
  const changeDateRangeWithStatic = (item: RangeKeyDict) => {
    const [selection] = Object.values(item);
    const range = {
      startDate: moment(selection.startDate || value?.startDate)
        .startOf("day")
        .toDate(),
      endDate: moment(selection.endDate || value?.endDate)
        .endOf("day")
        .toDate(),
    };
    setRange(range);
    onChange(range);
    closePopup();
  };

  const onClearDateRange = () => {
    if (allowUndefined) {
      setRange(undefined);
      onChange(undefined);
      closePopup();
    }
  };

  return (
    <ButtonGroup>
      <div ref={fakeEvent}></div>
      <OverlayTrigger
        trigger="click"
        rootClose
        key="DateRangeSelection"
        placement={"bottom"}
        overlay={
          <Popover id={`popover-positioned-bottom`} className={styles.popover}>
            <Popover.Body className={styles.popoverContent}>
              <DateRange
                editableDateInputs
                startDatePlaceholder={t("common.dateRange.startDate")}
                endDatePlaceholder={t("common.dateRange.endDate")}
                locale={getCurrentLocale()}
                ranges={[range || {}]}
                maxDate={maxDate}
                minDate={minDate}
                scroll={{ enabled: false }}
                onChange={changeDateRange}
                dateDisplayFormat={DATE_FORMAT}
                dayContentRenderer={(day: Date) => {
                  return (
                    <div>
                      <span>
                        <span className="dayCustomLabel">
                          {format(day, "d")}
                        </span>
                      </span>
                    </div>
                  );
                }}
              />
            </Popover.Body>
            <Popover.Body className={styles.popoverContent}>
              <Button
                onClick={onApply}
                variant="primary"
                className={styles.applyButton}
              >
                {t("common.action.apply")}
              </Button>
            </Popover.Body>
          </Popover>
        }
      >
        <Button
          className={styles.dateLabelButton}
          variant={
            warningIfNotToday && !todayIsInRange ? "warning" : "secondary"
          }
        >
          {!value
            ? ""
            : `${format(value.startDate, DATE_FORMAT)} - ${format(
                value.endDate,
                DATE_FORMAT,
              )}`}
        </Button>
      </OverlayTrigger>
      <OverlayTrigger
        trigger="click"
        rootClose
        key="DateRangeSelection2"
        placement={"bottom"}
        overlay={
          <Popover id={`popover-positioned-bottom`} className={styles.popover}>
            <Popover.Body className={styles.popoverContent}>
              <DefinedRange
                className={styles.staticRange}
                ranges={[range || {}]}
                staticRanges={staticRanges}
                inputRanges={[]}
                onChange={changeDateRangeWithStatic}
              />
            </Popover.Body>
            <Popover.Body className={styles.popoverContent}>
              <DefinedRange
                className={styles.inputRange}
                ranges={[range || {}]}
                staticRanges={[]}
                inputRanges={inputRanges}
                onChange={changeDateRange}
              />
            </Popover.Body>
            <Popover.Body className={styles.popoverContent}>
              <Button
                onClick={onApply}
                variant="primary"
                className={styles.applyButton}
              >
                {t("common.action.apply")}
              </Button>
            </Popover.Body>
          </Popover>
        }
      >
        {compact ? (
          <></>
        ) : (
          <Button variant="secondary">
            <RangeDatePickerIcon />
          </Button>
        )}
      </OverlayTrigger>
      {allowUndefined && (
        <Button
          variant={"secondary"}
          className={styles.closeButton}
          size="sm"
          onClick={onClearDateRange}
        >
          <CloseLineIcon />
        </Button>
      )}
    </ButtonGroup>
  );
};

export default DateRangePickerInput;
