import { createConfirmation } from "react-confirm";
import ConfirmModal, {
  ConfirmModalOptions,
} from "../components/ModalWrapper/ConfirmModal";

// create confirm function
export const confirm = createConfirmation(ConfirmModal);

// This is optional. But wrapping function makes it easy to use.
export function confirmWrapper(
  confirmation: string | JSX.Element,
  options: ConfirmModalOptions = {},
) {
  return confirm({ confirmation, options });
}
