import { useEffect, useState, useReducer } from "react";
import styles from "./App.module.scss";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import config from "./config";
import api from "./api/Api";
import wsApi from "./api/WebSocketApi";
import globalStateReducer, {
  SET_LANGUAGE,
  SET_DEVICE_NAME,
  initialState,
  SET_USER,
  SET_PROFILE,
  SET_EMAILS,
  SET_SPHERES,
  SET_MEMBERS,
  SET_ABILITY,
  SET_WORDS,
  SET_SPHERE_CATEGORIES,
  ADD_OR_UPDATE_FEELINGS,
  ADD_OR_UPDATE_THINKINGS,
  SET_SESSIONS,
} from "./context/globalState/globalStateReducer";
import AppRoutes from "./AppRoutes";
import * as Sentry from "@sentry/browser";
import ErrorNotificationToast from "./components/errorNotificationToast/ErrorNotificationToast";
import { useShowError } from "./tools/useShowError";
import withErrorNotifications from "./tools/withErrorNotification";
import LayoutContextProvider from "./context/layout/LayoutContextProvider";
import NavigationMenu from "./components/side-menus/NavigationMenu";
import SettingsMenu from "./components/side-menus/SettingsMenu";
import { BrowserRouter as Router } from "react-router";
import Header from "./components/header/Header";
import { AbilityProvider } from "./context/AbilityContext";
import { Ability } from "@casl/ability";
import GlobalStateContextProvider from "./context/globalState/GlobalStateContextProvider";
import moment from "moment";
import { withAuthentication } from "./tools/withAuthentication";
import { LazyFeatureBundle, LazyMotion } from "motion/react";
import FilterMenu from "./components/side-menus/FilterMenu";
import PortalMenu from "./components/side-menus/PortalMenu";
import SignUpPage from "./pages/user/SignUpPage";
import ParticipationHeader from "./pages/participation/ParticipationHeader";
import ParticipationMenu from "./pages/participation/ParticipationMenu";
import ParticipationLayoutContextProvider from "./context/participation/ParticipationLayoutContextProvider";

const isLocal = config.Env === "local";

Amplify.configure({ Auth: config.Auth, API: config.API });

export const FILESTACK = "AAhS17hRGQgai3T15k9Mwz";

const loadMotionFeatures: LazyFeatureBundle = () =>
  import("./motionFeatures").then((res) => res.default);

const App = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [ability, setAbility] = useState<Ability>(new Ability());
  const showError = useShowError();

  const [forceRegistration, setForceRegistration] = useState<boolean>(
    localStorage.getItem("forceRegistration") === "true",
  );

  const [globalState, dispatch] = useReducer(globalStateReducer, initialState);

  const handleResize = () => {
    const winW = document.documentElement.clientWidth;
    const winH = document.documentElement.clientHeight;
    const appNode = document.getElementById("appRoot");
    if (appNode) {
      if (!isLocal) {
        Sentry.withScope(function (scope) {
          scope.setExtra("display.width", winW);
          scope.setExtra("display.height", winH);
        });
      }
    }
  };

  useEffect(() => {
    if (globalState?.state?.user?.userId) {
      wsApi.reconnect(globalState.state.user.userId);
    }
  }, [globalState?.state?.user?.userId]);

  useEffect(() => {
    wsApi.setDispatch(dispatch);

    // redirect all http error to toastr
    api.setErrorCallback(showError);
    const initialize = async () => {
      //Initialize memory
      const myselfData = await api.getMySelf();
      const { profile, emails, userId, spheres, ability, members, sessions } =
        myselfData;

      dispatch({
        type: SET_LANGUAGE,
        payload: profile.locale,
      });
      dispatch({
        type: SET_USER,
        payload: {
          userId,
        },
      });
      dispatch({
        type: SET_PROFILE,
        payload: profile,
      });
      dispatch({
        type: SET_SPHERES,
        payload: spheres,
      });
      dispatch({
        type: SET_SESSIONS,
        payload: sessions,
      });
      dispatch({
        type: SET_MEMBERS,
        payload: members,
      });
      dispatch({
        type: SET_ABILITY,
        payload: ability,
      });
      dispatch({
        type: SET_EMAILS,
        payload: emails,
      });

      // handle resize app
      handleResize();
      window.addEventListener("resize", handleResize);
      // retrieve data from local storage

      const deviceName = localStorage?.getItem("deviceName") || "";
      dispatch({
        type: SET_DEVICE_NAME,
        payload: deviceName,
      });

      setInitialized(true);
    };

    const initializeFeeling = async () => {
      const feelings = await api.getFeelings(
        moment().add(-24, "h").toDate(),
        moment().toDate(),
      );
      dispatch({
        type: ADD_OR_UPDATE_FEELINGS,
        payload: feelings,
      });
    };

    const initializeThinking = async () => {
      const thinkings = await api.getThinkings(
        moment().add(-24, "h").toDate(),
        moment().toDate(),
      );
      dispatch({
        type: ADD_OR_UPDATE_THINKINGS,
        payload: thinkings,
      });
    };

    const initializeData = async () => {
      const feelingWordsTask = api.getFeelingWords();
      const sphereCategoryTask = api.getSphereCategories();

      const words = await feelingWordsTask;
      dispatch({
        type: SET_WORDS,
        payload: words,
      });
      dispatch({
        type: SET_SPHERE_CATEGORIES,
        payload: await sphereCategoryTask,
      });
    };

    const fileStackscript = document.createElement("script");

    fileStackscript.src =
      "//static.filestackapi.com/filestack-js/3.x.x/filestack.min.js";
    fileStackscript.async = true;

    document.body.appendChild(fileStackscript);

    initialize();
    initializeData();
    initializeThinking();
    initializeFeeling();

    return () => {
      document.body.removeChild(fileStackscript);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ability = globalState.state.ability
      ? new Ability(globalState.state.ability.rules)
      : new Ability();

    setAbility(ability);
  }, [globalState.state.ability]);

  const isGuest = globalState.state?.profile?.isGuest;

  const handleSignup = () => {
    localStorage.removeItem("forceRegistration");
    setForceRegistration(false);
  };

  const handleCancelSignup = async () => {
    handleSignup();
    await signOut();
  };

  return (
    <LazyMotion features={loadMotionFeatures} strict>
      <Router>
        <div
          id="appRoot"
          className={`${styles.root} ${isGuest ? styles.guestRoot : ""}`}
        >
          <LayoutContextProvider>
            <GlobalStateContextProvider value={{ ...globalState, dispatch }}>
              <AbilityProvider value={ability}>
                {forceRegistration ? (
                  <SignUpPage
                    onSubmit={handleSignup}
                    onCancel={handleCancelSignup}
                  />
                ) : globalState.state.context.participationCtx ? (
                  <ParticipationLayoutContextProvider>
                    <ParticipationHeader />
                    <ParticipationMenu />
                    <AppRoutes initialized={initialized} />
                  </ParticipationLayoutContextProvider>
                ) : (
                  <div className={styles.appContent}>
                    <Header />
                    <NavigationMenu />
                    <SettingsMenu />
                    <FilterMenu />
                    <PortalMenu />
                    <AppRoutes initialized={initialized} />
                  </div>
                )}
                <ErrorNotificationToast />
              </AbilityProvider>
            </GlobalStateContextProvider>
          </LayoutContextProvider>
        </div>
      </Router>
    </LazyMotion>
  );
};

export default withAuthentication(withErrorNotifications(App));
