import { useState, useEffect } from "react";
import { Feeling, FeelingGoal } from "../../../@types/seen-apps";

import styles from "./GoalForm.module.scss";
import GoalsResume from "./GoalsResume";
import GoalForm from "./GoalForm";
import GoalSelect from "./GoalSelect";

import AbilityContext from "../../../context/AbilityContext";
import { useAbility } from "@casl/react";
import { Action, Subject } from "../../../@types/member-api";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type FeelingGoalsProps = {
  feeling: Feeling;
  values: FeelingGoal[];
  disabled?: boolean;
  onChange: (values: FeelingGoal[]) => void;
};

const FeelingGoals = ({
  values,
  disabled,
  feeling,
  onChange,
}: FeelingGoalsProps) => {
  const [showGoalSelection, setShowGoalSelection] = useState<boolean>(false);
  const [goalsValidated, setGoalsValidated] = useState<FeelingGoal[]>([]);
  const [goalsToUpdate, setGoalsToUpdate] = useState<FeelingGoal[]>([]);

  const { t } = useTranslation("i18n");
  const ability = useAbility(AbilityContext);
  const [featureAvailable, setFeatureAvailable] = useState<boolean>(false);
  useEffect(() => {
    setFeatureAvailable(ability.can(Action.Put, Subject.FeelingGoal));
  }, [ability]);

  useEffect(() => {
    if (disabled) {
      setShowGoalSelection(false);
      setGoalsValidated(values);
      return;
    }

    const goalsValidated = values.filter((g) => g.validated);
    setGoalsValidated(goalsValidated);
    const goalToUpdate = values.filter((g) => !g.validated);
    setGoalsToUpdate(goalToUpdate);
    setShowGoalSelection(!values.length || values.every((g) => g.validated));
  }, [values, disabled]);

  const handleGoalChanged = (goal: FeelingGoal) => {
    const newGoals = values?.map((g) => (g.id === goal.id ? goal : g));
    onChange(newGoals);
  };

  const handleGoalDeleted = (goal: FeelingGoal) => {
    onChange(values?.filter((g) => g.id !== goal.id));
  };

  const handleGoalAdded = (goalId: string, ranking: number) => {
    onChange([
      ...values,
      {
        id: goalId,
        level: 0,
        subs: [],
        ranking,
      },
    ]);
  };

  return (
    <div className={styles.feelingGoals}>
      {featureAvailable ? (
        <>
          <GoalsResume
            values={goalsValidated}
            disabled={disabled}
            feeling={feeling}
            onChange={handleGoalChanged}
            onDelete={handleGoalDeleted}
          />
          {goalsToUpdate.map((goal, index) => (
            <GoalForm
              key={index}
              value={goal}
              onChange={(goal) => handleGoalChanged(goal)}
              onDelete={() => handleGoalDeleted(goal)}
            />
          ))}
        </>
      ) : (
        <GoalsResume
          values={[]}
          disabled={disabled}
          feeling={feeling}
          onChange={() => {}}
          onDelete={() => {}}
        />
      )}
      {showGoalSelection && (
        <div className={styles.form}>
          <Container className={styles.noMargin}>
            <Row className={styles.noMargin}>
              <Col sm={6} className={styles.noMargin}>
                {values?.length
                  ? t("pages.feeling.goal.titleOther")
                  : t("pages.feeling.goal.title")}
              </Col>
              <Col sm={6} className={styles.noMargin}>
                <GoalSelect
                  featureAvailable={featureAvailable}
                  goalsExluded={values.map((g) => g.id)}
                  onSelect={(goal, ranking) => handleGoalAdded(goal, ranking)}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default FeelingGoals;
