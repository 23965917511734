import { TreeNode } from "rc-tree";

import { SessionTree } from "../../@types/webapp-api";

import SessionIcon from "./SessionIcon";

import styles from "./SelectParentSessionInput.module.scss";

const SessionNodes = (
  sessionTree: SessionTree,
  level: number,
  indent: number,
) => {
  return sessionTree.children.length > 0 ? (
    <TreeNode
      expanded
      className="sessionTree"
      icon={
        <SessionIcon className={styles.icon} session={sessionTree.session} />
      }
      key={sessionTree.key}
      title={sessionTree.label}
    >
      {sessionTree.children.map((session) =>
        SessionNodes(session, level + 1, indent + 32),
      )}
    </TreeNode>
  ) : (
    <TreeNode
      isLeaf
      icon={
        <SessionIcon className={styles.icon} session={sessionTree.session} />
      }
      key={sessionTree.key}
      title={sessionTree.label}
    />
  );
};

export default SessionNodes;
