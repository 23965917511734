import { Filters, FacetValue } from "../../@types/seen-apps";
import MultiSelectFacet, { OptionType } from "./MultiSelectFacet";
import { useTranslation } from "react-i18next";

const TopGoalMultiSelectFacet = () => {
  const { t } = useTranslation("i18n");

  return (
    <MultiSelectFacet
      label={t("common.filters.feeling.goals.top.title")}
      placeholder=""
      isMulti={false}
      isClearable={true}
      isSearchable={false}
      facetName="goalRankings"
      convertFacetsToOptions={async (facets: FacetValue[]) => {
        const data = facets
          .filter((f) => f.value)
          .sort((f1, f2) => parseInt(f1.value) - parseInt(f2.value));
        return data.map((f) => {
          return {
            count: f.count,
            label: t(`common.filters.feeling.goals.top.content`, {
              top: f.label || f.value,
            }),
            value: f.value,
          };
        });
      }}
      updateFilters={(filters: Filters, options: OptionType[]) => {
        const updates = {
          ...filters,
          feeling: {
            ...(filters.feeling || {}),
            goalRankings: options.map((o) => parseInt(o.value)),
          },
        };
        return updates;
      }}
      optionsSelected={(filters: Filters, options: OptionType[]) => {
        const goalRankings = (filters.feeling.goalRankings || []) as string[];
        return options.filter((option) =>
          goalRankings.some((id) => option.value === id),
        );
      }}
    />
  );
};

export default TopGoalMultiSelectFacet;
