import { useState, useContext, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import styles from "./ErrorNotificationToast.module.scss";
import { useTranslation } from "react-i18next";
import ErrorNotificationContext from "../../context/errorNotificationContext";
import { ErrorNotificationCtx } from "../../@types/seen-apps";

const ErrorNotificationToast = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation("i18n");
  const { error } = useContext<ErrorNotificationCtx>(ErrorNotificationContext);

  useEffect(() => {
    if (error) {
      setShow(true);
    }
  }, [error]);
  return (
    <>
      {show && (
        <div className={styles.root}>
          <Alert variant="danger">
            <div className={styles.container}>
              <span>
                {error && error.description
                  ? error.description
                  : t("modal.errorMsg", {
                      description: error ? error.detail : "",
                    })}
              </span>
              <Button variant="danger" size="sm" onClick={() => setShow(false)}>
                {t("modal.btnClose")}
              </Button>
            </div>
          </Alert>
        </div>
      )}
    </>
  );
};

export default ErrorNotificationToast;
