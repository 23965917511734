import React from "react";
import stylesModal from "../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import stylesSpheres from "./SphereNotAllowed.module.scss";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { Forbid2LineIcon } from "../../components/RemixIcons";
import PageTitle from "../../components/PageTitle/PageTitle";

const styles = { ...stylesModal, ...stylesSpheres };

const SphereNotAllowed: React.FC = () => {
  const { t } = useTranslation("i18n");

  return (
    <div className={styles.page}>
      <PageTitle title={t(`pages.sphere.notAllowed.title`)} />
      <div className={styles.content}>
        <Container className={styles.root}>
          <Row>
            <Col sm={12}>
              <h3 className={styles.description}>
                <Forbid2LineIcon className={styles.icon} />
                {t(`pages.sphere.notAllowed.desc`)}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SphereNotAllowed;
