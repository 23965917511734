import React, { useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import api from "../../api/Api";
import { toast } from "react-toastify";
import Select from "react-select";
import selectStyles from "../../styles/react-select.module.scss";
import differenceWith from "lodash.differencewith";
import pageStyles from "./AddOrUpdateSuperAdminMemberPage.module.scss";
import modalStyles from "../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import PageTitle from "../../components/PageTitle/PageTitle";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { ADD_OR_UPDATE_SPHERE_MEMBER } from "../../context/globalState/globalStateReducer";
import { captureException } from "@sentry/minimal";
import LoadingPage from "../loading/LoadingPage";
import { extractLocationData } from "../../tools/pathTools";
import { Role } from "../../@types/member-api";
import { useAbility } from "@casl/react";
import AbilityContext from "../../context/AbilityContext";
import { DeleteBinLineIcon } from "../../components/RemixIcons";
import { confirmWrapper } from "../../tools/confirm";
import { OptionsType } from "../../@types/webapp-api";

const styles = { ...modalStyles, ...pageStyles };

type Props = {
  currentPageIdx?: number;
  onSubmit?: (state?: any) => void;
};

type FormInputs = {
  id: string;
  email: string;
  name: string;
  roles: OptionType[];
  active: boolean;
};

type OptionType = {
  value: string;
  label: string;
};

const AddOrUpdateSuperAdminMemberPage: React.FC<Props> = ({
  onSubmit = () => {},
}) => {
  const { t } = useTranslation("i18n");
  const location = useLocation();
  const ability = useAbility(AbilityContext);

  const {
    state: { user },
    dispatch,
  } = useContext(GlobalStateContext);
  const [isLoading, setLoading] = useState(false);
  const [memberId, setMemberId] = useState<string>();
  const [userLinked, setUserLinked] = useState<boolean>(true);
  const [isVip, setIsVip] = useState<boolean>(false);
  const { register, handleSubmit, reset, formState, control } =
    useForm<FormInputs>({
      defaultValues: {
        id: "",
        email: "",
        name: "",
        roles: [],
        active: true,
      },
    });

  const [roleOptions] = useState<OptionsType<OptionType>>(() => {
    const eOptions = [Role.SA, Role.AMBASSADOR, Role.PREVIEW_ACCESS].map(
      (role) => ({ value: role, label: t(`common.roles.${role}`) }),
    );
    return eOptions;
  });

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    const isSuperAdmin = ability.can("manage", "member");
    setIsSuperAdmin(isSuperAdmin);

    if (!isSuperAdmin) {
      onSubmit();
    }
  }, [ability, onSubmit]);

  useEffect(() => {
    const fetchMember = async (memberId: string) => {
      setLoading(true);
      const member = await api.getGlobalMember(memberId);
      const formToReset = {
        ...member,
        roles: roleOptions.filter((option: OptionType) =>
          (member.roles as any[])?.find((role) => role === option.value),
        ),
      };

      const result = differenceWith(
        member.roles as string[],
        roleOptions as OptionType[],
        (roleStr: string, { value }: OptionType) => roleStr === value,
      );
      result.forEach((missingRole: string) =>
        formToReset.roles.push({
          value: missingRole,
          label: t(`common.roles.${missingRole}`),
        }),
      );
      reset(formToReset);
      setUserLinked(member.userLinked);
      setIsVip(
        member.roles.some((role) =>
          [Role.SA, Role.AMBASSADOR, Role.PREVIEW_ACCESS].some(
            (r) => r === role,
          ),
        ),
      );
      setLoading(false);
    };

    const data = extractLocationData(location);
    setMemberId(data.queryString?.memberId);

    if (data.queryString?.memberId) {
      fetchMember(data.queryString.memberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onSubmitForm: SubmitHandler<FormInputs> = async (form) => {
    if (form.id) {
      try {
        const updatedMember = await api.updateGlobalMember(form.id, {
          name: form.name,
          roles: (form.roles! as any[]).map((role: any) => role.value),
          active: form.active,
          requestByUserId: user.userId,
        });
        toast.success(t(`pages.sphere.members.section.update.succeed`));
        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: updatedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);
        toast.error(
          t("pages.sphere.members.section.add.error", { error: error }),
        );
      }
    } else {
      try {
        const addedMember = await api.addGlobalMember({
          name: form.name,
          email: form.email,
          roles: (form.roles! as any[]).map((role: any) => role.value),
          requestByUserId: user.userId,
        });
        toast.success(t(`pages.sphere.members.section.add.succeed`));
        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: addedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);
        toast.error(
          t("pages.sphere.members.section.add.error", { error: error }),
        );
      }
    }
  };
  const onRemoveAccess = async () => {
    if (
      memberId &&
      (await confirmWrapper(
        t(`pages.superAdmin.members.removeAccess.confirm`),
        {
          btnPrimaryLabel: t(
            `pages.superAdmin.members.removeAccess.confirmAction`,
          ),
          title: t(`pages.superAdmin.members.removeAccess.confirmAction`),
        },
      ))
    ) {
      try {
        const updatedMember = await api.updateGlobalMember(memberId, {
          roles: [Role.MEMBER],
          requestByUserId: user.userId,
        });
        toast.success(t(`pages.superAdmin.members.removeAccess.succeed`));

        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: updatedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);
        toast.error(t(`pages.superAdmin.members.removeAccess.error`));
      }
    }
  };

  return !isSuperAdmin ? (
    <div></div>
  ) : (
    <div className={styles.page}>
      <PageTitle
        title={t(
          `pages.sphere.members.section.${memberId ? "update" : "add"}.title`,
        )}
      />{" "}
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className={styles.content}>
          <Container>
            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              autoComplete="off"
              noValidate
            >
              <input type="hidden" {...register("id")} />
              <Row>
                <Col sm={12}>
                  <Alert variant="warning">
                    {t(`pages.superAdmin.members.warning`)}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="name">
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.name`)} *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t(
                        `pages.sphere.members.section.form.labels.name`,
                      )}
                      {...register("name", {
                        required: t(
                          `pages.sphere.members.section.form.validations.required`,
                        ) as string,
                      })}
                      isInvalid={
                        formState.isSubmitted && !!formState.errors.name
                      }
                    />
                    {formState.errors.name && (
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {!memberId && (
                <Row>
                  <Col sm={6}>
                    <Alert variant="info">
                      {t(`pages.superAdmin.members.creation`)}
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="email">
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.email`)} *
                    </Form.Label>
                    <Form.Control
                      disabled={!!memberId}
                      placeholder={t(
                        `pages.sphere.members.section.form.labels.email`,
                      )}
                      {...register("email", {
                        required: t(
                          `pages.sphere.members.section.form.validations.required`,
                        ) as string,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t(
                            `pages.sphere.members.section.form.validations.valid_email`,
                          ),
                        },
                      })}
                      isInvalid={
                        formState.isSubmitted && !!formState.errors.email
                      }
                    />
                    {formState.errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.email.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Alert variant="danger">
                    {t(`pages.superAdmin.members.superAdminDanger`)}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="roles">
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.roles`)} *
                    </Form.Label>

                    <div className={selectStyles.reactSelect}>
                      <Controller
                        name="roles"
                        control={control}
                        rules={{
                          required: t(
                            `pages.sphere.members.section.form.validations.required`,
                          ) as string,
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={roleOptions}
                            isSearchable
                            isClearable
                            isMulti
                            noOptionsMessage={() =>
                              t(
                                `pages.sphere.members.section.form.noOptionMessage`,
                              )
                            }
                            className={selectStyles.reactSelect}
                            classNamePrefix="custom-react-select"
                            placeholder={t(
                              `pages.sphere.members.section.form.placeholder`,
                            )}
                          />
                        )}
                      />
                      {formState.errors.roles && formState.errors.roles.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {!userLinked && (
                <Row style={{ display: memberId ? "block" : "none" }}>
                  <Col sm={6}>
                    <Alert variant="info">
                      {t("pages.sphere.members.section.access.userNotFound")}
                    </Alert>
                  </Col>
                </Row>
              )}

              <Row>
                <Col sm={6} className={styles.buttons}>
                  {isVip && (
                    <Button
                      onClick={onRemoveAccess}
                      variant="danger"
                      className={styles.resetButtonFilter}
                    >
                      <DeleteBinLineIcon className={styles.resetFilter} />
                      {t("pages.superAdmin.members.removeAccess.action")}
                    </Button>
                  )}

                  <Button
                    variant="secondary"
                    className={styles.right}
                    onClick={() => onSubmit()}
                  >
                    {t("common.action.close")}
                  </Button>
                  <Button name="submit" type="submit">
                    {t("common.action.save")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      )}
    </div>
  );
};

export default AddOrUpdateSuperAdminMemberPage;
