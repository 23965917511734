import { fetchAuthSession } from "aws-amplify/auth";
import * as API from "@aws-amplify/api-rest";

import {
  Myself,
  GeoPoint,
  Profile,
  Email,
  feelingCategory,
  FeelingEvaluationPeriod,
  FeelingGeneralReportResponse,
  ThinkingGeneralReportResponse,
  FeelingType,
  Module,
} from "../@types/webapp-api";
import {
  FacetResponse,
  Feeling,
  FeelingGoal,
  FeelingWord,
  FilterDate,
  Note,
  PaginableList,
  PostBulkMembers,
  PostMember,
  PostTag,
} from "../@types/seen-apps";
import {
  Thinking,
  Axis,
  ThinkingCreateRequest,
  SphereAxeResponse,
  SphereAxe,
} from "../@types/thinking-api";
import {
  Category,
  ReportGroup,
  Sphere,
  SphereReportGroupFromDB,
  SphereSubscriptionReport,
  SphereTagFacetResponse,
  SphereTagSelectionPut,
  SphereTagSelectionResponse,
  Tag,
} from "../@types/sphere-api";
import { v4 as uuidv4 } from "uuid";
import { Session, SessionPost, SessionPut } from "../@types/session-api";
import {
  MemberResponse,
  SessionMemberPost,
  SessionMemberPut,
  Subject,
  Subscription,
} from "../@types/member-api";

import moment from "moment-timezone";
import {
  Form,
  FormEntitlementBody,
  FormEntitlementResponse,
  FormReportResponse,
  LightForm,
  FormSurvey,
  FormSurveyPost,
  FormSurveyPut,
  FormReportDefinition,
} from "../@types/form-api";
import {
  UrlAlias,
  UrlCampaignResponse,
  UrlCreationInput,
  UrlUpdateInput,
} from "../@types/shorten-url-api";
import {
  Strategy,
  StrategyAssessment,
  StrategyAssessmentPost,
  StrategyAssessmentPut,
  StrategyPost,
  StrategyPut,
} from "../@types/strategy-api";
import { Interval, ReportResponse } from "../@types/report";
import {
  TranslationRequest,
  TranslationResponse,
} from "../@types/translation-api";
import { isSuperAdminModeEnabled } from "../modes";
import { ChatResponse, FeelingContext } from "../@types/ai-api";
import {
  PromotionCode,
  PromotionCodePost,
  SubscriptionReport,
} from "../@types/user-service-api";
import {
  CampaignPost,
  CampaignPut,
  CampaignResponse,
  Cycle,
  CyclePut,
  ParticipationPut,
  ParticipationResponse,
} from "../@types/campaign-api";
import {
  ReportPost,
  ReportPut,
  ReportResponse as CustomReportResponse,
  CardResponse,
  ReportRenders,
} from "../@types/report-api";
import { CardPut } from "../@types/report-api-cards";
import { CardPost } from "../@types/report-api-cards";

const SOURCE = "app";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "del";

export const DEFAULT_GROUP_ID = "default";

const MAX_SAFE = 10000;

const sleep = (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const extractErrorFromApiResponse = (
  error: any,
): { statusCode: number; data: any } => {
  const { response } = error;
  return { statusCode: error, data: response.data };
};

export const omitEmptyHeaders = (
  headers: Record<string, unknown>,
): Record<string, string> => {
  return Object.keys(headers).reduce<Record<string, string>>(
    (acc, key) => {
      if (headers[key] !== undefined && headers[key] !== null) {
        return { ...acc, [key]: `${headers[key]}` };
      }
      return acc;
    },
    {} as Record<string, string>,
  );
};

class Api {
  language?: string;
  errorCallback: (
    description: string,
    detail: string,
    timestamp: string,
  ) => void;

  constructor() {
    this.language = undefined;
    this.errorCallback = () => {};
  }

  setLanguage(language: string) {
    this.language = language;
  }

  setErrorCallback(
    callback: (description: string, detail: string, timestamp: string) => void,
  ) {
    this.errorCallback = callback;
  }

  _callRequestWithPagination = async <T>(request: {
    path: string;
    max: number;
    offset: number;
    queryString: Record<string, string>;
  }): Promise<PaginableList<T>> => {
    const session = await fetchAuthSession();

    const response = await API.get({
      apiName: "seenapps",
      path: request.path,
      options: {
        queryParams: {
          ...request.queryString,
          _max: `${request.max}`,
          _offset: `${request.offset}`,
        },
        headers: omitEmptyHeaders({
          Authorization: session.tokens?.accessToken?.toString(),
          Source: SOURCE,
          "Accept-Language": this.language,
          "Enable-Super-Admin": isSuperAdminModeEnabled() ? "true" : undefined,
        }),
      },
    }).response;

    const result: PaginableList<T> = {
      items: (await response.body.json()) as T[],
      max: Number.parseInt(response.headers["x-pagination-max"]) || 0,
      offset: Number.parseInt(response.headers["x-pagination-offset"]) || 0,
      count: Number.parseInt(response.headers["x-pagination-count"]) || 0,
    };

    return result;
  };

  _callRequest = async <T>({
    method,
    path,
    defaultValue,
    body,
    queryParams,
  }: {
    method: "get" | "put" | "post" | "del" | "head" | "patch";
    path: string;
    defaultValue?: T;
    body?: any | any[];
    queryParams?: Record<string, string>;
  }): Promise<T> => {
    const session = await fetchAuthSession();

    const response = await API[method as "get"]({
      apiName: "seenapps",
      path,
      options: {
        queryParams,
        headers: omitEmptyHeaders({
          Authorization: session.tokens?.accessToken?.toString(),
          Source: SOURCE,
          "Accept-Language": this.language,
          "Enable-Super-Admin": isSuperAdminModeEnabled() ? "true" : undefined,
        }),
        body,
      },
    }).response;

    if (response?.body) {
      let textResponse: string = "";
      try {
        textResponse = await response.body.text();
        return JSON.parse(textResponse) as T;
      } catch {
        return textResponse as any;
      }
    }
    return defaultValue as any;
  };

  _callRequestWithoutToken = async <T>({
    method,
    path,
    defaultValue,
    body,
  }: {
    method: "get" | "put" | "post" | "del" | "head" | "patch";
    path: string;
    defaultValue?: T;
    body: any | any[];
  }): Promise<T> => {
    const response = await API[method as "post"]({
      apiName: "seenapps",
      path: `${path}`,
      options: {
        headers: omitEmptyHeaders({
          Source: SOURCE,
          "Accept-Language": this.language,
          "Enable-Super-Admin": isSuperAdminModeEnabled() ? "true" : undefined,
        }),
        body,
      },
    }).response;

    return response && response.body
      ? (response.body.json() as T)
      : (defaultValue as any);
  };

  getMySelf = async (): Promise<Myself> => {
    const myself = await this._callRequest<Myself>({
      method: GET,
      path: "/v1/me",
    });
    return myself;
  };

  getMyselfReport = async (): Promise<FeelingGeneralReportResponse> => {
    const report = await this._callRequest<FeelingGeneralReportResponse>({
      method: POST,
      path: `/feelings/myself-reports`,
      body: {
        template: "general",
      },
      queryParams: {
        _filters: `timestamp>=${moment()
          .subtract(7, "days")
          .startOf("day")
          .toISOString()};timestamp<=${moment().toISOString()};`,
      },
    });
    return report;
  };

  getFeeling = async (id: string): Promise<Feeling> => {
    const feelings = await this._callRequest<Feeling[]>({
      method: GET,
      path: `/feelings`,
      queryParams: {
        _filters: `id=${id}`,
      },
    });
    const [feeling] = feelings;
    return feeling;
  };
  getFeelings = async (
    from: Date,
    to: Date,
    filters?: {
      personalNotesSearch: string;
      colors?: string[];
      sphereIds?: string[];
      sessionIds?: string[];
      goalLevels?: number[];
      goalIds?: string[];
      goalRanking?: number;
    },
  ): Promise<Feeling[]> => {
    const filtersQueries: string[] = [];
    if (filters?.personalNotesSearch) {
      filtersQueries.push(`personalNotes=${filters.personalNotesSearch}`);
    }
    if (filters?.sphereIds?.length) {
      filtersQueries.push(`sphereId=${filters.sphereIds.join(",")}`);
    }
    if (filters?.sessionIds?.length) {
      filtersQueries.push(`sessionId=${filters.sessionIds.join(",")}`);
    }
    if (filters?.personalNotesSearch) {
      filtersQueries.push(`personalNotes=${filters.personalNotesSearch}`);
    }
    if (filters?.colors?.length) {
      filtersQueries.push(`color=${filters.colors.join(",")}`);
    }
    if (filters?.goalLevels?.length) {
      filtersQueries.push(`goalLevels=${filters.goalLevels.join(",")}`);
    }
    if (filters?.goalIds?.length) {
      filtersQueries.push(`goalIds=${filters.goalIds.join(",")}`);
    }
    if (filters?.goalRanking) {
      filtersQueries.push(`goalRankings<=${filters.goalRanking}`);
    }

    const feelings = await this._callRequest<Feeling[]>({
      method: GET,
      path: `/feelings`,
      queryParams: {
        _max: `${MAX_SAFE}`,
        _filters: `timestamp>=${from.toISOString()};timestamp<=${to.toISOString()};${filtersQueries.join(
          ";",
        )}`,
        _sortField: "timestamp",
        _sortDir: "desc",
      },
    });
    return feelings;
  };

  getFeelingFacets = async (from: Date, to: Date): Promise<Feeling[]> => {
    const facets = await this._callRequest<Feeling[]>({
      method: GET,
      path: `/feelings/facets`,
      queryParams: {
        _filters: `timestamp>=${from.toISOString()};timestamp<=${to.toISOString()};`,
      },
    });
    return facets;
  };

  getAxes = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<Axis>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: "/thinkings/definitions/axes",
      queryString,
    });
  };

  getAxis = async (id: string) => {
    return this._callRequest<Axis>({
      method: GET,
      path: `/thinkings/definitions/axes/${id}`,
    });
  };

  getAxesFacets = async () => {
    return this._callRequest<FacetResponse>({
      method: GET,
      path: `/thinkings/definitions/axes/facets`,
    });
  };

  addOrUpdateAxis = async ({
    id,
    ...axis
  }: Omit<Axis, "creationDate" | "updateDate">) => {
    const response = this._callRequest<Axis>({
      method: PUT,
      path: `/thinkings/definitions/axes/${id}`,
      body: axis,
    });
    return response;
  };

  deleteAxis = async (axis: Axis) => {
    const response = this._callRequest({
      method: DELETE,
      path: `/thinkings/definitions/axes/${axis.id}`,
    });
    return response;
  };

  getSphereAxes = async ({
    sphereId,
    memberCategory,
  }: {
    sphereId: string;
    memberCategory: string;
  }): Promise<SphereAxeResponse[]> => {
    return this._callRequest({
      method: GET,
      path: `/thinkings/definitions/sphere-axes/${sphereId}_${memberCategory}`,
    });
  };

  updateSphereAxes = async ({
    sphereId,
    memberCategory,
    axes,
  }: {
    sphereId: string;
    memberCategory: string;
    axes: SphereAxe[];
  }): Promise<SphereAxeResponse[]> => {
    return this._callRequest<SphereAxeResponse[]>({
      method: PUT,
      path: `/thinkings/definitions/sphere-axes/${sphereId}_${memberCategory}`,
      body: axes,
    });
  };

  getFeelingWords = async (): Promise<FeelingWord[]> => {
    const words = await this._callRequest<FeelingWord[]>({
      method: GET,
      path: `/feelings/definitions/words`,
    });
    return words;
  };

  getThinkings = async (
    from: Date,
    to: Date,
    filters?: {
      sphereIds?: string[];
      sessionIds?: string[];
    },
  ): Promise<Thinking[]> => {
    const filtersQueries: string[] = [];
    if (filters?.sphereIds?.length) {
      filtersQueries.push(`sphereId=${filters.sphereIds.join(",")}`);
    }
    if (filters?.sessionIds?.length) {
      filtersQueries.push(`sessionId=${filters.sessionIds.join(",")}`);
    }

    const thinkings = await this._callRequest<Thinking[]>({
      method: GET,
      path: `/thinkings`,
      queryParams: {
        _max: `${MAX_SAFE}`,
        _filters: `creationDate>=${from.toISOString()};creationDate<=${to.toISOString()};${filtersQueries.join(
          ";",
        )}`,
        _sortField: "creationDate",
        _sortDir: "desc",
      },
    });
    return thinkings;
  };

  getThinking = async (thinkingId: string): Promise<Thinking | undefined> => {
    const thinking = this._callRequest<Thinking>({
      method: GET,
      path: `/thinkings/${thinkingId}`,
    });
    return thinking;
  };

  getThinkingSmartReport = async ({
    sphereId,
    sessionId,
    category,
    linkTo,
    from,
    to,
  }: {
    sphereId?: string;
    sessionId?: string;
    from: string;
    to: string;
    linkTo: "session" | "sphere";
    category: feelingCategory | feelingCategory[];
  }): Promise<ThinkingGeneralReportResponse> => {
    const filters = [
      `timestamp<=${to}`,
      `timestamp>=${from}`,
      `linkTo=${linkTo}`,
    ];
    if (sphereId) {
      filters.push(`sphereId=${sphereId}`);
    }
    if (sessionId) {
      filters.push(`sessionId=${sessionId}`);
    }
    if (category) {
      filters.push(
        `category=${Array.isArray(category) ? category.join(",") : category}`,
      );
    }
    const feelingReportTask = this._callRequest<ThinkingGeneralReportResponse>({
      method: POST,
      path: `/thinkings/sphere-reports`,
      body: {
        template: "general",
      },
      queryParams: {
        _filters: filters.join(";"),
      },
    });
    return feelingReportTask;
  };

  getThinkingPublicReport = async ({
    sphereId,
    sessionId,
    category,
    from,
    to,
  }: {
    sphereId: string;
    sessionId: string;
    from: string;
    to: string;
    category: feelingCategory | feelingCategory[];
  }): Promise<ThinkingGeneralReportResponse> => {
    const filters = [
      `timestamp<=${to}`,
      `timestamp>=${from}`,
      `linkTo=session`,
    ];
    filters.push(`sphereId=${sphereId}`);
    filters.push(`sessionId=${sessionId}`);
    filters.push(
      `category=${Array.isArray(category) ? category.join(",") : category}`,
    );
    const feelingReportTask = this._callRequest<ThinkingGeneralReportResponse>({
      method: POST,
      path: `/thinkings/sphere-reports`,
      body: {
        template: "public",
      },
      queryParams: {
        _filters: filters.join(";"),
      },
    });
    return feelingReportTask;
  };

  saveUserProfile = async (profile: Partial<Profile>) => {
    const response = this._callRequest({
      method: PUT,
      path: `/v1/profile`,
      body: profile,
    });
    return response;
  };

  addEmail = async (email: string) => {
    const response = this._callRequest<Email>({
      method: PUT,
      path: `/v1/emails/${email}`,
    });
    return response;
  };

  sendEmailValidation = async (email: Email) => {
    const response = this._callRequest({
      method: POST,
      path: `/v1/emails/${email.email}/verification`,
    });
    return response;
  };

  verifyEmail = async ({
    userId,
    email,
    code,
  }: {
    userId: string;
    email: string;
    code: string;
  }) => {
    const response = this._callRequest({
      method: PUT,
      path: `/users/${userId}/emails/${email}/verification/${code}`,
    });
    return response;
  };

  deleteEmail = async (email: Email) => {
    const response = this._callRequest({
      method: DELETE,
      path: `/v1/emails/${email.email}`,
    });
    return response;
  };

  setPrimayEmail = async (userId: string, email: Email) => {
    const response = this._callRequest({
      method: PUT,
      path: `/users/${userId}/emails/${email.email}/primary`,
    });
    return response;
  };

  getSphere = async (id: string) => {
    return this._callRequest<Sphere>({ method: GET, path: `/spheres/${id}` });
  };

  getSphereMembers = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<MemberResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return this._callRequestWithPagination({
      max,
      offset,
      path: `/spheres/${sphereId}/members`,
      queryString,
    });
  };

  getSphereTags = async ({
    max,
    offset,
    searchQuery,
    filters,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<PaginableList<Tag>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/spheres/${sphereId}/tags`,
      queryString,
    });
  };

  getSphereTagsSelections = async ({
    max,
    offset,
    searchQuery,
    filters,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<PaginableList<SphereTagSelectionResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return this._callRequestWithPagination<SphereTagSelectionResponse>({
      max,
      offset,
      path: `/spheres/${sphereId}/tags-selections`,
      queryString,
    });
  };

  getSphereTagsSelection = async ({
    sphereId,
    selectionId,
  }: {
    sphereId: string;
    selectionId: string;
  }): Promise<SphereTagSelectionResponse> => {
    return this._callRequest<SphereTagSelectionResponse>({
      method: GET,
      path: `/spheres/${sphereId}/tags-selections/${selectionId}`,
    });
  };

  initializeTags = async ({
    sphereId,
  }: {
    sphereId: string;
  }): Promise<{
    tags: (Tag & { status: "created" | "ignored" })[];
    selections: (SphereTagSelectionPut & {
      status: "created" | "ignored";
    })[];
  }> => {
    return this._callRequest({
      method: POST,
      path: `/spheres/${sphereId}/tags/initialize`,
      defaultValue: { selections: [], tags: [] },
    });
  };

  addOrUpdateSphereTagsSelection = async ({
    sphereId,
    selectionId,
    selection,
  }: {
    sphereId: string;
    selectionId: string;
    selection: SphereTagSelectionPut;
  }): Promise<SphereTagSelectionResponse> => {
    return this._callRequest<SphereTagSelectionResponse>({
      method: PUT,
      path: `/spheres/${sphereId}/tags-selections/${selectionId}`,
      body: selection,
    });
  };

  getSphereTagFacets = async ({
    searchQuery,
    filters,
    sphereId,
  }: {
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<SphereTagFacetResponse> => {
    const queryParams: Record<string, string> = {};
    if (filters?.length) {
      queryParams._filters = filters.join(";");
    }
    if (searchQuery) {
      queryParams.q = searchQuery;
    }

    return this._callRequest<SphereTagFacetResponse>({
      method: GET,
      path: `/spheres/${sphereId}/tags/facets`,
      queryParams,
    });
  };

  getSphereTag = async (sphereId: string, tagId: string): Promise<Tag> => {
    return this._callRequest<Tag>({
      method: GET,
      path: `/spheres/${sphereId}/tags/${tagId}`,
    });
  };

  updateSphereTag = async (
    sphereId: string,
    tagId: string,
    tag: PostTag,
  ): Promise<Tag> => {
    return this._callRequest<Tag>({
      method: PUT,
      path: `/spheres/${sphereId}/tags/${tagId}`,
      body: tag,
    });
  };

  addSphereTag = async (sphereId: string, tag: PostTag): Promise<Tag> => {
    return this._callRequest<Tag>({
      method: POST,
      path: `/spheres/${sphereId}/tags`,
      body: tag,
    });
  };

  getSphereReportGroups = async ({
    max,
    offset,
    searchQuery,
    filters,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<PaginableList<SphereReportGroupFromDB>> => {
    const queryString: Record<string, string> = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return this._callRequestWithPagination<SphereReportGroupFromDB>({
      max,
      offset,
      path: `/spheres/${sphereId}/report-groups`,
      queryString,
    });
  };

  getSphereReportGroup = async (
    sphereId: string,
    groupId: string,
  ): Promise<Tag> => {
    return this._callRequest({
      method: GET,
      path: `/spheres/${sphereId}/report-groups/${groupId}`,
    });
  };

  updateSphereReportGroup = async (
    sphereId: string,
    groupId: string,
    reportGroup: ReportGroup,
  ): Promise<ReportGroup> => {
    return this._callRequest<ReportGroup>({
      method: PUT,
      path: `/spheres/${sphereId}/report-groups/${groupId}`,
      body: reportGroup,
    });
  };

  addSphereReportGroup = async (
    sphereId: string,
    reportGroup: ReportGroup,
  ): Promise<ReportGroup> => {
    return this._callRequest<ReportGroup>({
      method: POST,
      path: `/spheres/${sphereId}/report-groups`,
      body: reportGroup,
    });
  };

  deleteSphereReportGroup = async (
    sphereId: string,
    groupId: string,
  ): Promise<ReportGroup> => {
    return this._callRequest<ReportGroup>({
      method: DELETE,
      path: `/spheres/${sphereId}/report-groups/${groupId}`,
    });
  };

  getSphereMember = async (
    sphereId: string,
    memberId: string,
  ): Promise<MemberResponse> => {
    return this._callRequest({
      method: GET,
      path: `/spheres/${sphereId}/members/${memberId}`,
    });
  };

  addSphereMember = async (member: PostMember): Promise<MemberResponse[]> => {
    return this._callRequest<MemberResponse[]>({
      method: POST,
      path: `/members`,
      body: member,
    });
  };

  bulkSphereMembers = async (
    sphereId: string,
    bulkRequest: PostBulkMembers,
  ): Promise<MemberResponse[]> => {
    return this._callRequest<MemberResponse[]>({
      method: POST,
      path: `/spheres/${sphereId}/members/bulk`,
      body: bulkRequest,
    });
  };

  updateSphereMember = async (
    sphereId: string,
    memberId: string,
    member: Partial<PostMember>,
  ): Promise<MemberResponse> => {
    return this._callRequest<MemberResponse>({
      method: PUT,
      path: `/spheres/${sphereId}/members/${memberId}`,
      body: member,
    });
  };

  getSphereCategories = async (): Promise<Category[]> => {
    const categories = await this._callRequest<Category[]>({
      method: GET,
      path: `/spheres/definitions/categories`,
      queryParams: {
        _max: "200",
      },
    });
    return categories;
  };

  getSphereThinkingAxes = async (
    memberCategory?: string,
    sphereId?: string,
  ): Promise<SphereAxeResponse[]> => {
    return this._callRequest<SphereAxeResponse[]>({
      method: GET,
      path: `/thinkings/definitions/sphere-axes/${sphereId}_${memberCategory}`,
      defaultValue: [],
    });
  };

  addFeeling = async ({
    campaignId,
    sphereId,
    sessionId,
    value,
    energy,
    deviceCode,
    deviceName,
    category,
    geoPoint,
    validationCode,
    disableThinkingCreation,
    stepNumber,
    evaluationPeriod,
    fromDirectLink,
    specificTagIds,
  }: {
    campaignId?: string;
    sphereId?: string;
    sessionId?: string;
    value: number;
    energy: number;
    deviceCode: string;
    deviceName?: string;
    category?: feelingCategory;
    geoPoint: GeoPoint;
    validationCode?: string;
    disableThinkingCreation?: boolean;
    stepNumber?: string | number;
    evaluationPeriod?: FeelingEvaluationPeriod;
    fromDirectLink?: boolean;
    specificTagIds?: string | string[];
  }) => {
    const locale = moment.tz.guess();
    const number =
      !stepNumber || Number.isInteger(stepNumber)
        ? stepNumber
        : Number.parseInt(`${stepNumber}`, 10);

    return this._callRequest<Feeling>({
      method: POST,
      path: `/feelings`,
      body: {
        campaignId,
        sphereId,
        sessionId,
        value,
        energy,
        deviceCode,
        deviceName,
        geoPoint,
        category,
        validationCode,
        disableThinkingCreation,
        locale,
        stepNumber: number,
        evaluationPeriod,
        fromDirectLink,
        specificTagIds,
      },
    });
  };
  updateFeeling = async (
    feelingId: string,
    {
      sphereId,
      wordId,
      category,
      personalNotes,
      goals,
    }: {
      sphereId?: string;
      category?: string;
      wordId?: string;
      personalNotes?: Note[];
      goals?: FeelingGoal[];
    },
  ) => {
    return this._callRequest<Feeling>({
      method: PUT,
      path: `/feelings/${feelingId}`,
      body: {
        sphereId,
        category,
        wordId,
        personalNotes,
        goals,
      },
    });
  };

  updateThinking = async (
    thinkingId: string,
    axes: {
      id: string;
      value?: number;
    }[],
    geoPoint: GeoPoint,
  ) => {
    return this._callRequest<Thinking>({
      method: PUT,
      path: `/thinkings/${thinkingId}`,
      body: {
        axes,
        geoPoint,
      },
    });
  };

  _getThinking = async (
    sphereId: string,
    feeling: string,
    maxTry = 15,
  ): Promise<Thinking | undefined> => {
    const thinkingId = `${sphereId}_${feeling}_0`;

    const thinking = await this._callRequest<Thinking>({
      method: GET,
      path: `/thinkings/${thinkingId}`,
    });
    const tries = maxTry - 1;
    if (!thinking && tries > 0) {
      await sleep();
      return await this._getThinking(sphereId, feeling, tries);
    } else if (!thinking) {
      this.errorCallback(
        `Unable to find the thinking after 15 tries : id = ${thinkingId}`,
        "",
        "",
      );
      return undefined;
    }
    return thinking;
  };

  /**
   * AxesValue format = {id: string, "value": number (beetween -1 and 1)}
   */
  addThinking = async (thinking: ThinkingCreateRequest) => {
    const response = this._callRequest<Thinking>({
      method: POST,
      path: `/thinkings`,
      body: thinking,
    });
    return response;
  };

  getSmartReport = async ({
    sphereId,
    sessionId,
    category,
    from,
    to,
    feelingType,
    include,
    interval,
  }: {
    sphereId?: string;
    sessionId?: string;
    from: string;
    to: string;
    category: feelingCategory | feelingCategory[];
    feelingType: "feeling" | FeelingEvaluationPeriod;
    include?: (
      | "words"
      | "mood"
      | "colorByTime"
      | "globalColors"
      | "participants"
    )[];
    interval?: Interval;
  }): Promise<FeelingGeneralReportResponse> => {
    const filters = [`timestamp<=${to}`, `timestamp>=${from}`];
    if (sphereId) {
      filters.push(`sphereId=${sphereId}`);
    }
    if (sessionId) {
      filters.push(`sessionId=${sessionId}`);
    }
    if (category) {
      filters.push(
        `category=${Array.isArray(category) ? category.join(",") : category}`,
      );
    }
    if (feelingType === "feeling") {
      filters.push(`isAnEvaluation=false`);
    } else {
      filters.push(`isAnEvaluation=true`);
      filters.push(`evaluationPeriodType=${feelingType.type}`);
    }

    const feelingReportTask = this._callRequest<FeelingGeneralReportResponse>({
      method: POST,
      path: `/feelings/sphere-reports`,
      body: {
        template: "general",
        generalOptions: {
          include,
          interval,
        },
      },
      queryParams: {
        _filters: filters.join(";"),
      },
    });
    return feelingReportTask;
  };

  getPublicReport = async ({
    sphereId,
    sessionId,
    category,
    from,
    to,
  }: {
    sphereId: string;
    sessionId: string;
    from: string;
    to: string;
    category: feelingCategory | feelingCategory[];
  }): Promise<FeelingGeneralReportResponse> => {
    const filters = [`timestamp<=${to}`, `timestamp>=${from}`];
    filters.push(`sphereId=${sphereId}`);
    filters.push(`sessionId=${sessionId}`);
    filters.push(
      `category=${Array.isArray(category) ? category.join(",") : category}`,
    );

    const feelingReportTask = this._callRequest<FeelingGeneralReportResponse>({
      method: POST,
      path: `/feelings/sphere-reports`,
      body: {
        template: "public",
      },
      queryParams: {
        _filters: filters.join(";"),
      },
    });
    return feelingReportTask;
  };
  joinSphere = async (
    sphereId: string,
    accessCode: string,
    name: string,
    email: string,
  ) => {
    const member = await this._callRequest<MemberResponse>({
      method: POST,
      path: `/spheres/${sphereId}/join`,
      body: {
        sphereKey: accessCode,
        name,
        email,
      },
    });
    return member;
  };
  leaveSphere = async (sphereId: string, memberId: string) => {
    await this._callRequest({
      method: DELETE,
      path: `/spheres/${sphereId}/members/${memberId}`,
    });
    return true;
  };
  saveSphere = async (id: string, sphere: Partial<Sphere>) => {
    const sphereUpdated = await this._callRequest<Sphere>({
      method: PUT,
      path: `/spheres/${id}`,
      body: sphere,
    });
    return sphereUpdated;
  };

  createSphere = async (sphere: Partial<Sphere>) => {
    const response = await this._callRequest<Sphere>({
      method: POST,
      path: `/spheres`,
      body: sphere,
    });

    // Wait sphere indexation
    let tries = 10;
    while (tries > 0) {
      try {
        const sphere = await this._callRequest<Sphere>({
          method: GET,
          path: `/spheres/${response.id}`,
          queryParams: {
            unicity: uuidv4(),
          },
        });
        if (sphere) {
          return sphere;
        }
        tries--;
      } catch {
        // ignored on purpose
      }
      await sleep(1000);
    }
    return response;
  };

  getSpheres = async ({
    max,
    offset,
    searchQuery,
    filters,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
  }): Promise<PaginableList<Sphere>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: "/spheres",
      queryString,
    });
  };

  createGuestAccess = async ({
    name,
    link,
  }: {
    name?: string;
    link: string;
  }): Promise<{ userName: string; password: string }> => {
    const guestAccess = await this._callRequestWithoutToken<{
      userName: string;
      password: string;
    }>({
      method: POST,
      path: `/users/guests`,
      body: {
        name,
        link,
      },
    });
    return guestAccess;
  };

  registerGuest = async (body: { email: string }) => {
    const response = await this._callRequest({
      method: POST,
      path: `/users/guests/register`,
      body,
    });
    return response;
  };

  finalizeGuestRegistration = async (body: {
    firstName: string;
    lastName?: string;
    validationCode: string;
    acceptPrivatePolicy: boolean;
  }) => {
    const response = await this._callRequest({
      method: POST,
      path: `/users/guests/convert`,
      body,
    });
    return response;
  };

  getSessions = async ({
    max,
    offset,
    searchQuery,
    filters,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
  }): Promise<PaginableList<Session>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }
    queryString["_sort-field"] = "name";

    return await this._callRequestWithPagination({
      max,
      offset,
      path: "/sessions",
      queryString,
    });
  };

  fetchSessionReport = async ({
    sphereId,
    category,
    body,
  }: {
    sphereId: string;
    category: string;
    body: any;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [
      `sphereId=${sphereId}`,
      `category=${category}`,
    ];
    const reponse = await this._callRequest<ReportResponse>({
      method: POST,
      path: `/sessions/reports`,
      body,
      queryParams: {
        _filters: filtersQueries.join(";"),
      },
    });
    return reponse;
  };

  getSessionChildrenIds = async ({
    sessionId,
  }: {
    sessionId: string;
  }): Promise<string[]> => {
    const result = await this._callRequestWithPagination<{ id: string }>({
      max: 1000,
      offset: 0,
      path: `/sessions`,
      queryString: {
        _filters: `parentSessionIds=${sessionId}`,
        _fields: "id",
      },
    });
    return result.items.map((session) => session.id);
  };

  getSession = async (id: string): Promise<Session> => {
    return this._callRequest({ method: GET, path: `/sessions/${id}` });
  };

  saveSession = async (
    id: string,
    session: Partial<SessionPut>,
  ): Promise<Session> => {
    const response = await this._callRequest<Session>({
      method: PUT,
      path: `/sessions/${id}`,
      body: session,
    });

    return response;
  };

  createSession = async (session: SessionPost) => {
    const response = await this._callRequest<Session>({
      method: POST,
      path: `/sessions`,
      body: session,
    });

    // Wait sphere indexation
    let tries = 10;
    while (tries > 0) {
      try {
        const session = await this._callRequest<Session>({
          method: GET,
          path: `/sessions/${response.id}`,
          queryParams: {
            unicity: uuidv4(),
          },
        });
        if (session) {
          return session;
        }
        tries--;
      } catch {
        // ignored on purpose
      }
      await sleep(1000);
    }
    return response;
  };

  joinSession = async ({
    sessionId,
    accessCode,
  }: {
    sessionId: string;
    accessCode?: string;
  }): Promise<{
    member: MemberResponse;
    session?: Session;
  }> => {
    const member = await this._callRequest<MemberResponse>({
      method: POST,
      path: `/sessions/${sessionId}/join`,
      body: {
        sessionKey: accessCode,
      },
    });

    // Wait sphere indexation
    let tries = 10;
    let session;
    while (tries > 0) {
      try {
        session = await this._callRequest<Session>({
          method: GET,
          path: `/sessions/${sessionId}`,
          queryParams: {
            unicity: uuidv4(),
          },
        });
        if (session) {
          return { member, session };
        }
        tries--;
      } catch {
        // ignored on purpose
      }
      await sleep(1000);
    }
    return { member };
  };

  getSessionMembers = async ({
    max,
    offset,
    searchQuery,
    filters,
    sessionId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sessionId: string;
    filters?: string[];
  }): Promise<PaginableList<MemberResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return this._callRequestWithPagination<MemberResponse>({
      max,
      offset,
      path: `/sessions/${sessionId}/members`,
      queryString,
    });
  };

  updateSessionMember = async (
    sessionId: string,
    memberId: string,
    member: SessionMemberPut,
  ): Promise<MemberResponse> => {
    return this._callRequest<MemberResponse>({
      method: PUT,
      path: `/sessions/${sessionId}/members/${memberId}`,
      body: member,
    });
  };
  addSessionMember = async (
    sessionId: string,
    member: SessionMemberPost,
  ): Promise<MemberResponse> => {
    return this._callRequest<MemberResponse>({
      method: POST,
      path: `/sessions/${sessionId}/members`,
      body: member,
    });
  };
  deleteSessionMember = async (
    sessionId: string,
    memberId: string,
  ): Promise<MemberResponse> => {
    return this._callRequest<MemberResponse>({
      method: DELETE,
      path: `/sessions/${sessionId}/members/${memberId}`,
    });
  };
  deleteSession = async (sessionId: string): Promise<void> => {
    await this._callRequest({
      method: DELETE,
      path: `/sessions/${sessionId}`,
    });
  };

  getSessionAxesGroups = async ({
    sessionId,
    groupId,
  }: {
    sessionId: string;
    groupId: string;
  }): Promise<SphereAxeResponse[]> => {
    return this._callRequest({
      method: GET,
      path: `/thinkings/definitions/session-axes/${sessionId}_${groupId}`,
    });
  };

  updateSessionAxes = async ({
    sessionId,
    groupId,
    axes,
  }: {
    sessionId: string;
    groupId: string;
    axes: SphereAxe[];
  }): Promise<SphereAxeResponse[]> => {
    return this._callRequest<SphereAxeResponse[]>({
      method: PUT,
      path: `/thinkings/definitions/session-axes/${sessionId}_${groupId}`,
      body: axes,
    });
  };

  leaveSession = async (sessionId: string, memberId: string) => {
    return this._callRequest<MemberResponse>({
      method: DELETE,
      path: `/sessions/${sessionId}/members/${memberId}`,
    });
  };

  getSessionThinkingAxes = async (
    sessionId: string,
    groupId: string,
  ): Promise<SphereAxeResponse[]> => {
    return this._callRequest({
      method: GET,
      path: `/thinkings/definitions/session-axes/${sessionId}_${groupId}`,
      defaultValue: [],
    });
  };

  getFormEntitlements = async ({
    max,
    offset,
    searchQuery,
    filters,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<PaginableList<FormEntitlementResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/forms/spheres/${sphereId}/entitlements`,
      queryString,
    });
  };

  createFormEntitlement = async ({
    formId,
    sphereId,
    ...entitlementBody
  }: {
    formId: string;
    sphereId: string;
  } & Partial<FormEntitlementBody>): Promise<FormEntitlementResponse> => {
    return this._callRequest<FormEntitlementResponse>({
      method: POST,
      path: `/forms/spheres/${sphereId}/entitlements`,
      body: {
        formId,
        ...entitlementBody,
      },
    });
  };
  updateFormEntitlement = async ({
    formId,
    sphereId,
    ...entitlementBody
  }: {
    formId: string;
    sphereId: string;
  } & Partial<FormEntitlementBody>): Promise<FormEntitlementResponse> => {
    return this._callRequest({
      method: PUT,
      path: `/forms/spheres/${sphereId}/entitlements/${formId}`,
      body: entitlementBody,
    });
  };
  deleteFormEntitlement = async ({
    formId,
    sphereId,
  }: {
    formId: string;
    sphereId: string;
  }): Promise<FormEntitlementResponse> => {
    return this._callRequest({
      method: DELETE,
      path: `/forms/spheres/${sphereId}/entitlements/${formId}`,
    });
  };

  getForms = async ({
    max,
    offset,
    searchQuery,
    filters,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
  }): Promise<PaginableList<LightForm>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return this._callRequestWithPagination({
      max,
      offset,
      path: `/forms/definitions`,
      queryString,
    });
  };
  addSessionToFormEntitlement = async ({
    formId,
    sphereId,
    sessionId,
  }: {
    formId: string;
    sphereId: string;
    sessionId: string;
  }): Promise<FormEntitlementResponse> => {
    return this._callRequest({
      method: POST,
      path: `/forms/spheres/${sphereId}/entitlements/${formId}/sessions`,
      body: {
        sessionId,
      },
    });
  };
  deleteSessionFromFormEntitlement = async ({
    formId,
    sphereId,
    sessionId,
  }: {
    formId: string;
    sphereId: string;
    sessionId: string;
  }): Promise<FormEntitlementResponse> => {
    return this._callRequest({
      method: DELETE,
      path: `/forms/spheres/${sphereId}/entitlements/${formId}/sessions/${sessionId}`,
    });
  };

  getFormReport = async (body: {
    includeFormIds?: string[];
    onlyFormIds?: string[];
  }): Promise<FormReportResponse> => {
    return this._callRequest<FormReportResponse>({
      method: POST,
      path: `/forms/reports`,
      body,
    });
  };
  getForm = async (formId: string): Promise<Form> => {
    return this._callRequest<Form>({
      method: GET,
      path: `/forms/definitions/${formId}`,
    });
  };
  getFormReportDefinition = async (
    formId: string,
  ): Promise<FormReportDefinition> => {
    return this._callRequest<FormReportDefinition>({
      method: GET,
      path: `/forms/report-definitions/${formId}`,
    });
  };

  getFormSurveys = async (
    from: Date,
    to: Date,
    filters?: {
      max?: number;
      formId?: string;
      sphereIds?: string[];
      sessionIds?: string[];
    },
  ): Promise<FormSurvey[]> => {
    const filtersQueries: string[] = [];
    if (filters?.sphereIds?.length) {
      filtersQueries.push(`sphereId=${filters.sphereIds.join(",")}`);
    }
    if (filters?.sessionIds?.length) {
      filtersQueries.push(`sessionId=${filters.sessionIds.join(",")}`);
    }
    if (filters?.formId) {
      filtersQueries.push(`formId=${filters.formId}`);
    }

    const surveys = await this._callRequest<FormSurvey[]>({
      method: GET,
      path: `/forms`,
      queryParams: {
        _max: `${filters?.max ?? MAX_SAFE}`,
        _filters: `creationDate>=${from.toISOString()};creationDate<=${to.toISOString()};${filtersQueries.join(
          ";",
        )}`,
        "_sort-field": "creationDate",
        "_sort-dir": "desc",
      },
    });
    return surveys;
  };

  getMyForms = async (): Promise<Partial<FormSurvey>[]> => {
    const surveys = await this._callRequest<FormSurvey[]>({
      method: GET,
      path: `/forms`,
      queryParams: {
        _groupBy: "lastByFormId",
      },
    });
    return surveys;
  };

  getFormSurvey = async (id: string): Promise<FormSurvey> => {
    const survey = await this._callRequest<FormSurvey>({
      method: GET,
      path: `/forms/${id}`,
    });
    return survey;
  };

  createFormSurvey = async (form: FormSurveyPost): Promise<FormSurvey> => {
    const survey = await this._callRequest<FormSurvey>({
      method: POST,
      path: `/forms`,
      body: form,
    });
    return survey;
  };
  updateFormSurvey = async (
    id: string,
    form: FormSurveyPut,
  ): Promise<FormSurveyPost> => {
    const survey = await this._callRequest<FormSurveyPost>({
      method: PUT,
      path: `/forms/${id}`,
      body: form,
    });
    return survey;
  };

  getUrlAliases = async ({
    max,
    offset,
    searchQuery,
    sessionId,
    sphereId,
    category,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    category?: string;
    sphereId?: string;
    sessionId?: string;
  }): Promise<PaginableList<UrlAlias>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    const filters = [`category=${category}`];
    if (sphereId) {
      filters.push(`sphereId=${sphereId}`);
      filters.push(`linkTo=sphere`);
    }
    if (sessionId) {
      filters.push(`sessionId=${sessionId}`);
      filters.push(`linkTo=session`);
    }
    queryString._filters = filters.join(";");
    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/shorten-urls`,
      queryString,
    });
  };

  getUrlAlias = async (id: string): Promise<UrlAlias | undefined> => {
    const response = await this._callRequestWithPagination({
      max: 1,
      offset: 0,
      path: `/shorten-urls`,
      queryString: { _filters: `id=${id}` },
    });

    if (response && response.items && response.items.length > 0) {
      return response.items[0] as UrlAlias;
    }
    return undefined;
  };

  getUrlCampaign = async (
    id: string,
    validationCode: string,
  ): Promise<UrlCampaignResponse> => {
    const session = await fetchAuthSession();
    try {
      const response = await API.get({
        apiName: "seenapps",
        path: `/shorten-urls/${id}/campaign`,
        options: {
          queryParams: {
            validationCode: encodeURIComponent(validationCode),
          },
          headers: omitEmptyHeaders({
            Authorization: session.tokens?.accessToken?.toString(),
            Source: SOURCE,
            "Accept-Language": this.language,
            "Enable-Super-Admin": isSuperAdminModeEnabled()
              ? "true"
              : undefined,
          }),
        },
      }).response;

      return (await response.body.json()) as UrlCampaignResponse;
    } catch {
      throw new Error("Campaign Forbidden");
    }
  };

  createUrlAlias = async (body: UrlCreationInput): Promise<UrlAlias> => {
    return this._callRequest<UrlAlias>({
      method: POST,
      path: `/shorten-urls`,
      body,
    });
  };

  updateUrlAlias = async ({
    id,
    ...body
  }: { id: string } & Partial<UrlUpdateInput>): Promise<UrlAlias> => {
    return await this._callRequest<UrlAlias>({
      method: PUT,
      path: `/shorten-urls/${id}`,
      body,
    });
  };

  deleteUrlAlias = async (id: string): Promise<void> => {
    await this._callRequest({
      method: DELETE,
      path: `/shorten-urls/${id}`,
    });
  };

  getStrategies = async ({
    sphereId,
    sessionId,
    searchQuery,
    max,
    offset,
    linkTo,
  }: {
    max?: number;
    offset?: number;
    userId?: string;
    sphereId?: string;
    sessionId?: string;
    linkTo: "session" | "sphere" | "user";
    searchQuery?: string;
  }): Promise<PaginableList<Strategy>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    const filters = [`linkTo=${linkTo}`];
    if (sessionId) {
      filters.push(`sessionId=${sessionId}`);
    } else if (sphereId) {
      filters.push(`sphereId=${sphereId}`);
    }
    queryString._filters = filters.join(";");
    return this._callRequestWithPagination<Strategy>({
      max: max ?? 200,
      offset: offset ?? 0,
      path: `/strategies`,
      queryString,
    });
  };

  deleteStrategy = async (id: string): Promise<void> => {
    await this._callRequest({ method: DELETE, path: `/strategies/${id}` });
  };

  addStrategy = async (strategyBody: StrategyPost): Promise<Strategy> => {
    return this._callRequest<Strategy>({
      method: POST,
      path: `/strategies`,
      body: strategyBody,
    });
  };

  updateStrategy = async (
    id: string,
    strategyBody: Partial<StrategyPut>,
  ): Promise<Strategy> => {
    return await this._callRequest<Strategy>({
      method: PUT,
      path: `/strategies/${id}`,
      body: strategyBody,
    });
  };

  fetchReport = async ({
    sessionId,
    sphereId,
    category,
    body,
    period,
    tagIds,
    feelingType,
    onlyDirect,
    sessionLevel,
  }: {
    sessionId?: string;
    sphereId?: string;
    category?: feelingCategory | feelingCategory[];
    body: any;
    period: FilterDate;
    tagIds: string[];
    feelingType: FeelingType;
    onlyDirect?: boolean;
    sessionLevel?: number;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [];

    const timePeriod = feelingType === "feeling" ? "day" : feelingType.type;
    if (period.startDate) {
      filtersQueries.push(
        `timestamp>=${moment(period.startDate)
          .startOf(timePeriod)
          .toISOString()}`,
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        `timestamp<=${moment(period.endDate).endOf(timePeriod).toISOString()}`,
      );
    }

    if (tagIds?.length) {
      filtersQueries.push(`tagIds=${tagIds.join(",")}`);
    }
    if (feelingType === "feeling") {
      filtersQueries.push(`isAnEvaluation=false`);
    } else {
      filtersQueries.push(`isAnEvaluation=true`);
      filtersQueries.push(`lastEvaluationOfThePeriod=true`);
      filtersQueries.push(`evaluationPeriodType=${feelingType.type}`);
    }
    if (sessionLevel) {
      filtersQueries.push(`session-level=${sessionLevel}`);
    }

    const directLinkTo: Module = sessionId
      ? "session"
      : sphereId
        ? "sphere"
        : "myself";
    if (sessionId || onlyDirect) {
      filtersQueries.push(`directLinkTo=${directLinkTo}`);
    }
    if (sessionId && onlyDirect) {
      filtersQueries.push(`sessionId=${sessionId}`);
    } else if (sessionId) {
      const childrenIds = await api.getSessionChildrenIds({ sessionId });
      filtersQueries.push(`sessionId=${[sessionId, ...childrenIds].join(",")}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    if (category) {
      filtersQueries.push(
        `category=${Array.isArray(category) ? category.join(",") : category}`,
      );
    }

    const reponse =
      sphereId || sessionId
        ? await this._callRequest<ReportResponse>({
            method: POST,
            path: `/feelings/sphere-reports`,
            body,
            queryParams: {
              _filters: filtersQueries.join(";"),
            },
          })
        : await this._callRequest<ReportResponse>({
            method: POST,
            path: `/feelings/myself-reports`,
            body,
            queryParams: {
              _filters: filtersQueries.join(";"),
            },
          });
    return reponse;
  };

  fetchThinkingReport = async ({
    sessionId,
    sphereId,
    category,
    body,
    period,
    tagIds,
    onlyChildren,
    sessionLevel,
  }: {
    sessionId?: string;
    sphereId: string;
    category: feelingCategory;
    body: any;
    period: FilterDate;
    onlyChildren?: boolean;
    tagIds?: string[];
    sessionLevel?: number;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [];
    if (period.startDate) {
      filtersQueries.push(
        `timestamp>=${moment(period.startDate).startOf("day").toISOString()}`,
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        `timestamp<=${moment(period.endDate).endOf("day").toISOString()}`,
      );
    }

    const directLinkTo: Module =
      onlyChildren || sessionId ? "session" : "sphere";
    filtersQueries.push(`linkTo=${directLinkTo}`);
    if (onlyChildren) {
      filtersQueries.push(`onlyChildren=true`);
    }
    if (tagIds?.length) {
      filtersQueries.push(`tagIds=${tagIds.join(",")}`);
    }
    if (sessionId) {
      filtersQueries.push(`sessionId=${sessionId}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    if (category) {
      filtersQueries.push(`category=${category}`);
    }
    if (sessionLevel) {
      filtersQueries.push(`session-level=${sessionLevel}`);
    }

    const report = await this._callRequest<ReportResponse>({
      method: POST,
      path: `/thinkings/sphere-reports`,
      body,
      queryParams: {
        _filters: filtersQueries.join(";"),
      },
    });

    return report;
  };

  fetchFormReport = async ({
    formId,
    sessionId,
    sphereId,
    body,
    period,
    tagIds,
    onlyDirect,
    onlyLastSurvey,
    sessionLevel,
  }: {
    formId: string;
    sessionId?: string;
    sphereId?: string;
    category?: feelingCategory;
    body: any;
    period: FilterDate;
    tagIds: string[];
    onlyDirect?: boolean;
    onlyLastSurvey: boolean;
    sessionLevel: number;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [`formId=${formId}`];
    const timePeriod = "day";
    if (period.startDate) {
      filtersQueries.push(
        `creationDate>=${moment(period.startDate)
          .startOf(timePeriod)
          .toISOString()}`,
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        `creationDate<=${moment(period.endDate)
          .endOf(timePeriod)
          .toISOString()}`,
      );
    }

    if (tagIds?.length) {
      filtersQueries.push(`tagIds=${tagIds.join(",")}`);
    }
    if (onlyLastSurvey) {
      filtersQueries.push(`only-last-survey=true`);
    }
    if (sessionLevel) {
      filtersQueries.push(`session-level=${sessionLevel}`);
    }

    const directLinkTo: Module = sessionId
      ? "session"
      : sphereId
        ? "sphere"
        : "myself";
    if (sessionId || onlyDirect) {
      filtersQueries.push(`directLinkTo=${directLinkTo}`);
    }
    if (sessionId && onlyDirect) {
      filtersQueries.push(`sessionId=${sessionId}`);
    } else if (sessionId) {
      const childrenIds = await api.getSessionChildrenIds({ sessionId });
      filtersQueries.push(`sessionId=${[sessionId, ...childrenIds].join(",")}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    const reponse =
      sphereId || sessionId
        ? await this._callRequest<ReportResponse>({
            method: POST,
            path: `/forms/sphere-reports`,
            body,
            queryParams: {
              _filters: filtersQueries.join(";"),
            },
          })
        : await this._callRequest<ReportResponse>({
            method: POST,
            path: `/forms/myself-reports`,
            body,
            queryParams: {
              _filters: filtersQueries.join(";"),
            },
          });
    return reponse;
  };
  translate = async (
    request: TranslationRequest,
  ): Promise<TranslationResponse> => {
    const reponse = await this._callRequest<TranslationResponse>({
      method: POST,
      path: `/translations`,
      body: request,
    });
    return reponse;
  };

  createChatThread = async (
    mode: string,
    text: string,
    userName?: string,
    feelingContext?: FeelingContext,
  ): Promise<ChatResponse> => {
    return this._callRequest({
      method: POST,
      path: `/ai/chat-threads`,
      body: {
        mode,
        text,
        userName,
        feelingContext,
      },
    });
  };
  sendMessageToChatThread = async (
    id: string,
    text: string,
  ): Promise<ChatResponse> => {
    return this._callRequest({
      method: POST,
      path: `/ai/chat-threads/${id}`,
      body: {
        text,
      },
    });
  };

  getGlobalMembers = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<MemberResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }
    queryString._filters = `${queryString._filters}${
      queryString._filters ? ";" : ""
    }sphereId=*`;
    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/members`,
      queryString,
    });
  };

  getGlobalMember = async (memberId: string): Promise<MemberResponse> => {
    return this._callRequest<MemberResponse>({
      method: GET,
      path: `/members/${memberId}`,
    });
  };

  addGlobalMember = async (member: PostMember): Promise<MemberResponse[]> => {
    return this._callRequest<MemberResponse[]>({
      method: POST,
      path: `/members`,
      body: {
        ...member,
        sphereId: "*",
      },
    });
  };

  updateGlobalMember = async (
    memberId: string,
    member: PostMember,
  ): Promise<MemberResponse> => {
    return this._callRequest<MemberResponse>({
      method: PUT,
      path: `/members/${memberId}`,
      body: member,
    });
  };

  getSubscriptionReport = async ({
    callbackUrl,
  }: {
    callbackUrl: string;
  }): Promise<SubscriptionReport> => {
    return this._callRequest<SubscriptionReport>({
      method: POST,
      path: `/users/subscriptions/report`,
      body: {
        callbackUrl,
      },
    });
  };

  getPromotionCodes = async ({
    filters,
  }: {
    filters?: string[];
  }): Promise<PromotionCode[]> => {
    return this._callRequest<PromotionCode[]>({
      method: GET,
      path: `/users/subscriptions/promotion-codes`,
      queryParams: {
        _filters: filters?.join(";") || "",
      },
    });
  };

  addPromotionCode = async (
    promotionCode: PromotionCodePost,
  ): Promise<PromotionCode> => {
    return this._callRequest<PromotionCode>({
      method: POST,
      path: `/users/subscriptions/promotion-codes`,
      body: promotionCode,
    });
  };

  getSphereSubscriptionReport = async ({
    sphereId,
    callbackUrl,
    requestedBy,
  }: {
    sphereId: string;
    callbackUrl: string;
    requestedBy: { firstName: string; lastName: string; email: string };
  }): Promise<SphereSubscriptionReport> => {
    return this._callRequest<SphereSubscriptionReport>({
      method: POST,
      path: `/spheres/${sphereId}/subscriptions/report`,
      body: {
        callbackUrl,
        requestedBy,
      },
    });
  };
  updateSphereSubscriptionLicense = async ({
    sphereId,
    subscription,
    quantity,
  }: {
    sphereId: string;
    subscription: Subscription;
    quantity: number;
  }) => {
    return this._callRequest<{
      quantity: number;
      sphereId: string;
      subscription: Subscription;
    }>({
      method: PUT,
      path: `/spheres/${sphereId}/subscriptions/${subscription}/license`,
      body: {
        quantity,
      },
    });
  };

  getStrategyAssessments = async (
    from: Date,
    to: Date,
    filters?: {
      max?: number;
      formId?: string;
      sphereIds?: string[];
      sessionIds?: string[];
    },
  ): Promise<StrategyAssessment[]> => {
    const filtersQueries: string[] = [];
    if (filters?.sphereIds?.length) {
      filtersQueries.push(`sphereId=${filters.sphereIds.join(",")}`);
    }
    if (filters?.sessionIds?.length) {
      filtersQueries.push(`sessionId=${filters.sessionIds.join(",")}`);
    }

    const assessments = await this._callRequest<StrategyAssessment[]>({
      method: GET,
      path: `/strategies/assessments`,
      queryParams: {
        _max: `${filters?.max ?? MAX_SAFE}`,
        _filters: `creationDate>=${from.toISOString()};creationDate<=${to.toISOString()};${filtersQueries.join(
          ";",
        )}`,
        "_sort-field": "creationDate",
        "_sort-dir": "desc",
      },
    });
    return assessments;
  };
  getStrategyAssessment = async (
    assessmentId: string,
  ): Promise<StrategyAssessment> => {
    const assessment = await this._callRequest<StrategyAssessment>({
      method: GET,
      path: `/strategies/assessments/${assessmentId}`,
    });
    return assessment;
  };

  addStrategyAssessment = async (assessment: StrategyAssessmentPost) => {
    const response = await this._callRequest<StrategyAssessment>({
      method: POST,
      path: `/strategies/assessments`,
      body: assessment,
    });
    return response;
  };
  updateStrategyAssessment = async (
    assessmentId: string,
    assessment: StrategyAssessmentPut,
  ) => {
    const response = await this._callRequest<StrategyAssessment>({
      method: PUT,
      path: `/strategies/assessments/${assessmentId}`,
      body: assessment,
    });
    return response;
  };

  fetchAssessmentReport = async ({
    sessionId,
    sphereId,
    body,
    period,
    tagIds,
    onlyChildren,
    sessionLevel,
  }: {
    sessionId?: string;
    sphereId: string;
    body: any;
    period: FilterDate;
    onlyChildren?: boolean;
    tagIds?: string[];
    sessionLevel?: number;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [];
    if (period.startDate) {
      filtersQueries.push(
        `updateDate>=${moment(period.startDate).startOf("day").toISOString()}`,
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        `updateDate<=${moment(period.endDate).endOf("day").toISOString()}`,
      );
    }

    const directLinkTo: Module =
      onlyChildren || sessionId ? "session" : "sphere";
    filtersQueries.push(`onlyChildren=${onlyChildren || false}`);
    filtersQueries.push(`directLinkTo=${directLinkTo}`);
    if (tagIds?.length) {
      filtersQueries.push(`tagIds=${tagIds.join(",")}`);
    }
    if (sessionId) {
      filtersQueries.push(`sessionId=${sessionId}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    if (sessionLevel) {
      filtersQueries.push(`session-level=${sessionLevel}`);
    }

    const report = await this._callRequest<ReportResponse>({
      method: POST,
      path: `/strategies/assessments/sphere-reports`,
      body,
      queryParams: {
        _filters: filtersQueries.join(";"),
      },
    });

    return report;
  };

  fetchMyAssessmentReport = async ({
    body,
    period,
  }: {
    body: any;
    period: FilterDate;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [];
    if (period.startDate) {
      filtersQueries.push(
        `updateDate>=${moment(period.startDate).startOf("day").toISOString()}`,
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        `updateDate<=${moment(period.endDate).endOf("day").toISOString()}`,
      );
    }
    const report = await this._callRequest<ReportResponse>({
      method: POST,
      path: `/strategies/assessments/myself-reports`,
      queryParams: {
        _filters: filtersQueries.join(";"),
      },
      body,
    });

    return report;
  };
  signIn = async (body: { email: string }): Promise<void> => {
    await this._callRequestWithoutToken({
      method: POST,
      path: "/users/sign-in",
      body,
    });
  };

  getCampaigns = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<CampaignResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/campaigns/definitions`,
      queryString,
    });
  };
  createCampaign = async (
    campaign: CampaignPost,
  ): Promise<CampaignResponse> => {
    return this._callRequest<CampaignResponse>({
      method: POST,
      path: `/campaigns/definitions`,
      body: campaign,
    });
  };
  updateCampaign = async (
    id: string,
    campaign: Partial<CampaignPut>,
  ): Promise<CampaignResponse> => {
    return this._callRequest<CampaignResponse>({
      method: PUT,
      path: `/campaigns/definitions/${id}`,
      body: campaign,
    });
  };
  getCampaign = async (campaignId: string): Promise<CampaignResponse> => {
    return this._callRequest<CampaignResponse>({
      method: GET,
      path: `/campaigns/definitions/${campaignId}`,
    });
  };

  getCampaignCycles = async ({
    campaignId,
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    campaignId: string;
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<Cycle>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/campaigns/definitions/${campaignId}/cycles`,
      queryString,
    });
  };

  createCampaignCycle = async ({
    campaignId,
    cycle,
  }: {
    campaignId: string;
    cycle: Partial<CyclePut>;
  }): Promise<Cycle> => {
    return this._callRequest<Cycle>({
      method: POST,
      path: `/campaigns/definitions/${campaignId}/cycles`,
      body: cycle,
    });
  };
  updateCampaignCycle = async ({
    id,
    campaignId,
    cycle,
  }: {
    id: number;
    campaignId: string;
    cycle: Partial<CyclePut>;
  }): Promise<Cycle> => {
    return this._callRequest<Cycle>({
      method: PUT,
      path: `/campaigns/definitions/${campaignId}/cycles/${id}`,
      body: cycle,
    });
  };

  getActiveParticipations = async (): Promise<ParticipationResponse[]> => {
    const filtersQueries: string[] = [];
    filtersQueries.push(`systemStatus=active`);
    filtersQueries.push(`status=blank,inProgress`);

    const participations = await this._callRequest<ParticipationResponse[]>({
      method: GET,
      path: `/campaigns/participations`,
      queryParams: {
        _max: `${MAX_SAFE}`,
        _filters: filtersQueries.join(";"),
        "_sort-field": "updateDate",
        "_sort-dir": "desc",
      },
    });
    return participations;
  };
  updateParticipation = async ({
    id,
    participation,
  }: {
    id: string;
    participation: ParticipationPut;
  }): Promise<ParticipationResponse> => {
    return this._callRequest<ParticipationResponse>({
      method: PUT,
      path: `/campaigns/participations/${id}`,
      body: participation,
    });
  };
  getParticipationFromCampaignId = async (
    campaignId: string,
  ): Promise<ParticipationResponse> => {
    return this._callRequest<ParticipationResponse>({
      method: POST,
      path: `/campaigns/participations`,
      body: {
        campaignId,
      },
    });
  };
  getParticipationFacets = async ({
    campaignId,
    cycleId,
  }: {
    campaignId: string;
    cycleId?: number;
  }): Promise<FacetResponse> => {
    const queryParams: Record<string, string> = {};
    const filters = [`campaignId=${campaignId}`];
    if (cycleId) {
      filters.push(`cycleId=${cycleId}`);
    }
    if (filters?.length) {
      queryParams._filters = filters.join(";");
    }

    return await this._callRequest({
      method: GET,
      path: `/campaigns/participations/facets`,
      queryParams,
    });
  };

  fetchCampaignReport = async ({
    subject,
    sessionId,
    sphereId,
    campaignId,
    body,
    filters,
    period,
  }: {
    subject: Subject.CampaignParticipation;
    campaignId?: string;
    sessionId?: string;
    sphereId?: string;
    body: any;
    filters: string[];
    period: FilterDate;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [...filters];
    if (sessionId) {
      const childrenIds = await api.getSessionChildrenIds({ sessionId });
      filtersQueries.push(`sessionId=${[sessionId, ...childrenIds].join(",")}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    if (campaignId) {
      filtersQueries.push(`campaignId=${campaignId}`);
    }

    if (period.startDate) {
      filtersQueries.push(
        `updateDate>=${moment(period.startDate).startOf("day").toISOString()}`,
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        `updateDate<=${moment(period.endDate).endOf("day").toISOString()}`,
      );
    }

    const reponse = await this._callRequest<ReportResponse>({
      method: POST,
      path: `/campaigns/sphere-reports`,
      body: { ...body, subject },
      queryParams: {
        _filters: filtersQueries.join(";"),
      },
    });
    return reponse;
  };

  getReports = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<CustomReportResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return this._callRequestWithPagination({
      max,
      offset,
      path: `/reports`,
      queryString,
    });
  };
  getReport = async (id: string): Promise<CustomReportResponse> => {
    return this._callRequest<CustomReportResponse>({
      method: GET,
      path: `/reports/${id}`,
    });
  };
  createReport = async (report: ReportPost): Promise<CustomReportResponse> => {
    return this._callRequest<CustomReportResponse>({
      method: POST,
      path: `/reports`,
      body: report,
    });
  };
  updateReport = async (
    id: string,
    report: Partial<ReportPut>,
  ): Promise<CustomReportResponse> => {
    return this._callRequest<CustomReportResponse>({
      method: PUT,
      path: `/reports/${id}`,
      body: report,
    });
  };
  deleteReport = async (id: string): Promise<void> => {
    await this._callRequest({ method: DELETE, path: `/reports/${id}` });
  };
  getReportCards = async ({
    showHidden,
    reportId,
    reportPartId,
  }: {
    reportId: string;
    reportPartId: string;
    showHidden: boolean;
  }): Promise<CardResponse[]> => {
    const filters = [`reportPartId=${reportPartId}`];
    if (!showHidden) {
      filters.push(`hidden=false`);
    }

    const cards = await this._callRequestWithPagination<CardResponse>({
      max: 1000,
      offset: 0,
      path: `/reports/${reportId}/cards`,
      queryString: {
        _filters: filters.join(";"),
      },
    });

    return cards.items;
  };
  addCard = async ({
    reportId,
    card,
  }: {
    reportId: string;
    card: CardPost;
  }): Promise<CardResponse> => {
    const newCard = await this._callRequest<CardResponse>({
      method: POST,
      path: `/reports/${reportId}/cards`,
      body: card,
    });

    return newCard;
  };

  deleteCard = async (
    reportId: string,
    cardId: string,
  ): Promise<Partial<CardResponse>> => {
    const response = await this._callRequest({
      method: DELETE,
      path: `/reports/${reportId}/cards/${cardId}`,
    });

    return response as Pick<CardResponse, "affectedCards">;
  };

  updateCard = async ({
    id,
    card,
    reportId,
  }: {
    id: string;
    card: Partial<CardPut>;
    reportId: string;
  }): Promise<CardResponse> => {
    return await this._callRequest<CardResponse>({
      method: PUT,
      path: `/reports/${reportId}/cards/${id}`,
      body: card,
    });
  };

  updateCardPrompt = async ({
    cardId,
    reportId,
    prompt,
  }: {
    reportId: string;
    cardId: string;
    prompt: string;
  }): Promise<CardResponse> => {
    const card = await this._callRequest<CardResponse>({
      method: PUT,
      path: `/reports/${reportId}/cards/${cardId}/prompt`,
      body: { prompt },
    });
    return card;
  };

  getReportRenders = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
    reportId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    reportId: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<ReportRenders>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return this._callRequestWithPagination({
      max,
      offset,
      path: `/reports/${reportId}/renders`,
      queryString,
    });
  };
  renderReport = async (
    reportId: string,
    {
      name,
      format,
      lang,
      partsIds,
    }: {
      name: string;
      format: "pdf" | "docx";
      lang: string;
      partsIds: string[];
    },
  ): Promise<void> => {
    await this._callRequest({
      method: POST,
      path: `/reports/${reportId}/renders`,
      body: {
        name,
        format,
        lang,
        partsIds,
      },
    });
  };
  deleteReportRender = async ({
    reportId,
    renderId,
  }: {
    reportId: string;
    renderId: string;
  }) => {
    await this._callRequest({
      method: DELETE,
      path: `/reports/${reportId}/renders/${renderId}`,
    });
  };
}

const api = new Api();

export default api;
