import { FC, memo, ReactElement } from "react";
import { HistoryLineIcon } from "../RemixIcons";
import styles from "./HorizontalRule.module.scss";

type Props = {
  text: string | ReactElement;
  subText?: string;
  icon?: ReactElement;
  disabled?: boolean;
  className?: string;
  center?: boolean;
  titleClassName?: string;
};

const HorizontalRule: FC<Props> = ({
  text,
  subText,
  icon,
  disabled,
  className,
  center,
  titleClassName,
}) => {
  return (
    <div className={`${styles.root} ${className || ""}`}>
      <div className={styles.titleContainer}>
        {center && (
          <div className={styles.line} style={{ marginRight: "9px" }} />
        )}
        {icon && (
          <div
            className={!disabled ? styles.active : styles.disabled}
            style={{ marginRight: "9px" }}
          >
            {icon}
          </div>
        )}
        <span
          className={`${styles.title} ${
            !disabled ? styles.active : styles.disabled
          } ${titleClassName || ""}`}
        >
          {text}
        </span>

        <div className={styles.line} />
      </div>
      {subText && (
        <div
          className={`${styles.subtitle} ${
            !disabled ? styles.active : styles.disabled
          }`}
        >
          <HistoryLineIcon style={{ marginRight: 7 }} />
          {subText}
        </div>
      )}
    </div>
  );
};

export default memo(HorizontalRule);
