import cn from "classnames";
import styles from "./RippleButton.module.scss";

interface Props {
  src?: string;
  onClick: (event: any) => void;
  alt: any;
  className?: any;
  size: "big" | "small";
}

const RippleButton = ({ src, alt, onClick, className, size }: Props) => {
  const width = size === "small" ? 45 : 300;
  const height = size === "small" ? 45 : 300;

  return (
    <div className={cn(styles.root, className)}>
      <button
        type="button"
        onClick={onClick}
        className={cn(styles.ripple, styles.defaultBtn)}
      >
        <img src={src} alt={alt} width={width} height={height} />
      </button>
    </div>
  );
};

export default RippleButton;
