import React, { useState, useContext, useEffect } from "react";
import styles from "./StrategiesForm.module.scss";
import { useTranslation } from "react-i18next";
import {
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import ReactLoading from "react-loading";
import StrategiesSelection from "./StrategiesSelection";
import {
  Strategy,
  StrategyPost,
  StrategyType,
} from "../../@types/strategy-api";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import api from "../../api/Api";
import { PaginableList } from "../../@types/seen-apps";
import { captureException } from "@sentry/react";
import { toast } from "react-toastify";
import { getErrorTranslation } from "../../tools/errorTools";
import {
  SET_GOAL_RANKINGS,
  SET_STRATEGIES,
} from "../../context/globalState/globalStateReducer";
import { AddLineIcon, HiddenIcon } from "../RemixIcons";
import { getStrategyIcon } from "../../tools/strategyTools";
import StrategyFormModal from "./StrategyFormModal";
import LoadingPage from "../../pages/loading/LoadingPage";
import moment from "moment-timezone";

const StrategiesForm: React.FC<{
  disabled?: boolean;
  isMyself?: boolean;
  onClose?: () => void;
}> = ({ disabled, isMyself }) => {
  const {
    dispatch,
    state: { context, user, goalRankings },
  } = useContext(GlobalStateContext);

  const { t } = useTranslation("i18n");

  const [saving, setSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showHidden, setShowHidden] = useState<boolean>(true);
  const [strategyInEdition, setStrategyInEdition] = useState<
    StrategyPost & { id?: string }
  >();

  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [strategiesToShow, setStrategiesToShow] = useState<Strategy[]>([]);
  const [dicoPrefix] = useState(
    isMyself ? "pages.myself-strategy" : "pages.strategy",
  );
  const [strategyTypes] = useState<StrategyType[]>(
    isMyself
      ? ["goal", "action", "custom"]
      : ["vision", "mission", "strategicAxis", "goal", "action", "custom"],
  );

  useEffect(() => {
    const loadStrategies = async () => {
      setIsLoading(true);
      let strategies: PaginableList<Strategy> | undefined = undefined;
      if (context.sessionCtx) {
        strategies = await api.getStrategies({
          max: 2000,
          sessionId: context.sessionCtx.session.id,
          linkTo: "session",
        });
      } else if (context.sphereCtx) {
        strategies = await api.getStrategies({
          max: 2000,
          sphereId: context.sphereCtx.sphere.id,
          linkTo: "sphere",
        });
      } else {
        strategies = await api.getStrategies({
          max: 2000,
          linkTo: "user",
        });
      }
      setStrategies(strategies?.items || []);
      setIsLoading(false);
    };
    loadStrategies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch({
      type: SET_STRATEGIES,
      payload: strategies,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategies]);
  useEffect(() => {
    setStrategiesToShow(
      showHidden ? strategies : strategies.filter((s) => !s.isHidden),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategies, showHidden]);

  useEffect(() => {
    const loadGoals = async () => {
      const [survey] = await api.getFormSurveys(
        moment().startOf("year").subtract(3, "year").toDate(),
        moment().endOf("year").toDate(),
        {
          formId: "AQ8579T6LZR",
          max: 1,
        },
      );
      dispatch({
        type: SET_GOAL_RANKINGS,
        payload: survey?.ranking || {},
      });
    };

    if (!goalRankings) {
      loadGoals();
    }
  }, [dispatch, goalRankings]);

  const handleOnChange = async (values: Strategy[]) => {
    setSaving(true);
    try {
      const response = await Promise.all(
        values.map((strategy, index) => {
          const previous = strategies.find((s) => s.id === strategy.id);
          //Compare if changed before save
          if (
            previous?.order === index &&
            previous.indent === strategy.indent
          ) {
            return strategy;
          }
          return api.updateStrategy(strategy.id, {
            order: index,
            indent: strategy.indent,
          });
        }),
      );

      setStrategies(response);
      toast.success(t(`${dicoPrefix}.save.succeed`));
    } catch (error) {
      captureException(error);

      toast.error(
        getErrorTranslation({
          error,
          t,
        }),
      );
    }

    setSaving(false);
  };

  const handleAddStrategy = (event: any, type: StrategyType) => {
    event.stopPropagation();

    const newStrategy: StrategyPost = {
      order: strategies.length,
      type,
      indent: 0,
      description: undefined,
      sessionId: context.sessionCtx?.session.id,
      sphereId: context.sphereCtx?.sphere.id,
      title: undefined,
      userId: context.sessionCtx || context.sphereCtx ? undefined : user.userId,
      isHidden: false,
    };
    setStrategyInEdition(newStrategy);
  };

  const handleStrategyClicked = (strategy: Strategy) => {
    setStrategyInEdition(strategy);
  };

  const handleCloseStrategyModal = () => {
    setStrategyInEdition(undefined);
  };

  const handleShowHidden = () => {
    setShowHidden(!showHidden);
  };

  const handleStrategyChanged = async (strategy: Strategy) => {
    let found = false;

    const newStrategies = strategies.map((s) => {
      if (s.id !== strategy.id) {
        return s;
      }
      found = true;
      return strategy;
    });
    if (!found) {
      newStrategies.push(strategy);
    }
    setStrategies(newStrategies);
    setStrategyInEdition(undefined);
  };
  const handleStrategyDeleted = () => {
    setStrategies(strategies.filter((s) => s.id !== strategyInEdition?.id));
    setStrategyInEdition(undefined);
  };

  return (
    <div>
      {saving && (
        <div className={styles.loading}>
          <LoadingPage />
        </div>
      )}
      {!disabled && (
        <div className={styles.actions}>
          <DropdownButton
            as={ButtonGroup}
            title={
              <>
                <AddLineIcon /> <span>{t(`${dicoPrefix}.add`)}</span>
              </>
            }
            id="addStrategy"
          >
            {strategyTypes.map((type: StrategyType) => (
              <Dropdown.Item
                key={`strategy-types-${type}`}
                onClick={(e) => handleAddStrategy(e, type)}
              >
                {getStrategyIcon(type)}
                <span className={styles.addStrategyText}>
                  {t(`${dicoPrefix}.type.${type}`)}
                </span>
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <Button
            variant={showHidden ? "outline-primary" : "secondary"}
            onClick={handleShowHidden}
          >
            <HiddenIcon />
            {/* {t(`${dicoPrefix}.filter.showHidden`)} */}
          </Button>
        </div>
      )}

      <Container className={styles.noMargin}>
        <Row className={styles.noMargin}>
          <Col sm={12} className={styles.noMargin}>
            {isLoading ? (
              <div>
                <ReactLoading
                  type={"bars"}
                  color={"#DDD"}
                  height={"30px"}
                  width={"40px"}
                />
                {t("common.loading")}
              </div>
            ) : (
              <StrategiesSelection
                dicoPrefix={dicoPrefix}
                strategies={strategiesToShow}
                disabled={disabled}
                onChange={(strategies) => {
                  handleOnChange(strategies);
                }}
                onStrategyClicked={handleStrategyClicked}
              />
            )}
          </Col>
        </Row>
      </Container>
      {strategyInEdition && (
        <StrategyFormModal
          dicoPrefix={dicoPrefix}
          isMyself={isMyself}
          show={true}
          value={strategyInEdition}
          onChange={handleStrategyChanged}
          onDelete={handleStrategyDeleted}
          onClose={handleCloseStrategyModal}
        />
      )}
    </div>
  );
};

export default StrategiesForm;
