import React, { useContext, useEffect, useState } from "react";
import api from "../../api/Api";
import { toast } from "react-toastify";
import Select from "react-select";
import selectStyles from "../../styles/react-select.module.scss";
import modalStyles from "../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import memberPageStyles from "./AddOrUpdateSessionMemberPage.module.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import PageTitle from "../../components/PageTitle/PageTitle";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { SET_SESSION_MEMBER } from "../../context/globalState/globalStateReducer";
import { extractLocationData } from "../../tools/pathTools";
import { useLocation } from "react-router";
import { subject } from "@casl/ability";
import { Action, MemberResponse } from "../../@types/member-api";
import { confirmWrapper } from "../../tools/confirm";
import AbilityContext from "../../context/AbilityContext";
import { getSessionName } from "../../tools/sessionTools";
import { captureException } from "@sentry/minimal";
import { OptionsType } from "../../@types/webapp-api";

const styles = { ...modalStyles, ...memberPageStyles };

type Props = {
  currentPageIdx?: number;
  onSubmit?: (state?: any) => void;
};

type OptionType = {
  value: string;
  label: string;
};
type SessionMember = OptionType & {
  roles: string[];
};

const getMemberLabel = (member: MemberResponse) => {
  return `${member.name} - ${member.email}`;
};

const fetchSessionMembers = async ({
  sessionId,
  search,
  filters,
}: {
  sessionId: string;
  search?: string;
  filters?: string[];
}): Promise<SessionMember[]> => {
  const response = await api.getSessionMembers({
    sessionId,
    max: 200,
    searchQuery: search,
    offset: 0,
    filters,
  });

  const members = response.items.map((member) => {
    const sessionMember = member.sessionMembers.find((s) => s.id === sessionId);

    return {
      label: getMemberLabel(member),
      value: member.id,
      roles: sessionMember?.roles || [],
    };
  });
  return members;
};
const fetchSphereMembers = async ({
  sphereId,
  search,
  filters,
}: {
  sphereId: string;
  search?: string;
  filters?: string[];
}): Promise<SessionMember[]> => {
  const response = await api.getSphereMembers({
    sphereId,
    max: 200,
    searchQuery: search,
    offset: 0,
    filters,
  });

  const members = response.items.map((member) => {
    return {
      label: getMemberLabel(member),
      value: member.id,
      roles: [],
    };
  });
  return members;
};

const AddOrUpdateSessionMemberPage: React.FC<Props> = ({
  onSubmit = () => {},
}) => {
  const location = useLocation();
  const { t } = useTranslation("i18n");
  const {
    state: {
      sessionMembers,
      context: { sessionCtx },
      members,
    },

    dispatch,
  } = useContext(GlobalStateContext);
  const ability = useContext(AbilityContext);
  const [roleOptions] = useState<OptionsType<OptionType>>(
    ["member", "owner"].map((role) => ({
      value: role,
      label: t(`common.roles.${role}`),
    })),
  );

  const [sessionId, setSessionId] = useState<string>("");
  const [memberId, setMemberId] = useState<string>();
  const [memberSelected, setMemberSelected] = useState<SessionMember>();
  const [roles, setRoles] = useState<OptionType[]>([]);
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [couldDelete, setCouldDelete] = useState<boolean>(false);

  useEffect(() => {
    setCouldDelete(
      !!memberId &&
        !!sessionCtx &&
        !members.some((m) => m.id === memberId) &&
        ability.can(Action.Delete, subject("session", sessionCtx.session)),
    );
  }, [ability, memberId, members, sessionCtx]);

  useEffect(() => {
    const data = extractLocationData(location);

    const memberId = data.queryString.memberId;
    setMemberId(memberId);
    setErrors({});

    const sessionId = data.params.sessionId;
    if (!sessionId) {
      toast.error(t("common.not-found.session"));
      onSubmit();
      return;
    }
    setSessionId(sessionId);

    if (memberId) {
      fetchSessionMembers({
        sessionId,
        filters: [`id=${memberId}`],
      }).then((members) => {
        if (members.length === 0) {
          toast.error(t("common.not-found.member"));
          onSubmit();
          return;
        }
        setMemberSelected(members[0]);

        setRoles(
          roleOptions.filter((ro) =>
            members[0].roles.some((r) => r === ro.value),
          ),
        );
      });
    }
  }, [location, onSubmit, roleOptions, t]);

  const handleDelete = async () => {
    if (
      memberId &&
      (await confirmWrapper(
        t(`pages.sphere.sessions.members.delete-confirm.message`, {
          member: memberSelected?.label,
          session: getSessionName(sessionCtx?.session, ability),
        }),
        {
          btnPrimaryLabel: t(`common.action.delete`),
          title: t(`pages.sphere.sessions.members.delete-confirm.title`, {
            member: memberSelected?.label,
            session: getSessionName(sessionCtx?.session, ability),
          }),
        },
      ))
    ) {
      try {
        await api.deleteSessionMember(sessionId, memberId);
        toast.success(t(`pages.sphere.sessions.members.deleted`));

        dispatch({
          type: SET_SESSION_MEMBER,
          payload: [
            ...sessionMembers!.filter((mb) => mb.id !== memberSelected?.value),
          ],
        });
        onSubmit();
      } catch (error) {
        captureException(error);
        toast.error(t("common.error"));
      }
    }
  };

  const onSubmitForm = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const errors: Record<string, string> = {};
    if (roles.length === 0) {
      errors.roles = t("common.validations.required");
    }
    if (!memberSelected) {
      errors.member = t("common.validations.required");
    }
    if (Object.values(errors).filter((e) => !!e).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      if (memberId) {
        const updatedMember = await api.updateSessionMember(
          sessionId,
          memberId,
          {
            roles: roles.map((role: any) => role.value),
          },
        );
        toast.success(t(`pages.sphere.sessions.members.updated`));

        dispatch({
          type: SET_SESSION_MEMBER,
          payload: [
            ...sessionMembers!.filter((mb) => mb.id !== updatedMember.id),
            updatedMember,
          ],
        });
        onSubmit();
      } else if (memberSelected) {
        const addedMember = await api.addSessionMember(sessionId, {
          roles: roles.map((role: any) => role.value),
          memberId: memberSelected.value,
        });
        toast.success(t(`pages.sphere.sessions.members.added`));
        dispatch({
          type: SET_SESSION_MEMBER,
          payload: [...sessionMembers!, addedMember],
        });
        onSubmit();
      }
    } catch (error) {
      captureException(error);
      toast.error(t("common.error"));
    }
  };

  const loadMembersOptions = (
    inputValue: string,
    callback: (data: SessionMember[]) => void,
  ) => {
    if (!sessionCtx) {
      callback([]);
      return;
    }
    fetchSphereMembers({
      sphereId: sessionCtx.session.sphere.id,
      search: inputValue,
      //Exclude member already in session
      filters: [`sessionId!=${sessionId}`, `active=true`],
    }).then((members) => {
      callback(members);
    });
  };

  const handleInputOwnerChange = (newValue: string) => {
    return newValue;
  };

  const handleMemberSelected = (memberSelected: any) => {
    setMemberSelected(memberSelected);
    if (errors.memberSelected) {
      setErrors({ ...errors, memberSelected: undefined });
    }
  };
  const handleRolesSelected = (rolesSelected: any) => {
    setRoles(rolesSelected);
    if (errors.roles) {
      setErrors({ ...errors, roles: undefined });
    }
  };

  return (
    <div className={styles.page}>
      <PageTitle
        title={t(
          `pages.sphere.sessions.members.${memberId ? "update" : "add"}`,
        )}
      />
      {sessionId && sessionCtx && (
        <div className={styles.content}>
          <Container>
            <Form onSubmit={onSubmitForm} autoComplete="off" noValidate>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="member">
                    <Form.Label>
                      {t(`pages.sphere.sessions.members.member`)} *
                    </Form.Label>
                    <Form.Control
                      name="memberCheck"
                      type="hidden"
                      isInvalid={!!errors.member}
                    />
                    <AsyncSelect
                      isDisabled={!!memberId}
                      className={selectStyles.reactSelect}
                      classNamePrefix="custom-react-select"
                      loadOptions={loadMembersOptions}
                      defaultOptions
                      onInputChange={handleInputOwnerChange}
                      onChange={handleMemberSelected}
                      placeholder={t(
                        `pages.sphere.sessions.members.memberPlaceholder`,
                      )}
                      autoFocus={!memberId}
                      value={memberSelected}
                      noOptionsMessage={() => t("common.noValue")}
                    />

                    {errors.member && (
                      <Form.Control.Feedback type="invalid">
                        {errors.member}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="roles">
                    <Form.Label>
                      {t(`pages.sphere.sessions.members.roles`)} *
                    </Form.Label>
                    <Form.Control
                      name="rolesCheck"
                      type="hidden"
                      isInvalid={!!errors.roles}
                    />
                    <Select
                      className={selectStyles.reactSelect}
                      classNamePrefix="custom-react-select"
                      options={roleOptions}
                      isMulti
                      onChange={handleRolesSelected}
                      placeholder={t(`pages.sphere.sessions.members.roles`)}
                      autoFocus={!!memberId}
                      value={roles}
                      noOptionsMessage={() => t("common.noValue")}
                    />
                    {errors.roles && (
                      <Form.Control.Feedback type="invalid">
                        {errors.roles}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className={styles.buttons}>
                  {couldDelete && (
                    <Button
                      variant="danger"
                      className={styles.right}
                      onClick={() => handleDelete()}
                    >
                      {t("common.action.delete")}
                    </Button>
                  )}

                  <Button
                    variant="secondary"
                    className={styles.right}
                    onClick={() => onSubmit()}
                  >
                    {t("common.action.close")}
                  </Button>
                  <Button name="submit" type="submit">
                    {t("common.action.save")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      )}
    </div>
  );
};

export default AddOrUpdateSessionMemberPage;
