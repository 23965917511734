import { useState, useEffect } from "react";

import styles from "./FeelingEvaluationPeriodInput.module.scss";
import { Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HistoryLineIcon, TimeLineIcon } from "../RemixIcons";
import { isString } from "lodash";

export const TIMES_AVAILABLE = [
  "1 day",
  "1 week",
  "1 month",
  "1 quarter",
  "1 year",
];

type Time = {
  label: string;
  value?: string;
};

interface Props {
  disabled?: boolean;
  selectedTime?: string;
  onChange: (time?: string) => void;
}

const FeelingEvaluationPeriodInput = ({
  disabled,
  selectedTime,
  onChange,
}: Props) => {
  const { t } = useTranslation("i18n");

  const [timeSelections, setTimeSelections] = useState<Time[]>([]);

  const formatTimeLabel = (time: Time | string | undefined) => {
    const label =
      isString(time) || time === undefined
        ? timeSelections.find((choice) => choice.value === time)?.label
        : time.label;

    return (
      <>
        <span className={styles.choiceIcon}>
          {time ? <HistoryLineIcon /> : <TimeLineIcon />}
        </span>
        <span className={styles.choiceLabel}>{label}</span>
      </>
    );
  };

  useEffect(() => {
    const noTime = {
      label: t("pages.feeling.noTime"),
      value: undefined,
    };
    const timeSelections: Time[] = [
      noTime,
      ...TIMES_AVAILABLE.map((time) => {
        return {
          label: t(`pages.feeling.subTitle.feeling.duration.${time}`),
          value: time,
        };
      }),
    ];

    setTimeSelections(timeSelections);
  }, [t]);

  const handleTimeChange = (time?: string) => {
    onChange(time);
  };

  return disabled ? (
    <span className={styles.label}>{formatTimeLabel(selectedTime)}</span>
  ) : (
    <DropdownButton
      as={ButtonGroup}
      title={formatTimeLabel(selectedTime)}
      id="timesChoices"
    >
      {timeSelections.map((choice) => (
        <Dropdown.Item
          active={!!(selectedTime && choice.value === selectedTime)}
          key={`time-choice-${choice.value}`}
          onClick={() => handleTimeChange(choice.value)}
        >
          {formatTimeLabel(choice)}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
export default FeelingEvaluationPeriodInput;
