import {
  GlobalStateCtx,
  FeelingWord,
  Feeling,
  Filters,
  FacetResponse,
  PaginableList,
  GoalRankings,
  ChatMemory,
} from "../../@types/seen-apps";
import {
  Profile,
  UserInfo,
  Email,
  ResponseModel,
  EventAction,
  Ability,
  Notification,
  ReportTabChangeEvent,
  FeelingGeneralReportResponse,
} from "../../@types/webapp-api";
import moment from "moment";
import {
  Category,
  Sphere,
  SphereTagSelectionResponse,
  Tag,
} from "../../@types/sphere-api";
import { Axis, Thinking } from "../../@types/thinking-api";
import { convertEventToThinking } from "../../tools/thinkingTools";
import { getAllMySessions } from "../../tools/sessionTools";
import { changeLanguage, getCurrentLanguage } from "../../i18n";
import { Session } from "../../@types/session-api";
import { MemberResponse } from "../../@types/member-api";
import { Ability as UserAbility } from "@casl/ability";
import { FormEntitlementResponse, FormSurvey } from "../../@types/form-api";
import { UrlAlias } from "../../@types/shorten-url-api";
import { isEqual } from "lodash";
import { PromotionCode } from "../../@types/user-service-api";
import { Strategy, StrategyAssessment } from "../../@types/strategy-api";
import { sortBy } from "lodash";
import { Campaign, Cycle } from "../../@types/campaign-api";
import { ReportResponse } from "../../@types/report-api";
import { AiChatEvent } from "../../@types/ai-api";

export const SET_SELECTED_SPHERE = "SET_SELECTED_SPHERE";
export const SET_DEVICE_NAME = "SET_DEVICE_NAME";
export const SET_CODE = "SET_CODE";
export const SET_THINKING_AXES = "SET_THINKING_AXES";
export const ADD_OR_UPDATE_THINKING_AXIS = "ADD_OR_UPDATE_THINKING_AXIS";
export const DELETE_THINKING_AXIS = "DELETE_THINKING_AXIS";
export const ADD_FEELING = "ADD_FEELING";
export const ADD_OR_UPDATE_FEELINGS = "ADD_OR_UPDATE_FEELINGS";
export const UPDATE_FEELING = "UPDATE_FEELING";
export const DELETE_FEELING = "DELETE_FEELING";
export const ADD_THINKING = "ADD_THINKING";
export const ADD_OR_UPDATE_THINKINGS = "ADD_OR_UPDATE_THINKINGS";
export const ADD_OR_UPDATE_FORM_SURVEYS = "ADD_OR_UPDATE_FORM_SURVEYS";
export const ADD_OR_UPDATE_STRATEGY_ASSESSMENTS =
  "ADD_OR_UPDATE_STRATEGY_ASSESSMENTS";
export const UPDATE_THINKING = "UPDATE_THINKING";
export const DELETE_THINKING = "DELETE_THINKING";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_CLIENT_NAME = "SET_CLIENT_NAME";
export const SET_SESSION_NAME = "SET_SESSION_NAME";
export const SET_SESSSION_LOCATION = "SET_SESSSION_LOCATION";

export const SET_PROFILE = "SET_PROFILE";
export const SET_USER = "SET_USER";

export const SET_EMAILS = "SET_EMAILS";
export const SET_STRATEGIES = "SET_STRATEGIES";
export const ADD_EMAIL = "ADD_EMAIL";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const DELETE_EMAIL = "DELETE_EMAIL";

export const SET_SPHERES = "SET_SPHERES";
export const ADD_SPHERE = "ADD_SPHERE";
export const UPDATE_SPHERE = "UPDATE_SPHERE";
export const DELETE_SPHERE = "DELETE_SPHERE";
export const SET_SPHERE_CATEGORIES = "SET_SPHERE_CATEGORIES";

export const SET_SESSIONS = "SET_SESSIONS";
export const ADD_SESSION = "ADD_SESSION";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";

export const SET_ABILITY = "SET_ABILITY";
export const SET_URL_ALIASES = "SET_URL_ALIASES";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";

export const SET_MEMBERS = "SET_MEMBERS";
export const ADD_MEMBER = "ADD_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";

export const SET_CYCLES = "SET_CYCLES";
export const ADD_CYCLE = "ADD_CYCLE";
export const UPDATE_CYCLE = "UPDATE_CYCLE";
export const DELETE_CYCLE = "DELETE_CYCLE";

export const SET_REPORTS = "SET_REPORTS";
export const ADD_REPORT = "ADD_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";

export const EVENT_RECEIVED = "EVENT_RECEIVED";
export const ADD_OR_UPDATE_NOTIFICATION = "ADD_OR_UPDATE_NOTIFICATION";

export const SET_WORDS = "SET_WORDS";

export const SET_MYSELF_FEELING_REPORT = "SET_MYSELF_FEELING_REPORT";

export const SET_CONTEXT = "SET_CONTEXT";

export const SET_SESSION_MEMBER = "SET_SESSION_MEMBER";
export const SET_SPHERE_MEMBER = "SET_SPHERE_MEMBER";
export const ADD_OR_UPDATE_SPHERE_MEMBER = "ADD_OR_UPDATE_SPHERE_MEMBER";
export const SET_SPHERE_TAG = "SET_SPHERE_TAG";
export const SET_SPHERE_TAGS_SELECTION = "SET_SPHERE_TAGS_SELECTION";
export const ADD_OR_UPDATE_SPHERE_TAGS_SELECTION =
  "ADD_OR_UPDATE_SPHERE_TAGS_SELECTION";
export const DELETE_SPHERE_TAGS_SELECTION = "DELETE_SPHERE_TAGS_SELECTION";
export const SET_SPHERE_FORM_ENTITLEMENT = "SET_SPHERE_FORM_ENTITLEMENT";
export const ADD_OR_UPDATE_PROMOTION_CODE = "ADD_OR_UPDATE_PROMOTION_CODE";
export const SET_PROMOTION_CODES = "SET_PROMOTION_CODES";

export const THINKING_AXES_CHANGED = "THINKING_AXES_CHANGED";

export const SET_REPORT_TAB_CHANGE = "SET_REPORT_TAB_CHANGE";

export const SET_FILTERS = "SET_FILTERS";
export const SET_FEELING_FACETS = "SET_FEELING_FACETS";
export const SET_GOAL_RANKINGS = "SET_GOAL_RANKINGS";

export const CLEAN_CHAT_MESSAGES = "CLEAN_CHAT_MESSAGES";

interface Action {
  type: string;
  payload: any;
}

const convertThinkingToNotification = (
  thinking: Thinking,
  showNotification: boolean,
): Notification => {
  return {
    showNotification,
    active: thinking.isBlank && moment().isAfter(thinking.freezeDate),
    id: thinking.id,
    timestamp: thinking.creationDate,
    thinking: thinking,
  };
};

const reportTabSelected = JSON.parse(
  localStorage.getItem("reportTabSelected") || "{}",
);

export const initialState: GlobalStateCtx = {
  state: {
    language: "fr",
    deviceName: "",
    emails: [],
    spheres: [],
    sessions: [],
    mySessions: [],
    sphereCategories: [],
    feelings: [],
    thinkings: [],
    formSurveys: [],
    strategyAssessments: [],
    members: [],
    definition: {
      feelingWords: [],
    },
    notifications: [],
    reports: {},
    context: {
      sphereCtx: undefined,
      mySelfCtx: undefined,
    },
    user: {
      userId: "",
    },
    reportTabSelected,
    filters: {
      defaultDate: {
        endDate: moment().endOf("day").toDate(),
        startDate: moment().startOf("month").subtract(3, "month").toDate(),
      },
      feeling: {},
      count: 0,
    },
    feelingFacets: {},
    chatMessages: {},
  },
  dispatch: () => {},
};

const setLanguage = (langId: string, globalState: GlobalStateCtx) => {
  localStorage.setItem("language", langId);
  if (getCurrentLanguage() !== langId) {
    changeLanguage(langId);
  }

  return {
    ...globalState,
    state: { ...globalState.state, language: langId },
  };
};

const setProfile = (profile: Profile, globalState: GlobalStateCtx) => {
  setLanguage(profile.locale, globalState);
  return {
    ...globalState,
    state: { ...globalState.state, profile },
  };
};
const setUser = (user: UserInfo, globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: { ...globalState.state, user },
  };
};
const setEmails = (emails: Email[], globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: { ...globalState.state, emails },
  };
};
const setStrategies = (strategies: Strategy[], globalState: GlobalStateCtx) => {
  const strategiesOrdered = sortBy(strategies || [], "order");

  return {
    ...globalState,
    state: { ...globalState.state, strategies: strategiesOrdered },
  };
};
const setMembers = (members: MemberResponse[], globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: { ...globalState.state, members },
  };
};
const setThinkingAxes = (
  thinkingAxes: PaginableList<Axis>,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      thinkingAxes,
    },
  };
};

const deleteThinkingAxis = (
  thinkingAxis: Axis,
  globalState: GlobalStateCtx,
) => {
  if (!globalState.state.thinkingAxes) {
    return globalState;
  }
  const exists = globalState.state.thinkingAxes.items.some(
    (f) => f.id === thinkingAxis.id,
  );
  if (!exists) {
    return globalState;
  }
  return {
    ...globalState,
    state: {
      ...globalState.state,
      thinkingAxes: {
        ...globalState.state.thinkingAxes,
        items: globalState.state.thinkingAxes.items.filter(
          (i) => i.id !== thinkingAxis.id,
        ),
        count: globalState.state.thinkingAxes.count - 1,
        max: globalState.state.thinkingAxes.max - 1,
      },
    },
  };
};
const addOrUpdateThinkingAxis = (
  thinkingAxis: Axis,
  globalState: GlobalStateCtx,
) => {
  if (!globalState.state.thinkingAxes) {
    return globalState;
  }
  const axisIdx = globalState.state.thinkingAxes.items.findIndex(
    (f) => f.id === thinkingAxis.id,
  );

  let addMode = false;
  const axes = [...globalState.state.thinkingAxes.items];
  if (axisIdx >= 0) {
    axes[axisIdx] = thinkingAxis;
  } else {
    addMode = true;
    axes.push(thinkingAxis);
  }

  return {
    ...globalState,
    state: {
      ...globalState.state,
      thinkingAxes: {
        ...globalState.state.thinkingAxes,
        items: axes,
        count: addMode
          ? globalState.state.thinkingAxes.count + 1
          : globalState.state.thinkingAxes.count,
        max: axes.length,
      },
    },
  };
};

const setCampaigns = (campaigns: Campaign[], globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: { ...globalState.state, campaigns },
  };
};
const setUrlAliases = (urlAliases: UrlAlias[], globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: { ...globalState.state, urlAliases },
  };
};
const setAbility = (ability: Ability, globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: { ...globalState.state, ability },
  };
};
const setWords = (feelingWords: FeelingWord[], globalState: GlobalStateCtx) => {
  const definition = {
    ...globalState.state.definition,
    feelingWords: feelingWords,
  };

  return {
    ...globalState,
    state: { ...globalState.state, definition },
  };
};

const setSpheres = (spheres: Sphere[], globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      spheres,
    },
  };
};
const setSphereCategories = (
  sphereCategories: Category[],
  globalState: GlobalStateCtx,
) => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sphereCategories,
    },
  };
};

const setSessionMembers = (
  sessionMembers: MemberResponse[],
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sessionMembers,
    },
  };
};
const setSphereMembers = (
  sphereMembers: PaginableList<MemberResponse>,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sphereMembers,
    },
  };
};

const addOrUpdateSphereMembers = (
  sphereMember: MemberResponse | MemberResponse[],
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  const members = Array.isArray(sphereMember) ? sphereMember : [sphereMember];
  if (!globalState.state.sphereMembers) {
    return {
      ...globalState,
      state: {
        ...globalState.state,
        sphereMembers: {
          count: members.length,
          items: members,
          max: members.length,
          offset: 0,
        },
      },
    };
  }

  const newState = members.reduce(
    (acc, member) => {
      const itemIdx = acc.items.findIndex((m) => m.id === member.id);
      if (itemIdx < 0) {
        return {
          ...acc,
          count: acc.count + 1,
          max: acc.max + 1,
          items: [...acc.items, member],
        };
      }

      const items = [...acc.items];
      items[itemIdx] = member;

      return { ...acc, items };
    },
    globalState.state.sphereMembers ||
      ({
        items: [],
        count: 0,
        offset: 0,
        max: 0,
      } as PaginableList<MemberResponse>),
  );

  return {
    ...globalState,
    state: {
      ...globalState.state,
      sphereMembers: newState,
    },
  };
};

const setPromotionCodes = (
  promotionCodes: PromotionCode[],
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      promotionCodes,
    },
  };
};

const addOrUpdatePromotionCodes = (
  promotionCode: PromotionCode | PromotionCode[],
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  const promotionCodes = Array.isArray(promotionCode)
    ? promotionCode
    : [promotionCode];
  if (!globalState.state.sphereMembers) {
    return {
      ...globalState,
      state: {
        ...globalState.state,
        promotionCodes,
      },
    };
  }

  const newState = promotionCodes.reduce((acc, item) => {
    const itemIdx = acc.findIndex((p) => p.id === item.id);
    if (itemIdx < 0) {
      return [...acc, item];
    }
    acc[itemIdx] = item;
    return acc;
  }, globalState.state.promotionCodes || []);

  return {
    ...globalState,
    state: {
      ...globalState.state,
      promotionCodes: newState,
    },
  };
};
const setSphereTags = (
  sphereTags: PaginableList<Tag>,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sphereTags,
    },
  };
};
const setSphereTagsSelections = (
  sphereTagsSelections: PaginableList<SphereTagSelectionResponse>,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sphereTagsSelections,
    },
  };
};

const addOrUpdateSphereTagsSelections = (
  selection: SphereTagSelectionResponse | SphereTagSelectionResponse[],
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  const selections = Array.isArray(selection) ? selection : [selection];
  if (!globalState.state.sphereTagsSelections) {
    return {
      ...globalState,
      state: {
        ...globalState.state,
        sphereTagsSelections: {
          count: selections.length,
          items: selections,
          max: selections.length,
          offset: 0,
        },
      },
    };
  }
  const newState = selections.reduce(
    (acc, selection) => {
      const itemIdx = acc.items.findIndex((s) => s.field === selection.field);
      if (itemIdx < 0) {
        return {
          ...acc,
          count: acc.count + 1,
          max: acc.max + 1,
          items: [...acc.items, selection],
        };
      }

      const items = [...acc.items];
      items[itemIdx] = selection;

      return { ...acc, items };
    },
    globalState.state.sphereTagsSelections ||
      ({
        items: [],
        count: 0,
        offset: 0,
        max: 0,
      } as PaginableList<Cycle>),
  );

  return {
    ...globalState,
    state: {
      ...globalState.state,
      sphereTagsSelections: newState,
    },
  };
};

const deleteSphereTagsSelections = (
  cycle: SphereTagSelectionResponse | SphereTagSelectionResponse[],
  globalState: GlobalStateCtx,
) => {
  const memoryCycles = globalState.state.sphereTagsSelections;
  if (!memoryCycles) {
    return globalState;
  }

  const cycles = Array.isArray(cycle) ? cycle : [cycle];
  const selectionsRemaining = memoryCycles.items.filter(
    (selection) => !cycles.some((c) => c.field === selection.field),
  );
  const deleteCount = memoryCycles.items.length - selectionsRemaining.length;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sphereTagsSelections: {
        ...memoryCycles,
        items: selectionsRemaining,
        count: selectionsRemaining.length,
        max: memoryCycles.max - deleteCount,
      } as PaginableList<SphereTagSelectionResponse>,
    },
  };
};

const setFormEntitlements = (
  formEntitlements: FormEntitlementResponse[],
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      formEntitlements,
    },
  };
};

const setReportTabChanged = (
  reportTabChangeEvent: ReportTabChangeEvent,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  const updated = {
    ...globalState.state.reportTabSelected,
    [reportTabChangeEvent.reportPageId]: reportTabChangeEvent.report,
  };

  localStorage.setItem("reportTabSelected", JSON.stringify(updated));

  return {
    ...globalState,
    state: {
      ...globalState.state,
      reportTabSelected: updated,
    },
  };
};

const setFiltersChanged = (
  payload: Omit<Filters, "count">,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  let count = Object.keys(payload.feeling || {}).length || 0;
  if (payload.date) {
    count++;
  }
  if (payload.sphereIds?.length) {
    count++;
  }
  if (payload.sessionIds?.length) {
    count++;
  }
  const newFilter = {
    ...payload,
    count,
  };

  if (isEqual(newFilter, globalState.state.filters)) {
    return globalState;
  }

  return {
    ...globalState,
    state: {
      ...globalState.state,
      filters: newFilter,
    },
  };
};

const setFeelingFacetsChanged = (
  payload: FacetResponse,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      feelingFacets: payload,
    },
  };
};

const setGoalRankingsChanged = (
  payload: GoalRankings,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      goalRankings: payload,
    },
  };
};

const setThinkingAxesChanged = (
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      thinkingAxisLastChangedAt: new Date().toISOString(),
    },
  };
};

const addOrUpdateEmail = (email: Email, globalState: GlobalStateCtx) => {
  const emailIdx = globalState.state.emails.findIndex(
    (e) => e.email === email.email,
  );

  if (emailIdx < 0) {
    const emails = [...globalState.state.emails, email];
    return {
      ...globalState,
      state: {
        ...globalState.state,
        emails,
      },
    };
  }

  const emails = [...globalState.state.emails];
  emails[emailIdx] = email;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      emails,
    },
  };
};

const deleteEmail = (email: Email, globalState: GlobalStateCtx) => {
  const emailIdx = globalState.state.emails.findIndex(
    (e) => e.email === email.email,
  );
  if (emailIdx < 0) {
    return globalState;
  }
  const emails = [...globalState.state.emails];
  emails.splice(emailIdx, 1);
  return {
    ...globalState,
    state: {
      ...globalState.state,
      emails,
    },
  };
};

const addOrUpdateSphere = (sphere: Sphere, globalState: GlobalStateCtx) => {
  const sphereIdx = globalState.state.spheres.findIndex(
    (s) => s.id === sphere.id,
  );

  if (sphereIdx < 0) {
    const spheres = [...globalState.state.spheres, sphere];
    return {
      ...globalState,
      state: {
        ...globalState.state,
        spheres,
      },
    };
  }

  const isSphereInContext =
    globalState.state.context?.sphereCtx?.sphere.id === sphere.id;

  const spheres = [...globalState.state.spheres];
  spheres[sphereIdx] = sphere;

  const newState: GlobalStateCtx = {
    ...globalState,
    state: {
      ...globalState.state,
      spheres,
      context: isSphereInContext
        ? {
            sphereCtx: {
              sphere,
              category: globalState.state.sphereCategories.find(
                (cat) => cat.id === sphere.category.id,
              ) as Category,
            },
          }
        : globalState.state.context,
    },
  };

  return newState;
};

const deleteSphere = (sphere: Sphere, globalState: GlobalStateCtx) => {
  const sphereIdx = globalState.state.spheres.findIndex(
    (s) => s.id === sphere.id,
  );
  if (sphereIdx < 0) {
    return globalState;
  }
  const spheres = [...globalState.state.spheres];
  spheres.splice(sphereIdx, 1);
  return {
    ...globalState,
    state: {
      ...globalState.state,
      spheres,
    },
  };
};

const addOrUpdateMember = (
  member: MemberResponse,
  globalState: GlobalStateCtx,
) => {
  const memberIdx = globalState.state.members.findIndex(
    (m) => m.id === member.id,
  );

  if (memberIdx < 0) {
    const members = [...globalState.state.members, member];
    return {
      ...globalState,
      state: {
        ...globalState.state,
        members,
      },
    };
  }

  const members = [...globalState.state.members];
  members[memberIdx] = member;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      members,
    },
  };
};

const deleteMember = (member: MemberResponse, globalState: GlobalStateCtx) => {
  const memberIdx = globalState.state.members.findIndex(
    (m) => m.id === member.id,
  );
  if (memberIdx < 0) {
    return globalState;
  }
  const members = [...globalState.state.members];
  members.splice(memberIdx, 1);
  return {
    ...globalState,
    state: {
      ...globalState.state,
      members,
    },
  };
};

const addOrUpdateFeeling = (feeling: Feeling, globalState: GlobalStateCtx) => {
  const feelingIdx = globalState.state.feelings.findIndex(
    (f) => f.id === feeling.id,
  );

  if (feelingIdx < 0) {
    // add feeling at right position based on timestamp
    const idx = globalState.state.feelings.findIndex(
      (f) => feeling.timestamp > f.timestamp,
    );
    const feelings = [...globalState.state.feelings];
    if (idx < 0) {
      feelings.push(feeling);
    } else {
      feelings.splice(idx, 0, feeling);
    }

    return {
      ...globalState,
      state: {
        ...globalState.state,
        feelings,
        lastFeelingTimestamp: moment().toISOString(),
        lastFeelingUpdatedId: feeling.id,
      },
    };
  }

  const feelings = [...globalState.state.feelings];
  feelings[feelingIdx] = feeling;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      feelings,
      lastFeelingTimestamp: moment().toISOString(),
      lastFeelingUpdatedId: feeling.id,
    },
  };
};

const addOrUpdateFeelings = (
  feelings: Feeling[],
  globalState: GlobalStateCtx,
) => {
  return feelings.reduce(
    (acc, feeling) => addOrUpdateFeeling(feeling, acc),
    globalState,
  );
};

const deleteFeeling = (feeling: Feeling, globalState: GlobalStateCtx) => {
  const thinkingIdx = globalState.state.thinkings.findIndex(
    (f) => f.id === feeling.id,
  );
  if (thinkingIdx < 0) {
    return globalState;
  }
  const feelings = [...globalState.state.feelings];
  feelings.splice(thinkingIdx, 1);
  return {
    ...globalState,
    state: {
      ...globalState.state,
      feelings,
      lastFeelingTimestamp: moment().toISOString(),
      lastFeelingUpdatedId: feeling.id,
    },
  };
};

const addOrUpdateThinking = (
  thinking: Thinking,
  isNewThinking: boolean,
  globalState: GlobalStateCtx,
) => {
  const thinkingIdx = globalState.state.thinkings.findIndex(
    (f) => f.id === thinking.id,
  );

  const thinkings = [...globalState.state.thinkings];
  if (thinkingIdx >= 0) {
    thinkings[thinkingIdx] = thinking;
  } else {
    // add feeling at right position based on timestamp
    const idx = globalState.state.thinkings.findIndex(
      (f) => thinking.updateDate > f.updateDate,
    );
    if (idx < 0) {
      thinkings.push(thinking);
    } else {
      thinkings.splice(idx, 0, thinking);
    }
  }

  const newGlobalState: GlobalStateCtx = {
    ...globalState,
    state: {
      ...globalState.state,
      thinkings,
      lastThinkingTimestamp: moment().toISOString(),
    },
  };

  if (isNewThinking || moment(thinking.freezeDate).isAfter(moment())) {
    return addOrUpdateNotification(
      convertThinkingToNotification(
        thinking,
        isNewThinking && thinking.isBlank,
      ),
      newGlobalState,
    );
  }
  return newGlobalState;
};
const deleteThinking = (thinking: Thinking, globalState: GlobalStateCtx) => {
  const thinkingIdx = globalState.state.thinkings.findIndex(
    (f) => f.id === thinking.id,
  );
  if (thinkingIdx < 0) {
    return globalState;
  }
  const thinkings = [...globalState.state.thinkings];
  thinkings.splice(thinkingIdx, 1);

  const newGlobalState: GlobalStateCtx = {
    ...globalState,
    state: {
      ...globalState.state,
      thinkings,
      lastThinkingTimestamp: moment().toISOString(),
    },
  };

  return deleteNotification(
    convertThinkingToNotification(thinking, false),
    newGlobalState,
  );
};

const addOrUpdateNotification = (
  notification: Notification,
  globalState: GlobalStateCtx,
) => {
  const notificationIdx = globalState.state.notifications.findIndex(
    (f) => f.id === notification.id,
  );

  const notifications = [...globalState.state.notifications];
  if (notificationIdx >= 0) {
    notifications[notificationIdx] = notification;
  } else {
    // add feeling at right position based on timestamp
    const idx = globalState.state.notifications.findIndex(
      (n) => notification.timestamp > n.timestamp,
    );
    if (idx < 0) {
      notifications.push(notification);
    } else {
      notifications.splice(idx, 0, notification);
    }
  }

  return {
    ...globalState,
    state: {
      ...globalState.state,
      notifications: notifications,
    },
  };
};

const deleteNotification = (
  notification: Notification,
  globalState: GlobalStateCtx,
) => {
  const notificationIdx = globalState.state.notifications.findIndex(
    (n) => n.id === notification.id,
  );
  if (notificationIdx < 0) {
    return globalState;
  }
  const notifications = [...globalState.state.notifications];
  notifications.splice(notificationIdx, 1);
  return {
    ...globalState,
    state: {
      ...globalState.state,
      notifications,
    },
  };
};

const addOrUpdateThinkings = (
  thinkings: Thinking[],
  globalState: GlobalStateCtx,
) => {
  return thinkings.reduce(
    (acc, thinking) => addOrUpdateThinking(thinking, false, acc),
    globalState,
  );
};
const deleteFormSurvey = (
  formSurvey: FormSurvey,
  globalState: GlobalStateCtx,
) => {
  const formSurveyIdx = globalState.state.formSurveys.findIndex(
    (f) => f.id === formSurvey.id,
  );
  if (formSurveyIdx < 0) {
    return globalState;
  }
  const formSurveys = [...globalState.state.formSurveys];
  formSurveys.splice(formSurveyIdx, 1);
  return {
    ...globalState,
    state: {
      ...globalState.state,
      formSurveys,
      lastFormSurveyTimestamp: moment().toISOString(),
    },
  };
};
const addOrUpdateFormSurvey = (
  survey: FormSurvey,
  globalState: GlobalStateCtx,
) => {
  const surveyIdx = globalState.state.formSurveys.findIndex(
    (f) => f.id === survey.id,
  );

  if (surveyIdx < 0) {
    const formSurveys = [...globalState.state.formSurveys];
    // add feeling at right position based on timestamp
    const idx = globalState.state.formSurveys.findIndex(
      (s) => survey.updateDate > s.updateDate,
    );
    if (idx < 0) {
      formSurveys.push(survey);
    } else {
      formSurveys.splice(idx, 0, survey);
    }
    return {
      ...globalState,
      state: {
        ...globalState.state,
        formSurveys,
        lastFormSurveyTimestamp: moment().toISOString(),
      },
    };
  }

  const formSurveys = [...globalState.state.formSurveys];
  formSurveys[surveyIdx] = survey;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      formSurveys,
      lastFormSurveyTimestamp: moment().toISOString(),
    },
  };
};

const addOrUpdateFormSurveys = (
  surveys: FormSurvey[],
  globalState: GlobalStateCtx,
) => {
  return surveys.reduce(
    (acc, survey) => addOrUpdateFormSurvey(survey, acc),
    globalState,
  );
};
const deleteStrategyAssessment = (
  strategyAssessment: StrategyAssessment,
  globalState: GlobalStateCtx,
) => {
  const strategyAssessmentIdx = globalState.state.strategyAssessments.findIndex(
    (a) => a.id === strategyAssessment.id,
  );
  if (strategyAssessmentIdx < 0) {
    return globalState;
  }
  const strategyAssessments = [...globalState.state.strategyAssessments];
  strategyAssessments.splice(strategyAssessmentIdx, 1);
  return {
    ...globalState,
    state: {
      ...globalState.state,
      strategyAssessments,
      lastStrategyAssessmentTimestamp: moment().toISOString(),
    },
  };
};
const addOrUpdateStrategyAssessment = (
  strategyAssessment: StrategyAssessment,
  globalState: GlobalStateCtx,
) => {
  const strategyAssessmentIdx = globalState.state.strategyAssessments.findIndex(
    (f) => f.id === strategyAssessment.id,
  );

  if (strategyAssessmentIdx < 0) {
    const strategyAssessments = [...globalState.state.strategyAssessments];
    // add feeling at right position based on timestamp
    const idx = globalState.state.formSurveys.findIndex(
      (s) => strategyAssessment.updateDate > s.updateDate,
    );
    if (idx < 0) {
      strategyAssessments.push(strategyAssessment);
    } else {
      strategyAssessments.splice(idx, 0, strategyAssessment);
    }
    return {
      ...globalState,
      state: {
        ...globalState.state,
        strategyAssessments,
        lastStrategyAssessmentTimestamp: moment().toISOString(),
      },
    };
  }

  const strategyAssessments = [...globalState.state.strategyAssessments];
  strategyAssessments[strategyAssessmentIdx] = strategyAssessment;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      strategyAssessments,
      lastStrategyAssessmentTimestamp: moment().toISOString(),
    },
  };
};

const addOrUpdateStrategyAssessments = (
  strategyAssessments: StrategyAssessment[],
  globalState: GlobalStateCtx,
) => {
  return strategyAssessments.reduce(
    (acc, assessment) => addOrUpdateStrategyAssessment(assessment, acc),
    globalState,
  );
};

const setMyselfFeelingReport = (
  generalFeeling: FeelingGeneralReportResponse,
  globalState: GlobalStateCtx,
) => {
  const reports = {
    ...globalState.state.reports,
    generalFeeling,
    lastFeelingTimestamp: moment().toISOString(),
  };

  return {
    ...globalState,
    state: { ...globalState.state, reports },
  };
};

const updateMemory = (
  event: { type: ResponseModel; data: any; action: EventAction },
  globalState: GlobalStateCtx,
) => {
  if (event.type === "profile") {
    return setProfile(event.data, globalState);
  }
  if (event.type === "emails") {
    if (event.action === "deleted") {
      return deleteEmail(event.data, globalState);
    }
    return addOrUpdateEmail(event.data, globalState);
  }
  if (event.type === "member") {
    if (event.action === "deleted") {
      return deleteMember(event.data, globalState);
    }
    return addOrUpdateMember(event.data, globalState);
  }
  if (event.type === "spheres") {
    if (event.action === "deleted") {
      return deleteSphere(event.data, globalState);
    }
    return addOrUpdateSphere(event.data, globalState);
  }
  if (event.type === "sessions") {
    if (event.action === "deleted") {
      return deleteSession(event.data, globalState);
    }
    return addOrUpdateSession(event.data, globalState);
  }
  if (event.type === "ability") {
    return setAbility(event.data, globalState);
  }
  if (event.type === "feelings") {
    if (event.action === "deleted") {
      return deleteFeeling(event.data, globalState);
    }
    return addOrUpdateFeeling(event.data, globalState);
  }
  if (event.type === "formSurveys") {
    if (event.action === "deleted") {
      return deleteFormSurvey(event.data, globalState);
    }
    return addOrUpdateFormSurvey(event.data, globalState);
  }
  if (event.type === "strategyAssessments") {
    if (event.action === "deleted") {
      return deleteStrategyAssessment(event.data, globalState);
    }
    return addOrUpdateStrategyAssessment(event.data, globalState);
  }
  if (event.type === "thinkings") {
    if (event.action === "deleted") {
      return deleteThinking(event.data, globalState);
    }

    const ability = globalState.state.ability
      ? new UserAbility(globalState.state.ability.rules as any)
      : new UserAbility();

    return addOrUpdateThinking(
      // Receive multilingual thinking event
      convertEventToThinking(event.data, globalState.state.language, ability),
      event.action === "created",
      globalState,
    );
  }
  if (event.type === "chat") {
    return updateChatMessage(event.data as any, globalState);
  }
  return globalState;
};

const updateContext = (payload: any, globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      context: payload,
    },
  };
};

const setSessions = (sessions: Session[], globalState: GlobalStateCtx) => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sessions: sessions,
    },
  };
};
const addOrUpdateSession = (session: Session, globalState: GlobalStateCtx) => {
  const sessionIdx = globalState.state.sessions.findIndex(
    (s) => s.id === session.id,
  );

  if (sessionIdx < 0) {
    const sessions = [...globalState.state.sessions, session];
    return {
      ...globalState,
      state: {
        ...globalState.state,
        sessions: sessions,
      },
    };
  }

  const sessions = [...globalState.state.sessions];
  sessions[sessionIdx] = session;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sessions: sessions,
    },
  };
};

const deleteSession = (session: Session, globalState: GlobalStateCtx) => {
  const sessionIdx = globalState.state.sessions.findIndex(
    (s) => s.id === session.id,
  );
  if (sessionIdx < 0) {
    return globalState;
  }
  const sessions = [...globalState.state.sessions];
  sessions.splice(sessionIdx, 1);
  return {
    ...globalState,
    state: {
      ...globalState.state,
      sessions,
    },
  };
};

const setReports = (
  reports: PaginableList<ReportResponse>,
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      customReports: reports,
    },
  };
};

const addOrUpdateReports = (
  reports: ReportResponse | ReportResponse[],
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  const items = Array.isArray(reports) ? reports : [reports];
  if (!globalState.state.reports) {
    return {
      ...globalState,
      state: {
        ...globalState.state,
        customReports: {
          count: items.length,
          items: items,
          max: items.length,
          offset: 0,
        },
      },
    };
  }

  const newState = items.reduce(
    (acc, report) => {
      const itemIdx = acc.items.findIndex((m) => m.id === report.id);
      if (itemIdx < 0) {
        return {
          ...acc,
          count: acc.count + 1,
          max: acc.max + 1,
          items: [...acc.items, report],
        };
      }

      const items = [...acc.items];
      items[itemIdx] = report;

      return { ...acc, items };
    },
    globalState.state.customReports ||
      ({
        items: [],
        count: 0,
        offset: 0,
        max: 0,
      } as PaginableList<ReportResponse>),
  );

  return {
    ...globalState,
    state: {
      ...globalState.state,
      customReports: newState,
    },
  };
};

const deleteReport = (
  reports: ReportResponse | ReportResponse[],
  globalState: GlobalStateCtx,
) => {
  const memoryReports = globalState.state.customReports;
  if (!memoryReports) {
    return globalState;
  }

  const shots = Array.isArray(reports) ? reports : [reports];
  const reportsRemaining = memoryReports.items.filter(
    (cycle) => !shots.some((c) => c.id === cycle.id),
  );
  const deleteCount = memoryReports.items.length - reportsRemaining.length;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      customReports: {
        ...memoryReports,
        items: reportsRemaining,
        count: reportsRemaining.length,
        max: memoryReports.max - deleteCount,
      } as PaginableList<ReportResponse>,
    },
  };
};

const setCampaignCycles = (
  cycles: PaginableList<Cycle>,
  globalState: GlobalStateCtx,
) => {
  return {
    ...globalState,
    state: { ...globalState.state, campaignCycles: cycles },
  };
};

const addOrUpdateCampaignCycles = (
  cycle: Cycle | Cycle[],
  globalState: GlobalStateCtx,
): GlobalStateCtx => {
  const cycles = Array.isArray(cycle) ? cycle : [cycle];

  if (!globalState.state.campaignCycles) {
    return {
      ...globalState,
      state: {
        ...globalState.state,
        campaignCycles: {
          count: cycles.length,
          items: cycles,
          max: cycles.length,
          offset: 0,
        },
      },
    };
  }

  const newState = cycles.reduce(
    (acc, cycle) => {
      const itemIdx = acc.items.findIndex((c) => c.cycleId === cycle.cycleId);
      if (itemIdx < 0) {
        return {
          ...acc,
          count: acc.count + 1,
          max: acc.max + 1,
          items: [...acc.items, cycle],
        };
      }

      const items = [...acc.items];
      items[itemIdx] = cycle;

      return { ...acc, items };
    },
    globalState.state.campaignCycles ||
      ({
        items: [],
        count: 0,
        offset: 0,
        max: 0,
      } as PaginableList<Cycle>),
  );

  return {
    ...globalState,
    state: {
      ...globalState.state,
      campaignCycles: newState,
    },
  };
};

const deleteCampaignCycles = (
  cycle: Cycle | Cycle[],
  globalState: GlobalStateCtx,
) => {
  const memoryCycles = globalState.state.campaignCycles;
  if (!memoryCycles) {
    return globalState;
  }

  const cycles = Array.isArray(cycle) ? cycle : [cycle];
  const cyclesRemaining = memoryCycles.items.filter(
    (cycle) => !cycles.some((c) => c.cycleId === cycle.cycleId),
  );
  const deleteCount = memoryCycles.items.length - cyclesRemaining.length;
  return {
    ...globalState,
    state: {
      ...globalState.state,
      campaignCycles: {
        ...memoryCycles,
        items: cyclesRemaining,
        count: cyclesRemaining.length,
        max: memoryCycles.max - deleteCount,
      } as PaginableList<Cycle>,
    },
  };
};

const resetChatMessage = (
  { cardId }: { cardId: string },
  globalState: GlobalStateCtx,
) => {
  return {
    ...globalState,
    state: {
      ...globalState.state,
      chatMessages: {
        ...globalState.state.chatMessages,
        [cardId]: undefined,
      },
    },
  };
};

const updateChatMessage = (input: AiChatEvent, globalState: GlobalStateCtx) => {
  const memoryId = input.context.cardId as string;
  const memory = globalState.state.chatMessages[memoryId] || {
    messages: [],
    text: "",
    inError: false,
    status: "inProgress",
  };

  if (memory.status === "completed") {
    console.log(`Ignore message for completed chat memory ${memoryId}`);
    return globalState;
  }

  const newMemory: ChatMemory = {
    messages: [],
    text: "",
    inError: false,
    status: "completed",
  };

  if (input.error) {
    newMemory.inError = true;
  } else if (input.status === "completed") {
    newMemory.text = input.text;
  } else {
    newMemory.messages = memory.messages.concat([
      {
        text: input.text,
        timestamp: input.timestamp,
      },
    ]);
    newMemory.status = "inProgress";
    sortBy(newMemory.messages, "timestamp");
    newMemory.text = newMemory.messages.map((m) => m.text).join("");
  }

  return {
    ...globalState,
    state: {
      ...globalState.state,
      chatMessages: {
        ...globalState.state.chatMessages,
        [memoryId]: newMemory,
      },
    },
  };
};

const updateState = (globalState: GlobalStateCtx, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LANGUAGE: {
      return setLanguage(payload, globalState);
    }
    case SET_DEVICE_NAME: {
      localStorage.setItem("deviceName", payload);
      return { ...globalState, deviceName: payload };
    }
    case SET_PROFILE: {
      return setProfile(payload, globalState);
    }
    case SET_EMAILS: {
      return setEmails(payload, globalState);
    }
    case SET_STRATEGIES: {
      return setStrategies(payload, globalState);
    }
    case ADD_EMAIL: {
      return addOrUpdateEmail(payload, globalState);
    }
    case UPDATE_EMAIL: {
      return addOrUpdateEmail(payload, globalState);
    }
    case DELETE_EMAIL: {
      return deleteEmail(payload, globalState);
    }
    case SET_URL_ALIASES: {
      return setUrlAliases(payload, globalState);
    }
    case SET_CAMPAIGNS: {
      return setCampaigns(payload, globalState);
    }
    case SET_USER: {
      return setUser(payload, globalState);
    }
    case SET_SPHERES: {
      return setSpheres(payload, globalState);
    }
    case ADD_SPHERE: {
      return addOrUpdateSphere(payload, globalState);
    }
    case UPDATE_SPHERE: {
      return addOrUpdateSphere(payload, globalState);
    }
    case SET_SPHERE_CATEGORIES: {
      return setSphereCategories(payload, globalState);
    }
    case DELETE_SPHERE: {
      return deleteSphere(payload, globalState);
    }
    case SET_SESSIONS: {
      return setSessions(payload, globalState);
    }
    case ADD_SESSION: {
      return addOrUpdateSession(payload, globalState);
    }
    case UPDATE_SESSION: {
      return addOrUpdateSession(payload, globalState);
    }
    case DELETE_SESSION: {
      return deleteSession(payload, globalState);
    }
    case SET_MEMBERS: {
      return setMembers(payload, globalState);
    }
    case ADD_MEMBER: {
      return addOrUpdateMember(payload, globalState);
    }
    case UPDATE_MEMBER: {
      return addOrUpdateMember(payload, globalState);
    }
    case DELETE_MEMBER: {
      return deleteMember(payload, globalState);
    }
    case SET_CYCLES: {
      return setCampaignCycles(payload, globalState);
    }
    case ADD_CYCLE: {
      return addOrUpdateCampaignCycles(payload, globalState);
    }
    case UPDATE_CYCLE: {
      return addOrUpdateCampaignCycles(payload, globalState);
    }
    case DELETE_CYCLE: {
      return deleteCampaignCycles(payload, globalState);
    }
    case SET_REPORTS: {
      return setReports(payload, globalState);
    }
    case ADD_REPORT: {
      return addOrUpdateReports(payload, globalState);
    }
    case UPDATE_REPORT: {
      return addOrUpdateReports(payload, globalState);
    }
    case DELETE_REPORT: {
      return deleteReport(payload, globalState);
    }
    case SET_ABILITY: {
      return setAbility(payload, globalState);
    }
    case SET_WORDS: {
      return setWords(payload, globalState);
    }
    case ADD_FEELING: {
      return addOrUpdateFeeling(payload, globalState);
    }
    case UPDATE_FEELING: {
      return addOrUpdateFeeling(payload, globalState);
    }
    case ADD_OR_UPDATE_FEELINGS: {
      return addOrUpdateFeelings(payload, globalState);
    }
    case DELETE_FEELING: {
      return deleteFeeling(payload, globalState);
    }
    case SET_MYSELF_FEELING_REPORT: {
      return setMyselfFeelingReport(payload, globalState);
    }
    case ADD_THINKING: {
      return addOrUpdateThinking(payload, true, globalState);
    }
    case UPDATE_THINKING: {
      return addOrUpdateThinking(payload, false, globalState);
    }
    case ADD_OR_UPDATE_THINKINGS: {
      return addOrUpdateThinkings(payload, globalState);
    }
    case DELETE_THINKING: {
      return deleteThinking(payload, globalState);
    }
    case ADD_OR_UPDATE_FORM_SURVEYS: {
      return addOrUpdateFormSurveys(payload, globalState);
    }
    case ADD_OR_UPDATE_STRATEGY_ASSESSMENTS: {
      return addOrUpdateStrategyAssessments(payload, globalState);
    }
    case ADD_OR_UPDATE_NOTIFICATION: {
      return addOrUpdateNotification(payload, globalState);
    }
    case SET_THINKING_AXES: {
      return setThinkingAxes(payload, globalState);
    }
    case ADD_OR_UPDATE_THINKING_AXIS: {
      return addOrUpdateThinkingAxis(payload, globalState);
    }
    case DELETE_THINKING_AXIS: {
      return deleteThinkingAxis(payload, globalState);
    }
    case EVENT_RECEIVED: {
      if (Array.isArray(payload)) {
        const response = payload.reduce(
          (acc, data) => updateMemory(data, acc),
          globalState,
        );

        return response;
      }
      return updateMemory(payload, globalState);
    }
    case SET_CONTEXT: {
      return updateContext(payload, globalState);
    }
    case SET_SPHERE_MEMBER: {
      return setSphereMembers(payload, globalState);
    }
    case ADD_OR_UPDATE_SPHERE_MEMBER: {
      return addOrUpdateSphereMembers(payload, globalState);
    }
    case SET_PROMOTION_CODES: {
      return setPromotionCodes(payload, globalState);
    }
    case ADD_OR_UPDATE_PROMOTION_CODE: {
      return addOrUpdatePromotionCodes(payload, globalState);
    }
    case SET_SESSION_MEMBER: {
      return setSessionMembers(payload, globalState);
    }
    case SET_SPHERE_TAG: {
      return setSphereTags(payload, globalState);
    }
    case SET_SPHERE_TAGS_SELECTION: {
      return setSphereTagsSelections(payload, globalState);
    }
    case ADD_OR_UPDATE_SPHERE_TAGS_SELECTION: {
      return addOrUpdateSphereTagsSelections(payload, globalState);
    }
    case DELETE_SPHERE_TAGS_SELECTION: {
      return deleteSphereTagsSelections(payload, globalState);
    }
    case SET_SPHERE_FORM_ENTITLEMENT: {
      return setFormEntitlements(payload, globalState);
    }
    case THINKING_AXES_CHANGED: {
      return setThinkingAxesChanged(globalState);
    }
    case SET_REPORT_TAB_CHANGE: {
      return setReportTabChanged(payload, globalState);
    }
    case SET_FILTERS: {
      return setFiltersChanged(payload, globalState);
    }
    case SET_FEELING_FACETS: {
      return setFeelingFacetsChanged(payload, globalState);
    }
    case SET_GOAL_RANKINGS: {
      return setGoalRankingsChanged(payload, globalState);
    }
    case CLEAN_CHAT_MESSAGES: {
      return resetChatMessage(payload, globalState);
    }
    default:
      return globalState;
  }
};

const MY_SESSIONS_TRIGGERS = [
  SET_MEMBERS,
  UPDATE_MEMBER,
  ADD_MEMBER,
  DELETE_MEMBER,
  SET_SESSIONS,
  UPDATE_SESSION,
  ADD_SESSION,
  DELETE_SESSION,
  SET_PROFILE,
];
const MY_SESSIONS_EVENT = ["profile", "member", "sessions"];
const updateMySessions = (globalState: GlobalStateCtx, action: Action) => {
  const { type, payload } = action;
  let updateState = MY_SESSIONS_TRIGGERS.some((t) => t === type);
  if (!updateState && type === EVENT_RECEIVED) {
    const eventPayload = payload as {
      type: ResponseModel;
      data: any;
      action: EventAction;
    }[];

    updateState = MY_SESSIONS_EVENT.some((e) =>
      eventPayload.some((p) => p.type === e),
    );
  }
  if (!updateState) {
    return globalState;
  }
  return {
    ...globalState,
    state: {
      ...globalState.state,
      mySessions: getAllMySessions(globalState.state),
    },
  };
};

const globalStateReducer = (globalState: GlobalStateCtx, action: Action) => {
  let newState = updateState(globalState, action);

  newState = updateMySessions(newState, action);

  return newState;
};

export default globalStateReducer;
