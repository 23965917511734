import Button from "react-bootstrap/Button";
import styles from "./ConfirmToast.module.scss";
import { useTranslation } from "react-i18next";

export type ConfirmToastProps = {
  content: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
};

const ConfirmToast = ({ content, onConfirm, onCancel }: ConfirmToastProps) => {
  const { t } = useTranslation("i18n");

  return (
    <div className={styles.container}>
      <div className={styles.content}>{content}</div>

      <div className={styles.buttons}>
        <Button variant="danger" size="sm" onClick={onConfirm}>
          {t("common.action.confirm")}
        </Button>
        <Button variant="primary" size="sm" onClick={onCancel}>
          {t("common.action.close")}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmToast;
