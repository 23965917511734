import React from "react";
import styles from "./Notes.module.scss";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Button, Col, Container, Row } from "react-bootstrap";
import { captureException } from "@sentry/minimal";
import { Note } from "../../../@types/seen-apps";
import NoteCard from "./NoteCard";
import { DraggableIcon, NoteIcon, PersonalNoteIcon } from "../../RemixIcons";

const Notes: React.FC<{
  notes: (Note & { updated?: boolean })[];
  isPersonal?: boolean;
  disabled?: boolean;
  onChange: (notes: (Note & { updated?: boolean })[]) => void;
}> = ({ notes, disabled, onChange, isPersonal }) => {
  const { t } = useTranslation("i18n");

  const getId = () => {
    const lastId = notes?.reduce((acc, note) => Math.max(acc, note.id), 0) || 0;
    return lastId + 1;
  };

  const handleAddNote = (event: any) => {
    event.stopPropagation();

    const newNote: Note & { updated?: boolean } = {
      id: getId(),
      updated: true,
      value: "",
    };
    onChange([...notes, newNote]);
  };

  const handleDelete = (note: Note, index: number) => {
    const copy = [...notes];
    copy.splice(index, 1);
    onChange(copy);
  };

  const handleChange = (note: Note, index: number) => {
    const copy = [...notes];
    copy[index] = { ...note, updated: true };
    onChange(copy);
  };

  const handleDragEnd = (result: DropResult) => {
    if (result.destination) {
      try {
        const copy = notes.map((note, index) => ({
          ...note,
          updated: index === result.source.index ? true : note.updated,
        }));
        copy.splice(
          result.destination.index,
          0,
          copy.splice(result.source.index, 1)[0],
        );
        onChange(copy);
      } catch (error) {
        captureException(error);
        console.log(`Error during dropîng element`, error);
      }
    }
  };

  return (
    <Container className={styles.noMargin}>
      {!disabled && (
        <div className={styles.actions}>
          <Button onClick={handleAddNote}>
            {isPersonal ? <PersonalNoteIcon /> : <NoteIcon />}
            <span className={styles.addIcon}>
              {" "}
              {isPersonal
                ? t(`pages.note.personal.add`)
                : t(`pages.note.personal.add`)}
            </span>
          </Button>
        </div>
      )}
      <Row className={styles.noMargin}>
        <Col sm={12} className={styles.noMargin}>
          {notes.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable" isDropDisabled={disabled}>
                {(provided) => (
                  <div ref={provided.innerRef}>
                    {notes?.map((note, index) => (
                      <Draggable
                        key={note.id}
                        draggableId={`${note.id}`}
                        index={index}
                        isDragDisabled={disabled}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.card}
                          >
                            <div className={styles.card}>
                              {!disabled && (
                                <DraggableIcon className={styles.icon} />
                              )}
                              <NoteCard
                                variant={note.updated ? "secondary" : "primary"}
                                updated={note.updated}
                                disabled={disabled}
                                note={note}
                                onDelete={() => {
                                  handleDelete(note, index);
                                }}
                                onChange={(note) => {
                                  handleChange(note, index);
                                }}
                              />

                              {(provided as any).placeholder}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <span>{t("pages.note.personal.empty")}</span>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Notes;
