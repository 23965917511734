import React, { useEffect, useState } from "react";
import styles from "./LikertAnswerComponent.module.scss";
import {
  LickertLegend,
  LickertMeasureType,
  LikertOption,
} from "../../../@types/form-api";
import { getLegend } from "../AnswerComponent";
import Question, { THINKING_OPTIONS } from "../../question/Question";
import { getRelevantTranslationFor } from "../../../tools/multiLingualTools";

interface AnswerComponentProps {
  id: string;
  measureType: LickertMeasureType;
  value?: number;
  onChange?: (value?: any) => void;
  disabled?: boolean;
  printMode?: boolean;
}

const LikertAnswerComponent: React.FC<AnswerComponentProps> = ({
  measureType,
  value,
  onChange,
  disabled,
}) => {
  const [options, setOptions] = useState<LikertOption[]>([]);
  const [topLegends, setTopLegends] = useState<LickertLegend[]>([]);
  const [bottomLegends, setBottomLegends] = useState<LickertLegend[]>([]);

  useEffect(() => {
    //TO BE REMOVED COMPATIBILITY MODE
    if (!Array.isArray(measureType.likertOptions.options)) {
      const options: LikertOption[] = Object.keys(
        measureType.likertOptions,
      ).map((value, index) => {
        const option: {
          value: number;
          legend: string;
          color: string | undefined;
        } = (measureType.likertOptions as any)[value];

        return {
          value: option.value,
          legend: getLegend(option.legend),
          color: option.color
            ? option.color
            : (measureType as any).noColor
              ? undefined
              : THINKING_OPTIONS[index]?.color,
        };
      });
      setOptions(options);
      return;
    }
    //END TO BE REMOVED COMPATIBILITY MODE

    setOptions(
      measureType.likertOptions.options.map((option) => ({
        ...option,
        legend: getLegend(option.legend),
      })),
    );
  }, [measureType, measureType.likertOptions]);

  useEffect(() => {
    if (measureType.likertOptions.legends) {
      setTopLegends(
        measureType.likertOptions.legends.filter(
          (legend) => legend.vertical === "top",
        ),
      );
      setBottomLegends(
        measureType.likertOptions.legends.filter(
          (legend) => legend.vertical === "bottom",
        ),
      );
    }
  }, [measureType.likertOptions.legends]);

  const handleValueChange = (value: number) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={styles.likert}>
      {!!topLegends.length && (
        <div className={styles.topLegends}>
          {topLegends.map((legend, index) => (
            <div key={index} className={styles[legend.horizontal]}>
              {getRelevantTranslationFor(legend.legend)}
            </div>
          ))}
        </div>
      )}
      <Question
        disabled={disabled}
        onValueChange={handleValueChange}
        options={options}
        value={value}
        display={measureType.likertOptions.display || "auto"}
      />
      {!!bottomLegends.length && (
        <div className={styles.bottomLegends}>
          {bottomLegends.map((legend, index) => (
            <div key={index} className={styles[legend.horizontal]}>
              {getRelevantTranslationFor(legend.legend)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LikertAnswerComponent;
