import React, { memo } from "react";
import styles from "./RoundedButton.module.scss";
import { Button, ButtonProps } from "react-bootstrap";

const RoundedButton: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, variant = "secondary", ...props }) => {
  return (
    <Button
      {...props}
      variant={variant}
      className={`${className} ${styles.root}`}
    >
      {children}
    </Button>
  );
};

export default memo(RoundedButton);
