import React, { useEffect, useState, useContext } from "react";
import styles from "./SessionAdminPage.module.scss";
import selectStyles from "../../styles/react-select.module.scss";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { Session, SessionPut, SessionResponse } from "../../@types/session-api";
import api from "../../api/Api";
import { getCurrentLanguage } from "../../i18n";
import { MultiLingual } from "../../@types/sphere-api";
import { withSessionAccess } from "../../tools/withSessionAccess";
import LoadingPage from "../loading/LoadingPage";
import { UPDATE_SESSION } from "../../context/globalState/globalStateReducer";
import MultiLingualInput from "../../components/MulitLingual/MultiLingualInput";
import {
  clearEmptyLanguage,
  getRelevantTranslationFor,
} from "../../tools/multiLingualTools";
import SelectParentSessionInput from "../../components/session/SelectParentSessionInput";
import SessionTags from "../../components/session/SessionTags";
import { captureException } from "@sentry/minimal";
import { ShieldKeyholeLineIcon } from "../../components/RemixIcons";
import { useAbility } from "@casl/react";
import AbilityContext from "../../context/AbilityContext";
import TagsInput from "../../components/input/TagsInput";
import GuestPreview from "./GuestPreview";
import AdvancedForm from "../../components/forms/AdvancedForm";
import Select from "react-select";
import { Subscription } from "../../@types/member-api";
import { getErrorTranslation } from "../../tools/errorTools";
import { OptionsType } from "../../@types/webapp-api";

type OptionType = {
  value: string;
  label: string;
};

const dicoPrefix = "pages.sphere.sessions.settings";

export const getNumberValue = (value?: string) => {
  if (!value) {
    return 0;
  }
  let stepNumberValue = 0;
  try {
    stepNumberValue = Number.parseInt(value, 10);
  } catch {
    // ignored
  }
  if (isNaN(stepNumberValue)) {
    stepNumberValue = 0;
  }

  return stepNumberValue;
};

export const MAX_STEP_NUMBER = 99;

const getSpecificTags = (
  session: Session,
): { value: string; label: string }[] => {
  return session.tags
    .filter((t) => session.specificTagIds?.some((id) => id === t.id))
    .map((t) => ({ value: t.id, label: getRelevantTranslationFor(t.name) }));
};

export const fetchTags = async ({
  sphereId,
  search,
  filters,
}: {
  sphereId: string;
  search?: string;
  filters?: string[];
}) => {
  const response = await api.getSphereTags({
    sphereId,
    max: 200,
    searchQuery: search,
    filters: [...(filters || []), `archived=false`],
    offset: 0,
  });
  const tags = response.items.map((tag) => {
    let label = getRelevantTranslationFor(tag.name);
    if (tag.name.default && label !== tag.name.default) {
      label = `${label} - ${tag.name.default}`;
    }

    return {
      label,
      value: tag.id,
      source: tag,
    };
  });
  return tags;
};

const SessionAdminPage: React.FC<{
  session: Session;
}> = ({ session: sessionLight }) => {
  const { dispatch } = useContext(GlobalStateContext);

  const { t } = useTranslation("i18n");
  const [saving, setSaving] = useState<boolean>(false);
  const ability = useAbility(AbilityContext);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    setIsSuperAdmin(ability.can("manage", "sphere"));
  }, [ability]);

  const [loading, setLoading] = useState<boolean>();
  const [sessionDetailled, setSessionDetailled] = useState<Session>();
  const [currentLang, setCurrentLang] = useState<string>("");
  const [specificTags, setSpecificTags] = useState<
    { value: string; label: string }[]
  >([]);
  const [name, setName] = useState<MultiLingual>();
  const [publicName, setPublicName] = useState<MultiLingual>();
  const [subscriptionOptions] = useState<OptionsType<OptionType>>([
    {
      value: "starter",
      label: `e-Nous ${t(`common.subscription.starter`)}`,
    },
    {
      value: "essentials",
      label: `e-Nous ${t(`common.subscription.essentials`)}`,
    },
  ]);
  const [subscription, setSubscription] = useState<OptionType>();
  const [isLeaf, setIsLeaf] = useState<boolean>();
  const [isSalesUp, setIsSalesUp] = useState(false);
  const [stepNumber, setStepNumber] = useState<string>();
  const [childNumber, setChildNumber] = useState<string>();
  const [parent, setParent] = useState<Session>();
  const [defaultParentSessionId, setDefaultParentSessionId] =
    useState<string>();

  const handleResetSession = () => {
    if (sessionDetailled) {
      setSpecificTags(getSpecificTags(sessionDetailled));
      setName(sessionDetailled.name);
      setPublicName(sessionDetailled.publicName);
      setIsLeaf(sessionDetailled.isLeaf || false);
      setStepNumber(`${sessionDetailled.stepNumber || 0}`);
      setSubscription(
        subscriptionOptions.find(
          (o) => o.value === sessionDetailled.subscription,
        ) || subscriptionOptions[0],
      );
    }
  };

  const handleStepNumberChange = (value: string) => {
    const stepNumber = getNumberValue(value);

    setStepNumber(stepNumber > MAX_STEP_NUMBER ? `${MAX_STEP_NUMBER}` : value);
  };

  const handleSaveSession = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (
      !form.checkValidity() ||
      !sessionDetailled ||
      !name?.default ||
      (isSalesUp && !publicName?.default)
    ) {
      return;
    }
    setSaving(true);

    const nameCleaned = clearEmptyLanguage(name);
    const publicNameCleaned = isSalesUp
      ? clearEmptyLanguage(publicName)
      : clearEmptyLanguage(name);

    const sessionToUpdate: Partial<SessionPut> = {
      specificTagIds: specificTags.map((t) => t.value),
      name: nameCleaned,
      publicName: publicNameCleaned,
      isLeaf,
      parentSessionId: parent?.id || null,
      childNumber: childNumber ? Number.parseInt(childNumber) : undefined,
      stepNumber:
        sessionDetailled.category === "consumer"
          ? getNumberValue(stepNumber)
          : 0,
      subscription: subscription?.value as Subscription | undefined,
    };
    try {
      const sessionUpdated = await api.saveSession(
        sessionDetailled.id,
        sessionToUpdate,
      );
      toast.success(
        t(`${dicoPrefix}.${sessionDetailled.category}.success`, {
          name: getRelevantTranslationFor(sessionToUpdate.name),
        }),
      );
      setSessionDetailled(sessionUpdated);
      dispatch({
        type: UPDATE_SESSION,
        payload: sessionUpdated,
      });
    } catch (error: any) {
      captureException(error);

      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey: `${dicoPrefix}.${sessionLight.category}.error`,
        }),
      );
    }
    setSaving(false);
  };

  const handleTagSelected = (tagSelected: any) => {
    setSpecificTags(tagSelected || []);
  };

  useEffect(() => {
    const loadSession = async () => {
      if (loading) {
        return;
      }
      setLoading(true);
      let sessionDetailled: SessionResponse | undefined = undefined;
      try {
        sessionDetailled = await api.getSession(sessionLight.id);
        setSessionDetailled(sessionDetailled);
        if (
          sessionDetailled.parentSessionIds &&
          sessionDetailled.parentSessionIds.length > 0
        ) {
          setDefaultParentSessionId(
            sessionDetailled.parentSessionIds[
              sessionDetailled.parentSessionIds.length - 1
            ],
          );
        } else {
          setDefaultParentSessionId(undefined);
        }
      } catch (error) {
        captureException(error);
      }
      if (!sessionDetailled) {
        toast.error(t("pages.session.error.get"));
        return;
      }
      setSpecificTags(getSpecificTags(sessionDetailled));
      setName(sessionDetailled.name);
      setPublicName(sessionDetailled.publicName);
      setIsLeaf(sessionDetailled.isLeaf || false);
      setStepNumber(`${sessionDetailled.stepNumber || 0}`);
      setChildNumber(`${sessionDetailled.childNumber}`);
      setLoading(false);
      setIsSalesUp(sessionDetailled.category === "consumer");
      setSubscription(
        subscriptionOptions.find(
          (o) => o.value === sessionDetailled?.subscription,
        ) || subscriptionOptions[0],
      );
    };
    loadSession();

    setCurrentLang(getCurrentLanguage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang, sessionLight, t]);

  return (
    <Container className={styles.root}>
      {loading && <LoadingPage />}

      {!loading && sessionDetailled && (
        <>
          <Container>
            <Form onSubmit={handleSaveSession} autoComplete="off" noValidate>
              <Row>
                <Col sm={6}>
                  <Form.Group className="form-group" controlId="tags">
                    <Form.Label>
                      {t(
                        `pages.sphere.sessions.${sessionDetailled.category}.parent`,
                      )}
                    </Form.Label>
                    <SelectParentSessionInput
                      sphereId={sessionDetailled.sphere.id}
                      defaultValue={defaultParentSessionId}
                      excludeSessionId={sessionDetailled.id}
                      value={parent}
                      onChange={setParent}
                      category={sessionDetailled.category}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className={styles.noMargin}>
                <Col sm={6} className={styles.noMargin}>
                  <MultiLingualInput
                    required
                    label={t(
                      `pages.sphere.sessions.${sessionDetailled.category}.name`,
                    )}
                    value={name}
                    onChange={(value) => setName(value)}
                  />
                </Col>
              </Row>
              {isSalesUp && (
                <>
                  <Row className={styles.noMargin}>
                    <Col
                      sm={6}
                      className={`${styles.noMargin} ${styles.relative}`}
                    >
                      <MultiLingualInput
                        required
                        label={t(
                          `pages.sphere.sessions.${sessionDetailled.category}.publicName`,
                        )}
                        value={publicName}
                        onChange={(value) => setPublicName(value)}
                      />
                      <GuestPreview
                        className={styles.preview}
                        label={t(
                          `pages.sphere.sessions.${sessionDetailled.category}.preview`,
                        )}
                        previewLabel={publicName}
                      />
                    </Col>
                  </Row>
                  <Row className={styles.noMargin}>
                    <Col sm={12} className={styles.noMargin}></Col>
                  </Row>
                </>
              )}
              {!isSalesUp && (
                <Row>
                  <Col sm={6}>
                    <Form.Group className="form-group" controlId="subscription">
                      <Form.Label>
                        {t(
                          `pages.sphere.sessions.${sessionDetailled.category}.license`,
                        )}{" "}
                        *
                      </Form.Label>
                      <div className={selectStyles.reactSelect}>
                        <Select
                          options={subscriptionOptions}
                          defaultValue={subscription}
                          value={subscription}
                          onChange={(value) =>
                            setSubscription(value || subscriptionOptions[0])
                          }
                          isSearchable={false}
                          isClearable={false}
                          isMulti={false}
                          className={selectStyles.reactSelect}
                          classNamePrefix="custom-react-select"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row className={styles.noMargin}>
                <Col sm={6} className={styles.noMargin}>
                  <Form.Group className="form-group" controlId="tags">
                    <Form.Label>{t(`pages.sphere.sessions.tags`)}</Form.Label>
                    <TagsInput
                      sphereId={sessionDetailled.sphere.id}
                      onChange={(items) => {
                        handleTagSelected(items);
                      }}
                      tagsSelected={specificTags}
                      module={`session-${sessionDetailled.category}`}
                      defaultValueTagWhenCreation={{
                        name,
                        modules: [`session-${sessionDetailled.category}`],
                      }}
                    />

                    <div className={styles.inherited}>
                      <div className={styles.inheritedTitle}>
                        {t("pages.sphere.sessions.inherited")}
                      </div>
                      <SessionTags
                        className={styles.inheritedTags}
                        session={parent}
                        variant="secondary"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <AdvancedForm defaultStatus="closed">
                <Container className={styles.noMargin}>
                  {sessionDetailled.category === "consumer" && (
                    <Row className={styles.noMargin}>
                      <Col sm={6} className={styles.noMargin}>
                        <Form.Group
                          className="form-group"
                          controlId="stepNumber"
                        >
                          <Form.Label>
                            {t(`${dicoPrefix}.stepNumber`)}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            maxLength={2}
                            value={stepNumber}
                            onChange={(event) =>
                              handleStepNumberChange(event.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row className={styles.noMargin}>
                    <Col sm={6} className={styles.noMargin}>
                      <Form.Group className="form-group" controlId="isLeaf">
                        <Form.Check
                          type="switch"
                          id="nameAutoFill"
                          name="nameAutoFill"
                          label={t(
                            `pages.sphere.sessions.${sessionDetailled.category}.isLeaf`,
                          )}
                          checked={isLeaf}
                          onChange={(event: any) => {
                            setIsLeaf(event.target.checked);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {isSuperAdmin && (
                    <Row className={styles.noMargin}>
                      <Col sm={6} className={`${styles.noMargin}`}>
                        <Form.Group
                          className="form-group"
                          controlId="childNumber"
                        >
                          <Form.Label>
                            <ShieldKeyholeLineIcon />
                            {t(
                              `pages.sphere.sessions.${sessionDetailled.category}.childNumber`,
                            )}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            maxLength={2}
                            value={childNumber}
                            onChange={(event) =>
                              setChildNumber(event.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Container>
              </AdvancedForm>
              <hr />

              <Row className={styles.noMargin}>
                <Col sm={12} className={`${styles.noMargin} ${styles.buttons}`}>
                  <Button
                    variant="secondary"
                    className={styles.right}
                    onClick={handleResetSession}
                  >
                    {t("common.action.reset")}
                  </Button>
                  <Button name="submit" type="submit">
                    {saving && (
                      <div className={styles.submitContent}>
                        <ReactLoading
                          type={"bars"}
                          color={"#DDD"}
                          height={"30px"}
                          width={"40px"}
                        />
                        <div className={styles.submitText}>
                          {t("common.action.saving")}
                        </div>
                      </div>
                    )}
                    {!saving && t("common.action.save")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      )}
    </Container>
  );
};

export default withSessionAccess(SessionAdminPage, {
  onlyOwner: true,
});
