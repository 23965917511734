import { useState } from "react";
import { Modal } from "react-bootstrap";

import styles from "./GuestPreview.module.scss";
import { MultiLingual } from "../../@types/sphere-api";
import GuestLoginHeader from "../../components/Auth/GuestLoginHeader";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";

type Props = {
  className?: string;
  label: string;
  previewLabel: string | MultiLingual | undefined;
};

const GuestPreview = ({ label, previewLabel, className }: Props) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className={className}>
        <a className={styles.link} href="#" onClick={handleClick}>
          {label}
        </a>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        style={{
          zIndex: 999999,
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{label}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            position: "relative",
            display: "table",
            overflowY: "auto",
            overflowX: "auto",
            width: "auto",
            minWidth: "300px",
          }}
        >
          <GuestLoginHeader
            previewMode
            providerName={getRelevantTranslationFor(previewLabel)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default GuestPreview;
