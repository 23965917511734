import { FC, useEffect, useState } from "react";
import { feelingCategory } from "../../@types/webapp-api";
import FeelingConsumerPage from "./FeelingConsumerPage/FeelingConsumerPage";
import FeelingContributorPage from "./FeelingContributorPage/FeelingContributorPage";

type Props = {
  memberCategory?: feelingCategory;
};

const FeelingPage: FC<Props> = ({
  memberCategory = "contributor",
  ...otherProps
}) => {
  const [disableThinkingCreation, setDisableThinkingCreation] =
    useState<boolean>(false);

  useEffect(() => {
    const initialize = async () => {
      setDisableThinkingCreation(memberCategory !== "contributor");
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {memberCategory === "contributor" ? (
        <FeelingContributorPage
          {...otherProps}
          disableThinkingCreation={disableThinkingCreation}
        />
      ) : (
        <FeelingConsumerPage
          {...otherProps}
          disableThinkingCreation={disableThinkingCreation}
        />
      )}
    </>
  );
};

export default FeelingPage;
