import { matchPath } from "react-router";
import { routes } from "../AppRoutes";
import { Location } from "react-router";

export const getUrlParams = <T extends Record<string, string | undefined>>(
  url: string,
) => {
  const errors: any[] = [];

  const response = routes.reduce<T>((accumulator, route) => {
    try {
      const match = matchPath(route.path || "", url);
      return {
        ...accumulator,
        ...match?.params,
      };
    } catch (error) {
      errors.push({
        error: `${error}`,
        path: route.path,
        url,
      });
      return accumulator;
    }
  }, {} as T);
  if (errors.length > 0) {
    alert(JSON?.stringify(errors));
  }
  return response;
};

export const getUrlQueryString = (searchQuery: string) => {
  try {
    const qs = new URLSearchParams(searchQuery);
    const response: Record<string, string> = {};
    qs.forEach((value: string, key: string) => {
      response[key] = value;
    });
    return response;
  } catch (error) {
    console.error("Error parsing URL query string:", error);
    alert(`getUrlQueryString: ${searchQuery}, error: ${error}`);
    return {};
  }
};

export const extractLocationData = (location: Location<unknown>) => {
  return {
    params: getUrlParams(location?.pathname),
    queryString: getUrlQueryString(location?.search),
  };
};

export const getDocumentExtension = (path: string): string => {
  // clean all querystring
  const cleanUrl = path.split(/[#?]/)[0];

  // retrieve the last filename
  const lastSegment = cleanUrl.split("/").pop();
  if (!lastSegment) {
    return ""; // No extension found
  }
  //Find the extension
  const lastDotIndex = lastSegment.lastIndexOf(".");
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return ""; // No extension found
  }
  const extension = lastSegment.slice(lastDotIndex + 1);
  return extension;
};
