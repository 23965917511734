import { Button, ButtonGroup, Overlay, Popover } from "react-bootstrap";
import { SwatchesPicker } from "react-color";
import styles from "./ColorInput.module.scss";
import { useEffect, useRef, useState } from "react";

interface Props {
  onChange: (value: string | undefined) => void;
  value: string | undefined;
  className?: string;
}

const ColorInput = ({ value, onChange }: Props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleOnChange = (color: any) => {
    setShow(false);
    if (onChange) {
      onChange(color?.hex);
    }
  };
  const handleClear = () => {
    setShow(false);
    if (onChange) {
      onChange(undefined);
    }
  };

  useEffect(() => {
    const handleClick = () => {
      if (ref.current) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return (
    <div ref={ref}>
      <ButtonGroup className={styles.group}>
        <Button
          variant="secondary"
          className={`${styles.button} ${styles.input}`}
          onClick={handleClick}
        >
          <div
            className={styles.color}
            style={{ backgroundColor: value || "unset" }}
          ></div>
        </Button>
        <Button
          className={styles.button}
          variant="secondary"
          onClick={handleClear}
        >
          x
        </Button>
      </ButtonGroup>

      <Overlay
        show={show}
        target={target}
        placement="right"
        container={ref.current}
        containerPadding={20}
      >
        <Popover id="popover-contained" className={styles.popover}>
          <Popover.Body>
            <SwatchesPicker
              className={styles.selection}
              onChange={handleOnChange}
              width={255}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default ColorInput;
