import React from "react";
import styles from "./DropDownContexts.module.scss";

type Props = {
  style?: React.CSSProperties;
  className?: string;
};

const DropDownMenu = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function DropDownMenuWithRef({ children, style, className }, ref) {
  return (
    <div ref={ref} style={style} className={className}>
      <ul className={styles.listContexts}>{children}</ul>
    </div>
  );
});

export default DropDownMenu;
