import React from "react";
import styles from "./QrScanner.module.scss";
import { useZxing } from "react-zxing";

interface QrScannerProps {
  onScan: (data: string) => void;
}

const QrScanner: React.FC<QrScannerProps> = ({ onScan }) => {
  const { ref } = useZxing({
    onDecodeResult(result) {
      handleScan(result.getText());
    },
  });

  const handleScan = (data: string | null) => {
    if (data) {
      onScan(data);
    }
  };

  return (
    <div className={styles.container}>
      <video
        className={styles.scanner}
        ref={ref as React.RefObject<HTMLVideoElement>}
      />
    </div>
  );
};

export default QrScanner;
