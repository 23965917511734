import { FC } from "react";
import {
  SalesUpGroupIcon,
  TagGroupIcon,
  TagMomentIcon,
  TagPeriodIcon,
} from "../RemixIcons";
import { LightSession } from "../../@types/form-api";
import { Category, Session, SessionType } from "../../@types/session-api";

type Props = {
  session: Omit<
    LightSession | Session | { type: SessionType; category: Category },
    "sphere"
  >;
  className?: string;
  color?: string;
};

const SessionIcon: FC<Props> = ({ session, className, color }) => {
  return session.type === "group" && session.category === "consumer" ? (
    <SalesUpGroupIcon color={color} className={className} />
  ) : session.type === "group" ? (
    <TagGroupIcon className={className} color={color} />
  ) : session.type === "period" ? (
    <TagPeriodIcon className={className} color={color} />
  ) : (
    <TagMomentIcon className={className} color={color} />
  );
};

export default SessionIcon;
