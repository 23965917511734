import { GlobalState, MoodMeter, Color } from "../@types/seen-apps";
import { ReportItem } from "../@types/webapp-api";

export const getWordTranslated = (
  globalState: GlobalState,
  wordId: string,
): string => {
  const locale = globalState.profile ? globalState.profile.locale : "fr";
  const word = globalState.definition.feelingWords.find(
    (w) => w.word.id === wordId,
  );

  return word ? word.word.value[locale] : wordId;
};

const getColor = (feeling: {
  color?: Color;
  value: number;
  energy: number;
}): Color | undefined => {
  if (feeling.color) {
    return feeling.color;
  }
  if (!feeling.value || !feeling.energy) {
    return undefined;
  }

  if (feeling.value > 0 && feeling.energy > 0.5) {
    return "yellow";
  }
  if (feeling.value > 0) {
    return "green";
  }
  if (feeling.value < 0 && feeling.energy > 0.5) {
    return "red";
  }
  return "blue";
};

export const convertReportToMoodMeter = (items: ReportItem[]): MoodMeter => {
  let result: MoodMeter = {};
  result = (items || []).reduce((acc, mood) => {
    if (!mood.value) {
      return acc;
    }

    return {
      ...acc,
      [mood.value]: {
        active: !!mood.count,
        count: mood.count,
      },
    };
  }, result);
  return result;
};

export const convertToMoodMeter = (
  feelings: {
    value: number;
    energy: number;
  }[],
): MoodMeter => {
  let result: MoodMeter = {};
  result = (feelings || []).reduce<MoodMeter>((acc, feeling) => {
    const color = getColor(feeling);

    if (!color) {
      return acc;
    }

    const value = acc[color];
    if (!value) {
      return {
        ...acc,
        [color]: {
          active: true,
          count: 1,
        },
      };
    }
    return {
      ...acc,
      [color]: { ...value, count: (value.count || 0) + 1 },
    };
  }, result);
  return result;
};
