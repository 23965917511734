import React, { useEffect, useState } from "react";
import styles from "./ChoiceAnswerComponent.module.scss";
import { ChoiceMeasureType } from "../../../@types/form-api";
import { Badge, Form } from "react-bootstrap";
import AutoHeightTextarea from "../../input/AutoHeightTextarea";
import { getRelevantTranslationFor } from "../../../tools/multiLingualTools";
import { uniq } from "lodash";
import { OptionsType } from "../../../@types/webapp-api";

interface AnswerComponentProps {
  id: string;
  measureType: ChoiceMeasureType;
  value?: string;
  onChange?: (value?: any) => void;
  disabled?: boolean;
  printMode?: boolean;
}

type OptionType = {
  value: string;
  label: string;
};

const ChoiceAnswerComponent: React.FC<AnswerComponentProps> = ({
  measureType,
  id,
  value,
  onChange,
  disabled,
  printMode,
}) => {
  const [options, setOptions] = useState<OptionsType>();
  const [values, setValues] = useState<OptionsType>();
  const [isMaxReached, setMaxReached] = useState<boolean>(false);

  useEffect(() => {
    const options = measureType.choiceOptions.values.map((val) => ({
      label: getRelevantTranslationFor(val.title),
      value: val.value,
    }));
    setOptions(options);

    const values = (value || "").split(";");
    setValues(
      options.filter((option) => values.find((val) => option.value === val)),
    );
  }, [measureType.choiceOptions.values, value]);

  useEffect(() => {
    const maxReached =
      measureType.choiceOptions.multiChoiceMax &&
      values &&
      values.length >= measureType.choiceOptions.multiChoiceMax;
    setMaxReached(maxReached || false);
  }, [measureType.choiceOptions.multiChoiceMax, values]);

  const handleValueChange = (value: OptionType, checked: boolean) => {
    let newValues = values?.map((val) => val.value) || [];
    if (measureType.choiceOptions.multiChoice && checked) {
      newValues.push(value.value);
    } else if (measureType.choiceOptions.multiChoice) {
      newValues = newValues.filter((val) => val !== value.value);
    } else {
      newValues = checked ? [value.value] : [];
    }

    const data = uniq(newValues).join(";");
    if (onChange) {
      onChange(data);
    }
  };

  if (printMode) {
    return measureType.choiceOptions.multiChoice ? (
      <div className={styles.selectPrint}>
        {(values || []).map((value) => (
          <Badge key={`select-response-${value.value}`}>{value.label}</Badge>
        ))}
      </div>
    ) : (
      <div className={styles.text}>
        <AutoHeightTextarea
          className={`${styles.description} ${disabled ? styles.disabled : ""}`}
          key={`selected-${id}`}
          rows={1}
          value={values?.length ? values[0].label : ""}
          onChange={() => {}}
          disabled
          singleLine
        />
      </div>
    );
  }

  return (
    <div className={styles.btnGroupContainer}>
      {measureType.choiceOptions.multiChoice ? (
        <div className={styles.checkboxContainer}>
          {options?.map((option) => {
            const isChecked = !!values?.some(
              (val) => val.value === option.value,
            );
            const isDisabled = disabled || (isMaxReached && !isChecked);
            return (
              <Form.Check
                key={option.value}
                inline
                label={
                  <div
                    onClick={
                      isDisabled
                        ? undefined
                        : () => handleValueChange(option, !isChecked)
                    }
                  >
                    {option.label}
                  </div>
                }
                disabled={isDisabled}
                name={id}
                type="checkbox"
                checked={isChecked}
                onChange={() => handleValueChange(option, !isChecked)}
              />
            );
          })}
        </div>
      ) : (
        <div className={styles.radioContainer}>
          <div aria-label={id} className={styles.radio}>
            {options?.map((option) => {
              const isChecked = !!values?.some(
                (val) => val.value === option.value,
              );
              return (
                <Form.Check
                  key={option.value}
                  type="radio"
                  name={id}
                  label={option.label}
                  checked={isChecked}
                  onChange={() => handleValueChange(option, true)}
                  disabled={disabled}
                />
              );
            })}
          </div>
        </div>
      )}

      {!printMode && measureType.choiceOptions.multiChoiceMax && (
        <div className={styles.condition}>
          <Badge bg="dark">
            {values?.length || 0}/{measureType.choiceOptions.multiChoiceMax}
          </Badge>
        </div>
      )}
    </div>
  );
};

export default ChoiceAnswerComponent;
