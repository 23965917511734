import React from "react";
import styles from "./StrategiesSelection.module.scss";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Col, Container, Row } from "react-bootstrap";
import { captureException } from "@sentry/minimal";
import { Strategy } from "../../@types/strategy-api";
import StrategyCard from "./StrategyCard";
import RoundedButton from "../RoundedButton/RoundedButton";
import { DraggableIcon, EditIcon } from "../RemixIcons";

const StrategiesSelection: React.FC<{
  dicoPrefix: string;
  strategies: (Strategy & { updated?: boolean })[];
  disabled?: boolean;
  onChange: (strategies: (Strategy & { updated?: boolean })[]) => void;
  onStrategyClicked: (strategy: Strategy) => void;
}> = ({ strategies, disabled, onChange, dicoPrefix, onStrategyClicked }) => {
  const { t } = useTranslation("i18n");

  const handleChange = (strategy: Strategy) => {
    const result = strategies.map((s) => (s.id === strategy.id ? strategy : s));
    onChange(result);
  };

  const handleDragEnd = (result: DropResult) => {
    if (result.destination) {
      try {
        const copy = [...strategies];
        copy.splice(
          result.destination.index,
          0,
          copy.splice(result.source.index, 1)[0],
        );
        onChange(copy);
      } catch (error) {
        captureException(error);
        console.log(`Error during droping element`, error);
      }
    }
  };

  return (
    <Container className={styles.noMargin}>
      <Row className={styles.noMargin}>
        <Col sm={12} className={styles.noMargin}>
          {strategies.length === 0 && <span>{t(`${dicoPrefix}.empty`)}</span>}
          {strategies.length > 0 && (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable" isDropDisabled={disabled}>
                {(provided) => (
                  <div ref={provided.innerRef}>
                    {strategies?.map((strategy, index) => (
                      <Draggable
                        key={strategy.id}
                        draggableId={strategy.id}
                        index={index}
                        isDragDisabled={disabled}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.card}
                          >
                            <div
                              className={styles.card}
                              style={{
                                paddingLeft: `${(strategy.indent || 0) * 25}px`,
                              }}
                            >
                              {!disabled && (
                                <DraggableIcon className={styles.icon} />
                              )}
                              <StrategyCard
                                dicoPrefix={dicoPrefix}
                                actionIcon={
                                  disabled ? (
                                    <></>
                                  ) : (
                                    <RoundedButton
                                      onClick={() =>
                                        onStrategyClicked(strategy)
                                      }
                                    >
                                      <EditIcon />
                                    </RoundedButton>
                                  )
                                }
                                disabled={disabled}
                                strategy={strategy}
                                onChange={handleChange}
                              />

                              {(provided as any).placeholder}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default StrategiesSelection;
