const config = {
  development: {
    Env: "local",
    Auth: {
      Cognito: {
        region: "eu-west-1",
        userPoolId: "eu-west-1_b0aeTeD6g",
        userPoolClientId: "21pcgl1hj38g0ned1u03ma3jeh",
      },
    },
    whiteList: ["emotion.vip", "snapps.link", "vax.place", "app.seen-apps.com"],
    shortenUrl: "https://emotion.vip",
    API: {
      REST: {
        seenapps: {
          endpoint: "https://app.seen-apps.com",
          region: "eu-west-1",
        },
      },
    },
  },
  //used to deploy dev environment
  production: {
    Env: "production",
    Auth: {
      Cognito: {
        region: "eu-west-1",
        userPoolId: "eu-west-1_b0aeTeD6g",
        userPoolClientId: "21pcgl1hj38g0ned1u03ma3jeh",
      },
    },
    whiteList: ["emotion.vip", "snapps.link", "vax.place", "app.seen-apps.com"],
    shortenUrl: "https://emotion.vip",
    API: {
      REST: {
        seenapps: {
          endpoint: "https://app.seen-apps.com",
          region: "eu-west-1",
        },
      },
    },
  },
};

// eslint-disable-next-line no-undef
export default config[process.env.NODE_ENV];
