/* eslint react-hooks/exhaustive-deps: 0 */
import { FC } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StudyProgramIcon } from "../../components/RemixIcons";
import { Sphere } from "../../@types/sphere-api";

type Props = {
  sphere?: Sphere;
};

const FeelingReminder: FC<Props> = ({ sphere }) => {
  const { t } = useTranslation("i18n");
  return (
    <div style={{ marginTop: "30px" }}>
      {sphere && sphere.isStudyProgram ? (
        <Alert key={"warning"} variant="warning">
          <StudyProgramIcon />
          <div
            dangerouslySetInnerHTML={{
              __html: t("pages.feeling.linkToSphereStudyProgramInfo"),
            }}
          />
        </Alert>
      ) : (
        <Alert key={"info"} variant="info">
          <div
            dangerouslySetInnerHTML={{
              __html: t("pages.feeling.linkToSphereInfo"),
            }}
          />
        </Alert>
      )}
    </div>
  );
};

export default FeelingReminder;
