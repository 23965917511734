import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./IEModal.module.scss";
import { useTranslation } from "react-i18next";
import MarkdownComponent from "../Markdown/MarkdownComponent";
import { Color } from "../../@types/seen-apps";
import { getSVG } from "../feeling/MoodMeterPolygon";

type Props = {
  show: boolean;
  defaultValue?: Color | string;
  onClose: () => void;
};

const COLORS = ["yellow", "green", "blue", "red"];
const THEME = ["advantages", "disadvantages", "causes", "needs"];

const IEModal: FC<Props> = ({ show, onClose, defaultValue }) => {
  const { t } = useTranslation("i18n");
  const [color, setColor] = useState(
    defaultValue === "multi" ? undefined : defaultValue,
  );
  const [image, setImage] = useState<JSX.Element>();
  const [text, setText] = useState<Record<string, string>>({});

  const reference = useRef(null);

  useEffect(() => {
    const image = getSVG({
      width: 100,
      height: 100,
      showNumber: false,
      moodMetre: {
        red: { active: color === "red" || color === undefined },
        blue: { active: color === "blue" || color === undefined },
        green: { active: color === "green" || color === undefined },
        yellow: { active: color === "yellow" || color === undefined },
      },
      size: "big",
      onlySVG: true,
    });

    setImage(image);

    setText(
      THEME.reduce(
        (acc, theme) => {
          return { ...acc, [theme]: t(`common.ie.colors.${color}.${theme}`) };
        },
        {} as Record<string, string>,
      ),
    );
  }, [color, t]);

  useEffect(() => {
    const scroll = async () => {
      await 200;
      const element: any = reference?.current;
      if (element?.scrollIntoView) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };
    scroll();
  }, [text]);

  const handleClose = () => {
    onClose();
  };
  const handleChangeColor = (color: string) => {
    setColor(color);
  };

  return (
    <Modal
      size="sm"
      show={show}
      onHide={handleClose}
      style={{
        zIndex: 2100,
      }}
      className={styles.modal}
    >
      <Modal.Header closeButton>
        <Modal.Title ref={reference}>
          {color
            ? t(`common.ie.colors.${color}.title`)
            : t(`common.ie.quadrant`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.image}>{image}</div>

        <div className={styles.content}>
          {color === undefined ? (
            <></>
          ) : (
            THEME.map((theme, index) => (
              <Fragment key={index}>
                <h4>{t(`common.ie.${theme}`)}</h4>
                <MarkdownComponent key={`show-${theme}`} text={text[theme]} />
              </Fragment>
            ))
          )}
        </div>
        <div className={styles.actions}>
          {COLORS.map((c) => (
            <Button
              disabled={c === color}
              key={`show-${c}`}
              onClick={() => handleChangeColor(c)}
            >
              {t(`common.ie.colors.${c}.title`)}
            </Button>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IEModal;
