let superAdminModeEnabled =
  window.localStorage.getItem("superAdminMode") === "true" || false;

export const switchSuperAdminMode = () => {
  superAdminModeEnabled = !superAdminModeEnabled;
  window.localStorage.setItem("superAdminMode", `${superAdminModeEnabled}`);
};

export const isSuperAdminModeEnabled = () => {
  return superAdminModeEnabled;
};
