import { isString, uniq } from "lodash";
import { MultiLingual } from "../@types/sphere-api";
import { getCurrentLanguage } from "../i18n";

export const getRelevantTranslationFor = (
  multiligual: MultiLingual | undefined | string | null,
  forceLang?: string,
): string => {
  if (!multiligual) {
    return "";
  }
  if (isString(multiligual)) {
    return multiligual;
  }
  if (typeof multiligual === "string") {
    return multiligual;
  }
  if (Array.isArray(multiligual)) {
    return multiligual.join(", ");
  }

  const lang = forceLang || getCurrentLanguage();
  const translate = multiligual[lang] || multiligual.default;

  if (translate) {
    return translate;
  }

  return Object.keys(multiligual).reduce((acc, lang) => {
    if (acc) {
      return acc;
    }
    return multiligual[lang];
  }, "");
};

export const clearEmptyLanguage = (value?: MultiLingual): MultiLingual => {
  if (!value) {
    return {};
  }

  const response = Object.keys(value).reduce((acc, lang) => {
    const data = value[lang];
    if (!data) {
      return acc;
    }
    return { ...acc, [lang]: data };
  }, {} as MultiLingual);

  return response;
};

export const mergeMultiLingual = (
  a?: MultiLingual,
  b?: MultiLingual,
  separator: string = " ",
): MultiLingual => {
  const keys = uniq([...Object.keys(a || {}), ...Object.keys(b || {})]);
  return keys.reduce((acc, key) => {
    acc[key] = `${a?.[key] || a?.default || ""}${separator}${
      b?.[key] || b?.default || ""
    }`.trim();
    return acc;
  }, {} as MultiLingual);
};
