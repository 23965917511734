import { FC, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import Tree from "rc-tree";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

import api from "../../api/Api";

import { Session } from "../../@types/session-api";
import { SessionTree } from "../../@types/webapp-api";

import { composeTreeView } from "../../tools/sessionTools";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";

import SessionIcon from "./SessionIcon";
import SessionNodes from "./SessionNodes";

import selectStyles from "../../styles/react-select.module.scss";
import styles from "./SelectParentSessionInput.module.scss";

type Props = {
  defaultValue?: string;
  value?: Session;
  excludeSessionId?: string;
  sphereId: string;
  category: string;
  onChange: (session?: Session) => void;
};

const SelectParentSessionInput: FC<Props> = ({
  value,
  defaultValue,
  excludeSessionId,
  sphereId,
  category,
  onChange,
}) => {
  const [sessionTree, setSessionTree] = useState<SessionTree[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [selection, setSelection] = useState<Session>();
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedSessions, setExpandedSessions] = useState<string[]>([]);
  const ref = useRef(null);

  const { t } = useTranslation("i18n");

  useEffect(() => {
    const loadSessions = async () => {
      const response = await api.getSessions({
        max: 10000,
        offset: 0,
        filters: [
          `sphereId=${sphereId}`,
          `isLeaf=false`,
          `category=${category}`,
        ],
      });
      const sessions = excludeSessionId
        ? response.items.filter(
            (s) =>
              s.id !== excludeSessionId &&
              !s.parentSessionIds.some((id) => id === excludeSessionId),
          )
        : response.items;
      setSessionTree(composeTreeView(sessions));
      setSessions(sessions);

      if (defaultValue) {
        const session = sessions.find((s) => s.id === defaultValue);
        setSelection(session);
        if (session) {
          setExpandedSessions([session.id]);
          onChange(session);
        }
      }

      setLoading(false);
    };
    loadSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeSessionId, sphereId, defaultValue]);

  useEffect(() => {
    setSelection(value);
    if (value) {
      setExpandedSessions([value.id]);
    }
  }, [value]);

  const TreeOption = (props: any) => {
    return loading ? (
      <components.Menu {...props}>
        <div className={styles.menu}>
          <ReactLoading
            className={styles.loader}
            type={"bars"}
            color={"#DDD"}
            height={"20px"}
            width={"25px"}
          />
          {t("common.loading")}
        </div>
      </components.Menu>
    ) : (
      <components.Menu {...props}>
        <Tree
          key="sessionSelection"
          className={styles.menu}
          selectedKeys={selection ? [selection.id] : []}
          selectable
          defaultExpandedKeys={expandedSessions}
          onSelect={(idSelected: any) => {
            onChange(
              sessions.find((s) =>
                idSelected.some((id: string) => s.id === id),
              ),
            );
          }}
        >
          {props.options.map((session: SessionTree) =>
            SessionNodes(session, 1, 0),
          )}
        </Tree>
      </components.Menu>
    );
  };
  const Selection = (props: any) => {
    return selection ? (
      <components.SingleValue {...props}>
        <SessionIcon className={styles.icon} session={selection} />
        {getRelevantTranslationFor(selection.name)}
      </components.SingleValue>
    ) : (
      <></>
    );
  };

  return (
    <Select
      ref={ref}
      className={selectStyles.reactSelect}
      classNamePrefix="custom-react-select"
      options={sessionTree}
      components={{ Menu: TreeOption, SingleValue: Selection }}
      value={selection as any}
      noOptionsMessage={() => t("common.noValue")}
      isSearchable={false}
    />
  );
};

export default SelectParentSessionInput;
