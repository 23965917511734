const colors = {
  bubble: {
    positive: {
      zero: "#FFD747",
      twenty: "#D6E768",
      forty: "#AEF788",
      sixty: "#7BFF7B",
      eighty: "#85FF0A",
      hundred: "#3DFF3D",
    },
    negative: {
      zero: "#FFD747",
      twenty: "#FFB22B",
      forty: "#FF8E0F",
      sixty: "#FF6600",
      eighty: "#FF3100",
      hundred: "#FF0000",
    },
  },
  empty: "#bdbdbd",
  disabled: "#8a8e9e",
  dark: "#212529",
};

const font = {
  fontFamily: "Open Sans",
  fontFamilyBold: "OpenSans-Bold",
  fontFamilyLight: "OpenSans-Light",
  fontFamilySemiBold: "OpenSans-SemiBold",
};

const colorsInfo = {
  font,
  colors,
};

export default colorsInfo;

class Color {
  constructor(r, g, b) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  getComputedPart = (colorPart, type, d, base) => {
    const value = Math.round(colorPart / d);
    const piece = value.toString(base);

    if (type === "hex" && piece.length < 2) {
      return `0${piece}`;
    }

    if (type === "rgbp") {
      return `${piece}%`;
    }

    return piece;
  };

  format(type) {
    const d = type === "hex" ? 1 : 2.55;
    const base = type === "hex" ? 16 : 10;
    const separator = type === "hex" ? "" : ",";

    const r = this.getComputedPart(this.r, type, d, base);
    const g = this.getComputedPart(this.g, type, d, base);
    const b = this.getComputedPart(this.b, type, d, base);

    const rgb = `${r}${separator}${g}${separator}${b}`;

    if (type === "hex") {
      return `#${rgb.toUpperCase()}`;
    }
    return `rgb(${rgb})`;
  }

  static getFormattedHexColor = (color) => {
    if (color.length === 3) {
      const a = color.substr(0, 1);
      const b = color.substr(1, 1);
      const c = color.substr(2, 1);
      return a + a + b + b + c + c;
    }
    return color;
  };

  static parseColor = (color, type) => {
    const c = color.toUpperCase();
    const tempColor = c.replace("rgb", "").replace(/[#(]*/i, "");
    if (type === "hex") {
      const hexColor = Color.getFormattedHexColor(tempColor);
      const num = [
        hexColor.substr(0, 2),
        hexColor.substr(2, 2),
        hexColor.substr(4, 2),
      ];
      return { num, base: 16, m: 1 };
    }
    const num = tempColor.split(",");
    return { num, base: 10, m: type === "rgbp" ? 2.55 : 1 };
  };

  static parse(color, type) {
    const parsedColor = Color.parseColor(color, type);
    const rgb = parsedColor.num.map(
      (n) => parseInt(n, parsedColor.base) * parsedColor.m,
    );
    return new Color(rgb[0], rgb[1], rgb[2]);
  }
}

const getColorType = (color) => {
  if (color.startsWith("#")) {
    return "hex";
  }
  return "rgb";
};

const computeStep = (startColor, endColor, steps) => {
  const totalSteps = steps + 1;
  return new Color(
    (endColor.r - startColor.r) / totalSteps,
    (endColor.g - startColor.g) / totalSteps,
    (endColor.b - startColor.b) / totalSteps,
  );
};

export const mix = (startColor, endColor, step, steps) => {
  const startColorType = getColorType(startColor);
  const endColorType = getColorType(endColor);
  const parsedStartColor = Color.parse(startColor, startColorType);
  const parsedEndColor = Color.parse(endColor, endColorType);

  const computedStep = computeStep(parsedStartColor, parsedEndColor, steps);

  const finalColor = new Color(
    Math.min(parsedStartColor.r + computedStep.r * step, 255),
    Math.min(parsedStartColor.g + computedStep.g * step, 255),
    Math.min(parsedStartColor.b + computedStep.b * step, 255),
  );

  return finalColor.format(startColorType);
};

export const getEmptyColor = () => {
  return colors.empty;
};
export const getDisabledColor = () => {
  return colors.disabled;
};
export const getDarkColor = () => {
  return colors.dark;
};

export const getColor = (value) => {
  const { bubble } = colors;

  if (value === undefined || value === undefined) {
    return getEmptyColor();
  }

  const percent = Math.abs(value) * 100;
  const isNegative = value < 0;

  if (percent === null || percent === undefined) {
    return "rgba(255, 255, 255, 0.3)";
  }

  const bubbleColors = isNegative ? bubble.negative : bubble.positive;

  if (percent < 21) {
    return mix(bubbleColors.zero, bubbleColors.twenty, percent, 20);
  } else if (percent < 41) {
    return mix(bubbleColors.twenty, bubbleColors.forty, percent - 20, 20);
  } else if (percent < 61) {
    return mix(bubbleColors.forty, bubbleColors.sixty, percent - 40, 20);
  } else if (percent < 81) {
    return mix(bubbleColors.sixty, bubbleColors.eighty, percent - 60, 20);
  }
  return mix(bubbleColors.eighty, bubbleColors.hundred, percent - 80, 20);
};

export const mapEnergyPercentToColor = (colors, percent) => {
  const { energy } = colors;

  if (percent === null || percent === undefined) {
    return "rgba(255, 255, 255, 0.3)";
  }

  return mix(energy.low, energy.high, percent, 100);
};
