import { useState, FC } from "react";
import StepContext from "./ParticipationLayoutContext";
import { isMobile } from "react-device-detect";

type Props = { children: any };

const ParticipationLayoutContextProvider: FC<Props> = ({ children }) => {
  const [stepMenuOpen, setStepMenuOpen] = useState(!isMobile);

  const toggleStepMenu = () => {
    setStepMenuOpen(!stepMenuOpen);
  };

  return (
    <StepContext.Provider
      value={{
        isStepMenuOpen: stepMenuOpen,
        toggleStepMenu,
        stepStateChangeHandler: (newState) => setStepMenuOpen(newState.isOpen),
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export default ParticipationLayoutContextProvider;
