/* eslint react-hooks/exhaustive-deps: 0 */
import { FC, useEffect } from "react";
import GlobalStateContext from "./GlobalStateContext";
import { GlobalStateCtx } from "../../@types/seen-apps";
import { useLocation } from "react-router";
import { SET_CONTEXT } from "./globalStateReducer";
import { getUrlParams } from "../../tools/pathTools";

type Props = {
  value: GlobalStateCtx;
  children: any;
};

type UrlParams = {
  sphereId?: string;
  sessionId?: string;
  participationId?: string;
  campaignId?: string;
  reportId?: string;
};

const GlobalStateContextProvider: FC<Props> = ({ value, children }) => {
  const location = useLocation();

  const fillContext = (urlParams: UrlParams) => {
    const { dispatch } = value;
    if (urlParams.sphereId) {
      const {
        state: { spheres, sphereCategories },
      } = value;
      const sphere = spheres.find((sphere) => sphere.id === urlParams.sphereId);
      if (sphere) {
        dispatch({
          type: SET_CONTEXT,
          payload: {
            sphereCtx: {
              sphere,
              category: sphereCategories.find(
                (cat) => cat.id === sphere.category.id,
              ),
            },
          },
        });
      }
    } else if (urlParams.sessionId) {
      const {
        state: { sessions },
      } = value;
      const session = sessions.find(
        (session) => session.id === urlParams.sessionId,
      );
      if (session) {
        dispatch({
          type: SET_CONTEXT,
          payload: {
            sessionCtx: {
              session,
            },
          },
        });
      }
    } else if (
      !urlParams.campaignId &&
      !urlParams.participationId &&
      !urlParams.reportId
    ) {
      dispatch({
        type: SET_CONTEXT,
        payload: { mySelfCtx: {} },
      });
    }
  };

  useEffect(() => {
    const urlParams = getUrlParams(location.pathname);
    fillContext(urlParams);
  }, [location]);

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateContextProvider;
