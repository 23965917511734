import React, { memo } from "react";

// System Icons
import LogoutBoxRLine from "remixicon/icons/System/logout-box-r-line.svg?react";
import ArrowLeftSLine from "remixicon/icons/Arrows/arrow-left-s-line.svg?react";
import ArrowRightSLine from "remixicon/icons/Arrows/arrow-right-s-line.svg?react";
import ArrowRightFill from "remixicon/icons/Arrows/arrow-right-fill.svg?react";
import ArrowLeftRight from "remixicon/icons/Arrows/arrow-left-right-line.svg?react";
import ArrowUpDown from "remixicon/icons/Arrows/arrow-up-down-line.svg?react";
import SubGoal from "remixicon/icons/Arrows/corner-down-right-line.svg?react";
import SubElement from "remixicon/icons/Arrows/corner-down-right-fill.svg?react";
import CloseLine from "remixicon/icons/System/close-line.svg?react";
import DeleteBinLine from "remixicon/icons/System/delete-bin-line.svg?react";
import HistoryLine from "remixicon/icons/System/history-line.svg?react";
import Settings3Line from "remixicon/icons/System/settings-3-line.svg?react";
import ShieldKeyholeLine from "remixicon/icons/System/shield-keyhole-line.svg?react";
import ShieldKeyholeFill from "remixicon/icons/System/shield-keyhole-fill.svg?react";
import Forbid2Line from "remixicon/icons/System/forbid-2-line.svg?react";
import AddLine from "remixicon/icons/System/add-line.svg?react";
import SubstractLine from "remixicon/icons/System/subtract-line.svg?react";
import ExternalLinkLine from "remixicon/icons/System/external-link-line.svg?react";
import Link from "remixicon/icons/Editor/link.svg?react";
import Translate from "remixicon/icons/Editor/translate-2.svg?react";
import ShieldUserLine from "remixicon/icons/System/shield-user-line.svg?react";
import ShieldCheckLine from "remixicon/icons/System/shield-check-line.svg?react";
import LockPasswordLine from "remixicon/icons/System/lock-password-line.svg?react";
import ArrowDropDownLine from "remixicon/icons/Arrows/arrow-drop-down-line.svg?react";
import TagSeparator from "remixicon/icons/Arrows/arrow-drop-right-line.svg?react";
import TimeLine from "remixicon/icons/System/time-line.svg?react";
import TimerLine from "remixicon/icons/System/timer-line.svg?react";
import MenuLine from "remixicon/icons/System/menu-line.svg?react";
import SearchLine from "remixicon/icons/System/search-line.svg?react";
import Info from "remixicon/icons/System/information-fill.svg?react";
import More from "remixicon/icons/System/more-fill.svg?react";
import Warning from "remixicon/icons/System/alert-line.svg?react";
import CloseCircle from "remixicon/icons/System/close-circle-fill.svg?react";
import CheckboxCircleEmpty from "remixicon/icons/System/checkbox-blank-circle-line.svg?react";
import CheckboxCircleValid from "remixicon/icons/System/checkbox-circle-fill.svg?react";

import ListSettings from "remixicon/icons/System/list-settings-line.svg?react";
import ArrowDropRight from "remixicon/icons/Arrows/arrow-drop-right-line.svg?react";
import SalesUpGroup from "remixicon/icons/System/checkbox-blank-circle-line.svg?react";
import StepActive from "remixicon/icons/System/radio-button-line.svg?react";
import NextStep from "remixicon/icons/System/checkbox-blank-circle-fill.svg?react";
import StepValidated from "remixicon/icons/System/checkbox-circle-fill.svg?react";
import StepIncomplete from "remixicon/icons/System/checkbox-blank-circle-line.svg?react";

// User Icons
import AccountCircleFill from "remixicon/icons/User & Faces/account-circle-fill.svg?react";
import AccountCircleLine from "remixicon/icons/User & Faces/account-circle-line.svg?react";
import GroupLine from "remixicon/icons/User & Faces/group-line.svg?react";
import UserAddLine from "remixicon/icons/User & Faces/user-add-line.svg?react";
import UserUnfollowLine from "remixicon/icons/User & Faces/user-unfollow-line.svg?react";
import UserSettingsLine from "remixicon/icons/User & Faces/user-settings-line.svg?react";
import Guest from "remixicon/icons/User & Faces/user-add-line.svg?react";
import TagGroup from "remixicon/icons/User & Faces/team-fill.svg?react";
import Member from "remixicon/icons/User & Faces/user-line.svg?react";

// Design Icons
import PencilLine from "remixicon/icons/Design/pencil-line.svg?react";
import PencilFill from "remixicon/icons/Design/pencil-fill.svg?react";
import Edit from "remixicon/icons/Design/edit-box-line.svg?react";
import Magic from "remixicon/icons/Design/magic-fill.svg?react";

// Editor Icons
import TagChart from "remixicon/icons/Editor/organization-chart.svg?react";
import TreeView from "remixicon/icons/Editor/node-tree.svg?react";
import FlowChart from "remixicon/icons/Editor/flow-chart.svg?react";
import Ai from "remixicon/icons/Editor/ai-generate.svg?react";
import Draggable from "remixicon/icons/Editor/draggable.svg?react";
import Unknown from "remixicon/icons/System/question-fill.svg?react";
import Help from "remixicon/icons/System/question-fill.svg?react";
// Business Icons
import MailSendLine from "remixicon/icons/Business/mail-send-line.svg?react";
import BarChartLine from "remixicon/icons/Business/bar-chart-line.svg?react";
import BarChartBoxLine from "remixicon/icons/Business/bar-chart-box-line.svg?react";
import BarChartLine2 from "remixicon/icons/Business/bar-chart-2-fill.svg?react";
import AtLine from "remixicon/icons/Business/at-line.svg?react";
import TagMoment from "remixicon/icons/Business/calendar-event-fill.svg?react";
import TagPeriod from "remixicon/icons/Business/calendar-2-fill.svg?react";
import RangeDatePicker from "remixicon/icons/Business/calendar-2-line.svg?react";
import PrivacyUnlocked from "remixicon/icons/Business/verified-badge-line.svg?react";
import PrivacyLocked from "remixicon/icons/System/prohibited-line.svg?react";
import SetAsDefault from "remixicon/icons/Business/global-line.svg?react";
import Email from "remixicon/icons/Business/mail-send-line.svg?react";
import Scheduler from "remixicon/icons/Business/calendar-line.svg?react";

// Buildings Icons
import Home4Line from "remixicon/icons/Buildings/home-4-line.svg?react";
import Home4 from "remixicon/icons/Buildings/home-4-fill.svg?react";
import Building from "remixicon/icons/Buildings/building-line.svg?react";
import HospitalLine from "remixicon/icons/Buildings/hospital-line.svg?react";
import RestaurantLine from "remixicon/icons/Food/restaurant-line.svg?react";
import SchoolLine from "remixicon/icons/Buildings/school-line.svg?react";
// Map Icons
import EarthLine from "remixicon/icons/Map/earth-line.svg?react";
import MapPin2Line from "remixicon/icons/Map/map-pin-2-line.svg?react";
import HotelBedFill from "remixicon/icons/Map/hotel-bed-fill.svg?react";
import Campaign from "remixicon/icons/Map/treasure-map-line.svg?react";
import MapPin from "remixicon/icons/Map/map-pin-2-fill.svg?react";
// Media Icons
import FullscreenLine from "remixicon/icons/Media/fullscreen-line.svg?react";
import FullscreenExitLine from "remixicon/icons/Media/fullscreen-exit-line.svg?react";
import NotificationLine from "remixicon/icons/Media/notification-line.svg?react";
import NotificationFill from "remixicon/icons/Media/notification-fill.svg?react";
import Start from "remixicon/icons/Media/play-fill.svg?react";
import Stop from "remixicon/icons/Media/stop-fill.svg?react";
import Continue from "remixicon/icons/Media/play-large-fill.svg?react";
import Music from "remixicon/icons/Media/music-fill.svg?react";
import MovieAILine from "remixicon/icons/Media/movie-ai-line.svg?react";
//finance
import Tag from "remixicon/icons/Finance/price-tag-3-line.svg?react";
import TagFill from "remixicon/icons/Finance/price-tag-3-fill.svg?react";
import Subscription from "remixicon/icons/Finance/vip-crown-2-line.svg?react";
import Coupon from "remixicon/icons/Finance/coupon-3-line.svg?react";
// Device Icons
import ShutDownLine from "remixicon/icons/Device/shut-down-line.svg?react";
import Scan from "remixicon/icons/Device/qr-code-line.svg?react";
import RefreshFill from "remixicon/icons/Device/restart-fill.svg?react";
import RefreshLine from "remixicon/icons/Device/restart-line.svg?react";
import SaveLine from "remixicon/icons/Device/save-3-line.svg?react";
import SaveFill from "remixicon/icons/Device/save-fill.svg?react";
import FlowerLine from "remixicon/icons/Others/flower-line.svg?react";

import MentalHealthLine from "remixicon/icons/Health & Medical/mental-health-line.svg?react";
import MentalHealthFill from "remixicon/icons/Health & Medical/mental-health-fill.svg?react";
import Heart from "remixicon/icons/Health & Medical/heart-fill.svg?react";
import FavoriteFill from "remixicon/icons/System/star-fill.svg?react";
import Favorite from "remixicon/icons/System/star-line.svg?react";
import Success from "remixicon/icons/System/check-line.svg?react";
import Error from "remixicon/icons/System/close-fill.svg?react";
import Filter from "remixicon/icons/System/filter-fill.svg?react";
import Download from "remixicon/icons/System/download-2-fill.svg?react";
import Preview from "remixicon/icons/System/eye-fill.svg?react";
import Visible from "remixicon/icons/System/eye-fill.svg?react";
import Hidden from "remixicon/icons/System/eye-off-line.svg?react";
import Privacy from "remixicon/icons/Document/folder-user-fill.svg?react";
import Copy from "remixicon/icons/Document/file-copy-2-line.svg?react";
import BlankTemplate from "remixicon/icons/Document/file-paper-fill.svg?react";
import Report from "remixicon/icons/Document/book-2-fill.svg?react";
import ReportPart from "remixicon/icons/Document/newspaper-line.svg?react";
import ChartDocument from "remixicon/icons/Document/file-chart-line.svg?react";
import SynthesisReport from "remixicon/icons/Design/grid-fill.svg?react";
import DataDocument from "remixicon/icons/Document/file-excel-line.svg?react";
import TextDocument from "remixicon/icons/Document/file-text-line.svg?react";

import ChatSend from "remixicon/icons/Communication/chat-upload-line.svg?react";
import Chat from "remixicon/icons/Communication/chat-1-line.svg?react";
import Axis from "remixicon/icons/Communication/questionnaire-line.svg?react";
import Feedback from "remixicon/icons/Communication/feedback-fill.svg?react";
import ChatPoll from "remixicon/icons/Communication/chat-poll-line.svg?react";
import Forms from "remixicon/icons/Document/survey-line.svg?react";
import PersonalNote from "remixicon/icons/Document/file-lock-fill.svg?react";
import NoteSmall from "remixicon/icons/System/lock-fill.svg?react";
import PreviewAccess from "remixicon/icons/System/eye-2-line.svg?react";
import Note from "remixicon/icons/Document/file-fill.svg?react";
import Excel from "remixicon/icons/Document/file-excel-2-fill.svg?react";
import Csv from "remixicon/icons/Editor/table-2.svg?react";
import Compare from "remixicon/icons/Editor/insert-column-right.svg?react";
import Pdf from "remixicon/icons/Document/file-pdf-fill.svg?react";
import Duplicate from "remixicon/icons/Document/file-copy-2-fill.svg?react";
import Print from "remixicon/icons/Business/printer-fill.svg?react";

import StrategyVision from "remixicon/icons/Map/compass-3-line.svg?react";
import Strategy from "remixicon/icons/Design/artboard-fill.svg?react";
import StrategyMission from "remixicon/icons/Business/service-fill.svg?react";
import StrategyStrategicAxis from "remixicon/icons/Design/focus-2-line.svg?react";
import Goal from "remixicon/icons/Design/focus-2-line.svg?react";
import StrategyGoal from "remixicon/icons/Design/focus-3-line.svg?react";
import StrategyCustom from "remixicon/icons/Design/focus-line.svg?react";
import StrategyAction from "remixicon/icons/Media/memories-fill.svg?react";

import IndentIncrease from "remixicon/icons/Arrows/arrow-right-s-fill.svg?react";
import IndentDecrease from "remixicon/icons/Arrows/arrow-left-s-fill.svg?react";
import Expand from "remixicon/icons/Arrows/arrow-right-s-fill.svg?react";
import Collapse from "remixicon/icons/Arrows/arrow-down-s-fill.svg?react";

import Whatsapp from "remixicon/icons/Logos/whatsapp-fill.svg?react";
import Share from "remixicon/icons/System/share-line.svg?react";

import UserConnection from "remixicon/icons/User & Faces/user-shared-line.svg?react";
import UserConnection2 from "remixicon/icons/User & Faces/user-3-fill.svg?react";
import UserConnection3 from "remixicon/icons/User & Faces/user-3-line.svg?react";
import Dashboard from "remixicon/icons/System/dashboard-fill.svg?react";
import UseConnect from "remixicon/icons/User & Faces/user-follow-line.svg?react";

import RankingIcon from "remixicon/icons/Finance/trophy-line.svg?react";
import WaitTime from "remixicon/icons/System/hourglass-fill.svg?react";
import Gift from "remixicon/icons/Finance/gift-fill.svg?react";

import Infinite from "remixicon/icons/Others/infinity-line.svg?react";

import DataShotCard from "remixicon/icons/Business/bar-chart-grouped-line.svg?react";
import TextCard from "remixicon/icons/Editor/text-block.svg?react";
import TableOfContentCard from "remixicon/icons/Editor/list-ordered.svg?react";

import CarouselLeft from "remixicon/icons/Arrows/arrow-drop-left-line.svg?react";
import CarouselRight from "remixicon/icons/Arrows/arrow-drop-right-line.svg?react";

import cn from "classnames";

import styles from "./RemixIcons.module.scss";

export type RemixIconProps = React.SVGProps<SVGSVGElement> & {
  className?: string;
};

const remixIcon = (
  component: React.FC<React.SVGProps<SVGSVGElement>>,
  additionalClassName = "",
) =>
  memo(function RemixIconMemoized({
    className = "",
    ...otherProps
  }: RemixIconProps) {
    return React.createElement(component, {
      className: cn(styles.root, additionalClassName, className),
      ...otherProps,
    });
  });

export const RankingIconIcon = remixIcon(RankingIcon);
export const CheckboxCircleEmptyIcon = remixIcon(CheckboxCircleEmpty);
export const CloseCircleIcon = remixIcon(CloseCircle);
export const CheckboxCircleValidIcon = remixIcon(CheckboxCircleValid);
export const SubscriptionIcon = remixIcon(Subscription);
export const CouponIcon = remixIcon(Coupon);
export const TagIcon = remixIcon(Tag);
export const TagFillIcon = remixIcon(TagFill);
export const TagGroupIcon = remixIcon(TagGroup, styles.smaller);
export const MemberIcon = remixIcon(Member, styles.smaller);
export const LogoutBoxRLineIcon = remixIcon(LogoutBoxRLine);
export const ArrowLeftSLineIcon = remixIcon(ArrowLeftSLine);
export const ArrowRightSLineIcon = remixIcon(ArrowRightSLine);
export const AccountCircleFillIcon = remixIcon(AccountCircleFill);
export const ArrowRightFillIcon = remixIcon(ArrowRightFill);
export const ArrowLeftRightIcon = remixIcon(ArrowLeftRight);
export const ArrowUpDownIcon = remixIcon(ArrowUpDown);
export const SubGoalIcon = remixIcon(SubGoal);
export const SubElementIcon = remixIcon(SubElement);
export const UserAddLineIcon = remixIcon(UserAddLine);
export const CloseLineIcon = remixIcon(CloseLine);
export const UserUnfollowLineIcon = remixIcon(UserUnfollowLine);
export const PencilLineIcon = remixIcon(PencilLine);
export const PencilFillIcon = remixIcon(PencilFill);
export const SaveLineIcon = remixIcon(SaveLine);
export const SaveFillIcon = remixIcon(SaveFill);
export const DeleteBinLineIcon = remixIcon(DeleteBinLine);
export const MailSendLineIcon = remixIcon(MailSendLine);
export const Home4LineIcon = remixIcon(Home4Line);
export const Home4Icon = remixIcon(Home4);
export const BuildingIcon = remixIcon(Building);
export const HospitalLineIcon = remixIcon(HospitalLine);
export const RestaurantLineIcon = remixIcon(RestaurantLine);
export const SchoolLineIcon = remixIcon(SchoolLine);
export const BarChartLineIcon = remixIcon(BarChartLine);
export const BarChartLine2Icon = remixIcon(BarChartLine2);
export const BarChartBoxLineIcon = remixIcon(BarChartBoxLine);
export const HistoryLineIcon = remixIcon(HistoryLine);
export const EarthLineIcon = remixIcon(EarthLine);
export const Settings3LineIcon = remixIcon(Settings3Line);
export const GroupLineIcon = remixIcon(GroupLine, styles.smaller);
export const ShieldKeyholeLineIcon = remixIcon(
  ShieldKeyholeLine,
  styles.superAdmin,
);
export const ShieldKeyholeFillIcon = remixIcon(
  ShieldKeyholeFill,
  styles.superAdmin,
);
export const AddLineIcon = remixIcon(AddLine);
export const SubstractLineIcon = remixIcon(SubstractLine);
export const Forbid2LineIcon = remixIcon(Forbid2Line);
export const AnomalyIcon = remixIcon(Forbid2Line);
export const FullscreenLineIcon = remixIcon(FullscreenLine);
export const FullscreenExitLineIcon = remixIcon(FullscreenExitLine);
export const ExternalLinkLineIcon = remixIcon(ExternalLinkLine);
export const LinkIcon = remixIcon(Link);
export const TranslateIcon = remixIcon(Translate);
export const ShieldUserLineIcon = remixIcon(ShieldUserLine);
export const AtLineIcon = remixIcon(AtLine);
export const ShutDownLineIcon = remixIcon(ShutDownLine);
export const ScanIcon = remixIcon(Scan);
export const UserSettingsLineIcon = remixIcon(UserSettingsLine);
export const GuestIcon = remixIcon(Guest);
export const MapPin2LineIcon = remixIcon(MapPin2Line);
export const MapPinIcon = remixIcon(MapPin);
export const ShieldCheckLineIcon = remixIcon(ShieldCheckLine);
export const LockPasswordLineIcon = remixIcon(LockPasswordLine);
export const ArrowDropDownLineIcon = remixIcon(ArrowDropDownLine);
export const TimeLineIcon = remixIcon(TimeLine);
export const TimerLineIcon = remixIcon(TimerLine);
export const NotificationLineIcon = remixIcon(NotificationLine);
export const AccountCircleLineIcon = remixIcon(AccountCircleLine);
export const NotificationFillIcon = remixIcon(NotificationFill);
export const StartIcon = remixIcon(Start);
export const StopIcon = remixIcon(Stop);
export const ContinueIcon = remixIcon(Continue);
export const MusicIcon = remixIcon(Music);
export const MovieAILineIcon = remixIcon(MovieAILine);
export const MenuLineIcon = remixIcon(MenuLine);
export const SearchIcon = remixIcon(SearchLine);
export const InfoIcon = remixIcon(Info);
export const MoreIcon = remixIcon(More);
export const WarningIcon = remixIcon(Warning, styles.warning);
export const HotelBedFillIcon = remixIcon(HotelBedFill);
export const CampaignIcon = remixIcon(Campaign);
export const StudyProgramIcon = remixIcon(MentalHealthLine);
export const StudyProgramFillIcon = remixIcon(MentalHealthFill);
export const HeartIcon = remixIcon(Heart);
export const FavoriteFillIcon = remixIcon(FavoriteFill);
export const FavoriteIcon = remixIcon(Favorite);
export const ErrorIcon = remixIcon(Error, styles.error);
export const SuccessIcon = remixIcon(Success, styles.success);
export const PrivacyIcon = remixIcon(Privacy);
export const CopyIcon = remixIcon(Copy);
export const BlankTemplateIcon = remixIcon(BlankTemplate);
export const ReportIcon = remixIcon(Report);
export const ReportPartIcon = remixIcon(ReportPart);
export const DataDocumentIcon = remixIcon(DataDocument);
export const TextDocumentIcon = remixIcon(TextDocument);
export const ChartDocumentIcon = remixIcon(ChartDocument);
export const SynthesisReportIcon = remixIcon(SynthesisReport);
export const RefreshFillIcon = remixIcon(RefreshFill);
export const RefreshIcon = remixIcon(RefreshLine);
export const FlowerIcon = remixIcon(FlowerLine);
export const ChatPollIcon = remixIcon(ChatPoll);
export const ChatSendIcon = remixIcon(ChatSend);
export const AxisIcon = remixIcon(Axis);
export const ChatIcon = remixIcon(Chat);
export const FeedbackIcon = remixIcon(Feedback);
export const EditIcon = remixIcon(Edit);
export const MagicIcon = remixIcon(Magic);
export const TagSeparatorIcon = remixIcon(TagSeparator);
export const ListSettingsIcon = remixIcon(ListSettings);
export const TagChartIcon = remixIcon(TagChart);
export const TagsSelectionIcon = remixIcon(FlowChart);
export const AiIcon = remixIcon(Ai);
export const DraggableIcon = remixIcon(Draggable);
export const TreeViewIcon = remixIcon(TreeView);
export const UnknownIcon = remixIcon(Unknown);
export const HelpIcon = remixIcon(Help);
export const TagMomentIcon = remixIcon(TagMoment);
export const TagPeriodIcon = remixIcon(TagPeriod);
export const RangeDatePickerIcon = remixIcon(RangeDatePicker);
export const ArrowDropRightIcon = remixIcon(ArrowDropRight);
export const SalesUpGroupIcon = remixIcon(SalesUpGroup, styles.smaller);
export const FormsIcon = remixIcon(Forms, styles.smaller);
export const StepActiveIcon = remixIcon(StepActive, styles.smaller);
export const NextStepIcon = remixIcon(NextStep, styles.smaller);
export const StepValidatedIcon = remixIcon(StepValidated, styles.smaller);
export const StepIncompleteIcon = remixIcon(StepIncomplete, styles.smaller);
export const StrategyVisionIcon = remixIcon(StrategyVision, styles.smaller);
export const StrategyIcon = remixIcon(Strategy, styles.smaller);
export const StrategyMissionIcon = remixIcon(StrategyMission, styles.smaller);
export const StrategyStrategicAxisIcon = remixIcon(
  StrategyStrategicAxis,
  styles.smaller,
);
export const StrategyActionIcon = remixIcon(StrategyAction, styles.smaller);
export const StrategyAssessmentIcon = FormsIcon;
export const GoalIcon = remixIcon(Goal, styles.smaller);
export const StrategyGoalIcon = remixIcon(StrategyGoal, styles.smaller);
export const StrategyCustomIcon = remixIcon(StrategyCustom, styles.smaller);
export const IndentIncreaseIcon = remixIcon(IndentIncrease, styles.smaller);
export const IndentDecreaseIcon = remixIcon(IndentDecrease, styles.smaller);
export const ExpandIcon = remixIcon(Expand, styles.smaller);
export const CollapseIcon = remixIcon(Collapse, styles.smaller);
export const PersonalNoteIcon = remixIcon(PersonalNote, styles.secure);
export const NoteSmallIcon = remixIcon(NoteSmall, styles.secure);
export const PreviewAccessIcon = remixIcon(PreviewAccess, styles.secure);
export const NoteIcon = remixIcon(Note);
export const ExcelIcon = remixIcon(Excel);
export const CsvIcon = remixIcon(Csv);
export const CompareIcon = remixIcon(Compare);
export const PdfIcon = remixIcon(Pdf);
export const DuplicateIcon = remixIcon(Duplicate);
export const PrintIcon = remixIcon(Print);
export const FilterIcon = remixIcon(Filter);
export const DownloadIcon = remixIcon(Download);
export const PreviewIcon = remixIcon(Preview);
export const VisibleIcon = remixIcon(Visible);
export const HiddenIcon = remixIcon(Hidden);
export const WhatsappIcon = remixIcon(Whatsapp);

export const ShareIcon = remixIcon(Share);
export const UserConnectionIcon = remixIcon(UserConnection);
export const UserConnection2Icon = remixIcon(UserConnection2);
export const UserConnection3Icon = remixIcon(UserConnection3);
export const UseConnectIcon = remixIcon(UseConnect);
export const DashboardIcon = remixIcon(Dashboard);
export const PrivacyLockedIcon = remixIcon(PrivacyLocked, styles.error);
export const PrivacyUnlockedIcon = remixIcon(PrivacyUnlocked, styles.unlock);
export const PrivacyDisabledIcon = remixIcon(PrivacyUnlocked, styles.disabled);
export const SetAsDefaultIcon = remixIcon(SetAsDefault, styles.disabled);
export const EmailIcon = remixIcon(Email, styles.disabled);
export const SchedulerIcon = remixIcon(Scheduler);
export const SetAsPrimaryIcon = remixIcon(FavoriteFill);
export const WaitTimeIcon = remixIcon(WaitTime);
export const GiftIcon = remixIcon(Gift);
export const InfiniteIcon = remixIcon(Infinite);
export const DataShotCardIcon = remixIcon(DataShotCard);
export const TextCardIcon = remixIcon(TextCard);
export const CarouselLeftIcon = remixIcon(CarouselLeft);
export const CarouselRightIcon = remixIcon(CarouselRight);
export const TableOfContentCardIcon = remixIcon(TableOfContentCard);
