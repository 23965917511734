import React, { useEffect, useState } from "react";
import styles from "./ScanPage.module.scss";
import QrScanner from "../../components/scanner/QrScanner";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import config from "../../config";
import { captureException } from "@sentry/minimal";
import EnterCode from "../../components/Auth/EnterCode";
import HorizontalRule from "../../components/horizontalRule/HorizontalRule";
import { Button } from "react-bootstrap";
import { ScanIcon } from "../../components/RemixIcons";

const ScanPage: React.FC = () => {
  const { t } = useTranslation("i18n");
  const navigateTo = useNavigate();
  const [lastUrl, setLastUrl] = useState<string>();
  const [showEnterCode, setShowEnterCode] = useState<boolean>(false);
  const [showScan, setShowScan] = useState<boolean>(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const show = url.searchParams.get("show") || "";
    if (show === "enterCode") {
      setShowEnterCode(true);
    } else {
      setShowScan(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScan = (url: string) => {
    try {
      const target = new URL(url);
      if (target.hostname === window.location.hostname) {
        navigateTo(target.pathname);
      } else if (config.whiteList.find((w: string) => target.hostname === w)) {
        window.location.href = url;
      } else {
        throw new Error("URL no allowed");
      }
    } catch (error) {
      captureException(error);
      if (lastUrl !== url) {
        toast.error(t("scan.not-valid"));
      }
    }
    setLastUrl(url);
  };

  return (
    <div className={styles.root}>
      {showScan && <QrScanner onScan={handleScan} />}
      {showEnterCode && (
        <div className={styles.enterCodeRoot}>
          <div className={styles.enterCode}>
            <EnterCode size="small" />
          </div>
        </div>
      )}
      {!showScan && (
        <div className={styles.scanButton}>
          <HorizontalRule text={""} />
          <Button onClick={() => setShowScan(true)}>
            <ScanIcon /> {t("common.action.qrCodeScan")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ScanPage;
