import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/Api";
import { toast } from "react-toastify";
import pageStyles from "./AddPromotionCodePage.module.scss";
import modalStyles from "../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import PageTitle from "../../components/PageTitle/PageTitle";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { useTranslation } from "react-i18next";
import { ADD_OR_UPDATE_PROMOTION_CODE } from "../../context/globalState/globalStateReducer";
import { captureException } from "@sentry/minimal";
import { Action, Subject } from "../../@types/member-api";
import { useAbility } from "@casl/react";
import AbilityContext from "../../context/AbilityContext";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";
import MarkdownComponent from "../../components/Markdown/MarkdownComponent";

const styles = { ...modalStyles, ...pageStyles };

type Props = {
  currentPageIdx?: number;
  onSubmit?: (state?: any) => void;
};

type FormInputs = {
  name: string;
  count: number;
};

const AddPromotionCodePage: React.FC<Props> = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation("i18n");
  const ability = useAbility(AbilityContext);

  const {
    state: {
      context: { sessionCtx },
    },
    dispatch,
  } = useContext(GlobalStateContext);
  const { register, handleSubmit, formState } = useForm<FormInputs>({
    defaultValues: {
      count: 5,
      name: getRelevantTranslationFor(sessionCtx?.session.name),
    },
  });

  const onSubmitForm = async (form: FormInputs) => {
    try {
      const promotionCode = await api.addPromotionCode({
        count: form.count,
        name: form.name,
        sphereId: sessionCtx?.session.sphere.id,
        sphereName: sessionCtx?.session.sphere.name,
        sessionId: sessionCtx?.session.id,
        sessionName: getRelevantTranslationFor(sessionCtx?.session.name),
      });

      toast.success(t(`pages.ambassador.promotionCode.add.succeed`));
      dispatch({
        type: ADD_OR_UPDATE_PROMOTION_CODE,
        payload: promotionCode,
      });
      onSubmit();
    } catch (error) {
      captureException(error);
      toast.error(
        t("pages.ambassador.promotionCode.add.error", { error: error }),
      );
    }
  };
  return ability.cannot(Action.Post, Subject.SubscriptionPromotionCode) ? (
    <div></div>
  ) : (
    <div className={styles.page}>
      <PageTitle title={t(`pages.ambassador.promotionCode.add.title`)} />{" "}
      <div className={styles.content}>
        <Container>
          <Form
            onSubmit={handleSubmit(onSubmitForm)}
            autoComplete="off"
            noValidate
          >
            <Row>
              <Col sm={12}>
                <Alert variant="info" className={styles.info}>
                  <MarkdownComponent
                    className={styles.info}
                    text={t("pages.ambassador.promotionCode.info")}
                  />
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>
                    {t(`pages.ambassador.promotionCode.form.labels.name`)} *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(
                      `pages.ambassador.promotionCode.form.labels.name`,
                    )}
                    {...register("name", {
                      required: t(`common.validations.required`) as string,
                    })}
                    isInvalid={formState.isSubmitted && !!formState.errors.name}
                  />
                  {formState.errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="form-group" controlId="count">
                  <Form.Label>
                    {t(`pages.ambassador.promotionCode.form.labels.count`)} *
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t(
                      `pages.ambassador.promotionCode.form.labels.count`,
                    )}
                    {...register("count", {
                      required: t(`common.validations.required`) as string,
                    })}
                    isInvalid={
                      formState.isSubmitted && !!formState.errors.count
                    }
                  />
                  {formState.errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.count?.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6} className={styles.buttons}>
                <Button
                  variant="secondary"
                  className={styles.right}
                  onClick={() => onSubmit()}
                >
                  {t("common.action.close")}
                </Button>
                <Button name="submit" type="submit">
                  {t("common.action.save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default AddPromotionCodePage;
