import React, { useEffect, useState } from "react";
import { ChatMessage } from "./ChatDialog";

import styles from "./ChatDialog.module.scss";

import al from "../../assets/images/al.webp";
import { UserConnection2Icon } from "../RemixIcons";

import ReactLoading from "react-loading";
import MarkdownComponent from "../Markdown/MarkdownComponent";

interface ChatDialogProps {
  message: ChatMessage;
}

const Chat: React.FC<ChatDialogProps> = ({ message, ...props }) => {
  const [lines, setLines] = useState<string[]>([]);

  useEffect(() => {
    setLines(message.text.split(/\r?\n/));
  }, [message]);

  return (
    <div
      className={`${styles.message} ${
        message.user === "assistant"
          ? styles.messageAssistant
          : styles.messageMe
      }`}
      {...props}
    >
      <div className={styles.profile}>
        {message.user === "assistant" ? (
          <img className={styles.profileImg} src={al} alt="AL" />
        ) : (
          <UserConnection2Icon />
        )}
      </div>

      <div className={`${styles.content} ${styles[message.user]}`}>
        {message.isWriting ? (
          <ReactLoading
            className={styles.loader}
            type={"bubbles"}
            color={"#DDD"}
            height={"20px"}
            width={"30px"}
          />
        ) : (
          lines.map((line, index) => (
            <div key={`loading_line_${index}`} className={styles.line}>
              <MarkdownComponent text={line} />
            </div>
          ))
        )}
      </div>
      {message.isWriting && (
        <div className={`${styles.loadingMessage}`}>
          {lines.map((line, index) => (
            <div key={`line_${index}`} className={styles.line}>
              {line}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Chat;
