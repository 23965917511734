import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import styles from "./SessionTags.module.scss";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";
import { Session } from "../../@types/session-api";
import { BadgeContent } from "../../@types/webapp-api";
import { Variant } from "react-bootstrap/esm/types";

interface Props {
  session?: Session;
  mode?: "parent" | "specific" | "all";
  variant?: Variant;
  className?: string;
}

const SessionTags = ({ session, mode, variant, className }: Props) => {
  const [tags, setTags] = useState<BadgeContent[]>([]);

  useEffect(() => {
    let tags: BadgeContent[] = [];

    if (!mode || mode === "specific") {
      tags = (session?.specificTagIds || []).reduce((acc, tagId) => {
        const tag = session?.tags?.find((t) => t.id === tagId);
        if (!tag || tag.archived) {
          return acc;
        }

        return [
          ...acc,
          {
            id: tag.id,
            text: getRelevantTranslationFor(tag.name),
            variant: variant || "primary",
          },
        ];
      }, tags);
    }

    if (!mode || mode === "parent") {
      tags = (session?.parentTagIds || []).reduce((acc, tagId) => {
        const tag = session?.tags?.find((t) => t.id === tagId);
        if (!tag || tag.archived) {
          return acc;
        }
        if (acc.some((t) => t.id === tag.id)) {
          return acc;
        }

        return [
          ...acc,
          {
            id: tag.id,
            text: getRelevantTranslationFor(tag.name),
            variant: variant || "secondary",
          },
        ];
      }, tags);
    }

    // setTags(tags);
    setTags(tags);
  }, [session, mode, variant]);

  return (
    <div className={`${className || ""} ${styles.tags}`}>
      {tags.length === 0 && (
        <Badge className={styles.badge} key={`no-tag`} bg="secondary">
          -
        </Badge>
      )}
      {tags.map((tag) => (
        <Badge className={styles.badge} key={`tag-${tag.id}`} bg={tag.variant}>
          {tag.text}
        </Badge>
      ))}
    </div>
  );
};
export default SessionTags;
