import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  FeelingMode,
  getAdminSessionRoute,
  getFeelingRoute,
  getSessionReportRoute,
  getSessionRoute,
} from "../../../tools/routeTools";
import { Settings3LineIcon } from "../../RemixIcons";
import Card, { Props as CardProps } from "../Card";
import RoundedButton from "../../RoundedButton/RoundedButton";
import styles from "./SessionCard.module.scss";
import { Session } from "../../../@types/session-api";
import SessionIcon from "../../session/SessionIcon";
import RippleButton from "../../rippleButton/RippleButton";
import happyFace from "../../../assets/images/HappyFace.svg";
import notHappyFace from "../../../assets/images/NotHappyFace.svg";
import Favorite from "../../favorite/Favorite";
import AbilityContext from "../../../context/AbilityContext";
import { subject } from "@casl/ability";
import { Can } from "@casl/react";
import GlobalStateContext from "../../../context/globalState/GlobalStateContext";
import { Badge } from "react-bootstrap";
import { getLanguages } from "../../../i18n";
import { BadgeContent } from "../../../@types/webapp-api";
import SessionTags from "../../session/SessionTags";
import { useTranslation } from "react-i18next";
import { getSessionName, isSessionAdmin } from "../../../tools/sessionTools";

type Props = {
  session: Session;
  hiddenFeeling?: boolean;
  showLanguage?: boolean;
  showRoles?: boolean;
  showTags?: boolean;
} & CardProps;

const SessionCard: FC<Props> = ({
  session,
  hiddenFeeling,
  showLanguage,
  showRoles,
  showTags,
}) => {
  const navigateTo = useNavigate();
  const { t } = useTranslation("i18n");
  const ability = useContext(AbilityContext);
  const { state: globalState } = useContext(GlobalStateContext);
  const [sphere, setSphere] = useState<{ id: string; name: string }>();
  const [languagesStatus, setLanguagesStatus] = useState<BadgeContent[]>([]);
  const [roles, setRoles] = useState<BadgeContent[]>([]);
  const [link, setLink] = useState<string>();

  const location = useLocation();

  useEffect(() => {
    const sphere = globalState.spheres.find((s) => s.id === session.sphere.id);
    setSphere(sphere || session.sphere);

    if (showLanguage) {
      const languagesStatus = getLanguages().reduce((acc, lang) => {
        const name = session.name[lang];
        const publicName = session.publicName[lang];
        if (!name && !publicName) {
          return acc;
        }
        return [
          ...acc,
          {
            id: lang,
            text: lang,
            variant: name && publicName ? "success" : "warning",
          },
        ];
      }, [] as BadgeContent[]);
      setLanguagesStatus(languagesStatus);
    }

    const isAdmin = isSessionAdmin(session, ability);
    const member = globalState.mySessions.find((s) => s.id === session.id);
    if (showRoles) {
      const roles =
        member?.roles?.map((r) => ({
          id: t(`common.roles.${r}`),
          text: t(`common.roles.${r}`),
          variant: "secondary",
        })) || [];
      if (isAdmin) {
        roles.splice(0, 0, {
          id: t(`common.roles.admin`),
          text: t(`common.roles.admin`),
          variant: "secondary",
        });
      }
      setRoles(roles);
    }
    setLink(
      !isAdmin && !member
        ? getSessionReportRoute({ sessionId: session.id })
        : getSessionRoute({ sessionId: session.id }),
    );
  }, [
    ability,
    globalState.mySessions,
    globalState.spheres,
    session,
    showLanguage,
    showRoles,
    t,
  ]);

  const handleClick = (event: any, mode: FeelingMode, session: Session) => {
    event.stopPropagation();
    const url = getFeelingRoute({
      mode,
      sessionId: session.id,
      category: session.category,
      callerUrl: location.pathname,
    });
    navigateTo(url.replace("//", "/"));
  };

  const handleLink = () => {
    if (link) {
      navigateTo(link);
    }
  };
  const subscription = session.subscription || "starter";
  return (
    <div className={styles.card}>
      <Card
        icon={<SessionIcon session={session} />}
        actionIcon={
          <div className={styles.actionIcons}>
            <Favorite
              entityId={session.id}
              profileAttributeName="favoriteSessionIds"
            />

            <Can
              I="put"
              a={subject("session", { id: session.id })}
              ability={ability}
            >
              <RoundedButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  navigateTo(getAdminSessionRoute({ sessionId: session.id }));
                }}
              >
                <Settings3LineIcon />
              </RoundedButton>
            </Can>
          </div>
        }
        onClick={handleLink}
        title={getSessionName(session, ability)}
      >
        {session.category === "contributor" && (
          <div className={styles.lang}>
            <Badge
              className={styles.subscription}
              bg={subscription === "starter" ? "secondary" : "primary"}
            >
              e-Nous {t(`common.subscription.${subscription}`)}
            </Badge>
          </div>
        )}
        {showTags && (
          <div className={styles.lang}>
            <SessionTags session={session} />
          </div>
        )}
        {showLanguage && (
          <div className={styles.lang}>
            {languagesStatus.map((status) => (
              <Badge
                className={styles.badge}
                key={`lang-${status.id}`}
                bg={status.variant}
              >
                {status.text}
              </Badge>
            ))}
          </div>
        )}
        {showRoles && (
          <div className={styles.roles}>
            {roles.map((status) => (
              <Badge
                className={styles.badge}
                key={`role-${status.id}`}
                bg={status.variant}
              >
                {status.text}
              </Badge>
            ))}
          </div>
        )}

        <div className={styles.bodyContainer}>
          {sphere && <div className={styles.sphere}>{sphere.name}</div>}

          {!hiddenFeeling &&
            session.isMember &&
            session.category === "contributor" && (
              <div className={styles.feelingContainer}>
                <RippleButton
                  onClick={(event: any) => handleClick(event, "bad", session)}
                  src={notHappyFace}
                  alt="nothappyface"
                  size="small"
                />
                <RippleButton
                  onClick={(event: any) => handleClick(event, "good", session)}
                  src={happyFace}
                  alt="happyFace"
                  size="small"
                />
              </div>
            )}
        </div>
      </Card>
    </div>
  );
};

export default SessionCard;
