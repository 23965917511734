/* eslint-disable react/jsx-no-target-blank */
import { FC, memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import styles from "./Footer.module.scss";
import {
  getFeelingRoute,
  getParticipationRoute,
  getReportAdminRoute,
  getScanRoute,
  getSphereJoinRoute,
} from "../../tools/routeTools";
import { getUrlParams } from "../../tools/pathTools";
import happyFace from "../../assets/images/Happy_64.png";
import notHappyFace from "../../assets/images/NotHappy_64.png";
import { FeelingMode } from "../../tools/routeTools";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { ScanIcon } from "../RemixIcons";
import RoundedButton from "../RoundedButton/RoundedButton";
import { isMobile } from "react-device-detect";

const Footer: FC = () => {
  const {
    state: {
      context: { sessionCtx },
    },
  } = useContext(GlobalStateContext);

  const { t } = useTranslation("i18n");
  const navigateTo = useNavigate();
  const location = useLocation();

  const [isFeelingVisible, setIsFeelingVisible] = useState<boolean>(false);
  const [isScanVisible, setIsScanVisible] = useState<boolean>(false);

  useEffect(() => {
    const pathParams = getUrlParams<{
      sphereId: string;
      accessCode: string | undefined;
      campaignId: string;
      reportId: string;
    }>(location.pathname);
    const isJoinRoute = getSphereJoinRoute(pathParams) === location.pathname;
    const isParticipationRoute =
      getParticipationRoute(pathParams) === location.pathname;
    const isReportRoute = getReportAdminRoute(pathParams) === location.pathname;

    const isScanVisible =
      !isJoinRoute &&
      !!sessionCtx &&
      sessionCtx.session.category === "consumer";

    setIsScanVisible(isScanVisible);
    setIsFeelingVisible(
      !isScanVisible && !isJoinRoute && !isParticipationRoute && !isReportRoute,
    );
  }, [location.pathname, sessionCtx]);

  const onFeelingIconClick = (mode: FeelingMode) => {
    const feelingRoute = getFeelingRoute({
      callerUrl: location.pathname,
      mode,
    });
    if (feelingRoute !== location.pathname + location.search) {
      navigateTo(feelingRoute);
    }
  };
  const onScanIconClick = () => {
    navigateTo(getScanRoute({ callerUrl: location.pathname }));
  };
  return (
    <div className={styles.root2}>
      {isScanVisible && (
        <div className="feelingBtns">
          <RoundedButton
            className={styles.scanButton}
            onClick={onScanIconClick}
          >
            <ScanIcon className={styles.scanIcon} />
          </RoundedButton>
        </div>
      )}
      {isFeelingVisible && (
        <div className="feelingBtns">
          <div
            className={styles.wrapperBtn}
            onClick={() => onFeelingIconClick("bad")}
          >
            <img src={notHappyFace} alt="nothappyface" />
          </div>
          <div
            className={styles.wrapperBtn}
            onClick={() => onFeelingIconClick("good")}
          >
            <img src={happyFace} alt="happyface" />
          </div>
        </div>
      )}

      <div className={styles.section}>
        <div className="powerBy">
          {!isMobile && (
            <div
              className={styles.powerbyText}
              dangerouslySetInnerHTML={{ __html: t("footer.title") }}
            />
          )}

          <a target="_blank" href="https://www.seen-apps.com">
            <img className={styles.logo} src="/logo.png" alt="logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
