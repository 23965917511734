import { useTranslation } from "react-i18next";
import { FeelingGoal, GoalHit } from "../../../@types/seen-apps";

import styles from "./GoalForm.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import SubGoalForm from "./SubGoalForm";

type GoalFormProps = {
  value: FeelingGoal;
  onChange: (value: FeelingGoal) => void;
  onDelete: () => void;
};

const GoalForm = ({ value, onChange, onDelete }: GoalFormProps) => {
  const { t } = useTranslation("i18n");
  const handleOnSubGoalChange = async (values: GoalHit[]) => {
    if (value) {
      onChange({
        ...value,
        subs: values,
      });
    }
  };
  const handleOnMainGoalChange = async (hit: GoalHit) => {
    if (value) {
      onChange({
        ...value,
        ...hit,
      });
    }
  };

  const onResetClicked = () => {
    onDelete();
  };
  const onValidateClicked = () => {
    if (value) {
      onChange({
        ...value,
        validated: true,
      });
    }
  };

  return (
    <div className={styles.form}>
      <Container className={styles.noMargin}>
        <Row className={styles.noMargin}>
          <Col sm={12} className={`${styles.noMargin}`}>
            <SubGoalForm
              level={value.level}
              goal={value.id}
              ranking={value.ranking}
              values={value.subs}
              onChange={handleOnSubGoalChange}
              onMainGoalChange={handleOnMainGoalChange}
            />
          </Col>
        </Row>

        <Row className={styles.noMargin}>
          <Col sm={12} className={styles.noMargin}>
            <div className={styles.validate}>
              <Button onClick={onResetClicked}>
                {t("common.action.delete")}
              </Button>
              <Button onClick={onValidateClicked}>
                {t("common.action.validate")}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GoalForm;
