import styles from "./ParticipationMenuItem.module.scss";
import {
  CampaignMesureDefinition,
  StepStatus,
} from "../../@types/campaign-api";
import { useEffect, useState } from "react";

type Props = {
  currentStep: number;
  onClick: (stepId: number) => void;
  definition: CampaignMesureDefinition;
  value: { stepStatus: StepStatus } | undefined;
};

const ParticipationSubMenuItem: React.FC<Props> = ({
  definition,
  currentStep,
  value,
  onClick,
}) => {
  const [mode, setMode] = useState<"done" | "inProgress" | "todo">("todo");

  useEffect(() => {
    setMode(value?.stepStatus || "todo");
  }, [value?.stepStatus, currentStep]);

  const handleClicked = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    onClick(definition.stepId);
  };

  return definition.type === "feeling" || definition.type === "profile" ? (
    <></>
  ) : definition.type === "thinking" && !definition.sessionId ? (
    <></>
  ) : definition.type === "assessment" && !definition.sessionId ? (
    <></>
  ) : (
    <div
      className={`${styles.navSubItem} ${
        definition.stepId === currentStep
          ? styles.selectedSubItem
          : styles[`${mode}SubItem`]
      }`}
      onClick={handleClicked}
    ></div>
  );
};

export default ParticipationSubMenuItem;
