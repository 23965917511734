import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TagLabel.module.scss";

import { getRelevantTranslationFor } from "../../tools/multiLingualTools";
import { Tag } from "../../@types/sphere-api";

export type Item = { label: string; value: string };

type Props = {
  tag: Tag;
  disableColor?: boolean;
  disableBorder?: boolean;
  disableType?: boolean;
};

const TagLabel: React.FC<Props> = ({
  tag,
  disableColor,
  disableBorder,
  disableType,
}) => {
  const { t } = useTranslation("i18n");

  const [type, setType] = useState<string>("");
  const [label, setLabel] = useState<string>("");

  useEffect(() => {
    setType(
      tag.type && !disableType
        ? t(`pages.sphere.tags.section.form.values.type.${tag.type}`)
        : "",
    );
    setLabel(getRelevantTranslationFor(tag.name));
  }, [disableType, t, tag]);

  return (
    <div className={`${styles.root} ${disableBorder ? "" : styles.rootBorder}`}>
      {disableColor ? (
        <></>
      ) : (
        <div
          className={styles.color}
          style={
            tag.color
              ? { backgroundColor: tag.color }
              : { border: "1px solid #61698f78" }
          }
        ></div>
      )}
      {!!type && <span className={styles.type}>{`${type} / `}</span>}

      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default TagLabel;
