import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import i18n_fr from "./i18n/fr.json";
import i18n_en from "./i18n/en.json";
import i18n_nl from "./i18n/nl.json";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import "moment/dist/locale/fr";
import "moment/dist/locale/nl";

import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import nl from "date-fns/locale/nl";
import api from "./api/Api";

export const tranlations = {
  fr: i18n_fr,
  en: i18n_en,
  nl: i18n_nl,
};

const locales = {
  fr: fr,
  en: en,
  nl: nl,
};

const languages = Object.keys(tranlations);

export const getCurrentLanguage = () => i18next.language;
export const getCurrentLocale = () => locales[getCurrentLanguage()] || fr;

export const getLanguages = () => languages;
export const changeLanguage = (language) => {
  window.localStorage.setItem("locale", language);
  setLanguage(language);
};

const setLanguage = (language) => {
  i18next.changeLanguage(language);
  setDefaultLocale(language);
  api.setLanguage(language);
  moment.locale(language);
};

i18next.use(initReactI18next).init({
  resources: languages.reduce(
    (acc, lang) => ({ ...acc, [lang]: { i18n: tranlations[lang] } }),
    {},
  ),

  interpolation: {
    escapeValue: false,
  },
  detection: {},
});

Object.keys(locales).forEach((lang) => {
  registerLocale(lang, locales[lang]);
});

const url = new URL(window.location.href);

const locale =
  window.localStorage.getItem("locale") || url.searchParams.get("lang") || "fr";
setLanguage(locale);

const reactdatagridDict = {
  en: i18n_en.datagrid,
  fr: i18n_fr.datagrid,
  nl: i18n_nl.datagrid,
};

export const getTranslationsDataGrid = () => {
  return reactdatagridDict[locale];
};

export default i18next;
