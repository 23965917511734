import { useContext, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import styles from "./Header.module.scss";
import LayoutContext from "../../context/layout/LayoutContext";
import DropDownContexts from "../dropDownContext/DropDownContexts";
import {
  EarthLineIcon,
  MenuLineIcon,
  RefreshIcon,
  ShieldKeyholeFillIcon,
  ShieldKeyholeLineIcon,
  ShieldUserLineIcon,
} from "../../components/RemixIcons";
import { AccountCircleFillIcon, ScanIcon } from "../RemixIcons";
import { useTranslation } from "react-i18next";
import { GlobalStateCtx } from "../../@types/seen-apps";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { useNavigate, useLocation } from "react-router";
import {
  getGuestRegistrationRoute,
  getScanRoute,
} from "../../tools/routeTools";
import { Button } from "react-bootstrap";
import { isSuperAdminModeEnabled, switchSuperAdminMode } from "../../modes";
import { signOut } from "aws-amplify/auth";
// import { useServiceWorker } from '../../context/ServiceWorkerProvider';

export const SphereIcon = EarthLineIcon;
export const MyselfIcon = ShieldUserLineIcon;

const Header = () => {
  const ctx = useContext(LayoutContext);
  const {
    state: { profile, members },
  } = useContext<GlobalStateCtx>(GlobalStateContext);
  const { t } = useTranslation("i18n");
  const location = useLocation();
  const navigateTo = useNavigate();
  const [showEnableSuperAdmin, setShowEnableSuperAdmin] = useState(false);
  const [isSuperAdmin] = useState(isSuperAdminModeEnabled());

  useEffect(() => {
    setShowEnableSuperAdmin(
      members.some((m) => m.roles.some((r) => r === "super-admin")),
    );
  }, [members]);

  const handleSwithMode = () => {
    switchSuperAdminMode();
    window.location.reload();
  };

  const handleCreateAccount = () => {
    navigateTo(getGuestRegistrationRoute({ callerUrl: location.pathname }));
  };
  const handleLogin = async () => {
    await signOut();
    navigateTo("/");
  };

  return (
    <>
      {profile?.isGuest && (
        <Navbar expand="lg" fixed="top" className={styles.guestBar}>
          <div className={styles.guestMode}>
            <div className={styles.guestModeDesc}>
              {t("pages.guest.warning")}{" "}
            </div>
            <Button
              size="sm"
              variant="guest"
              className={styles.createAccount}
              onClick={handleLogin}
            >
              {t("loginflow.connect")}
            </Button>
            <Button
              size="sm"
              variant="guest"
              className={styles.createAccount}
              onClick={handleCreateAccount}
            >
              {t("pages.guest.createAccount")}
            </Button>
          </div>
        </Navbar>
      )}
      <Navbar
        bg="light"
        expand="lg"
        fixed="top"
        className={`${styles.navBar} ${
          profile?.isGuest ? styles.navBarForGuest : ""
        }`}
      >
        <div className={styles.root}>
          <Navbar.Brand
            className={styles.icon}
            href=""
            onClick={ctx.toggleNavigationMenu}
          >
            <MenuLineIcon />
          </Navbar.Brand>
          <Navbar.Text>
            <DropDownContexts />
          </Navbar.Text>
          <Navbar>
            {showEnableSuperAdmin && isSuperAdmin && (
              <span
                className={`${styles.iconAction} `}
                onClick={handleSwithMode}
              >
                <ShieldKeyholeFillIcon className={styles.superAdminEnabled} />
              </span>
            )}
            {showEnableSuperAdmin && !isSuperAdmin && (
              <span
                className={`${styles.iconAction} ${styles.superAdminNotEnabled}`}
                onClick={handleSwithMode}
              >
                <ShieldKeyholeLineIcon />
              </span>
            )}
            <span
              className={styles.iconAction}
              onClick={() => {
                window.location.reload();
              }}
            >
              <RefreshIcon />
            </span>
            <span
              className={styles.iconAction}
              onClick={() =>
                navigateTo(getScanRoute({ callerUrl: location.pathname }))
              }
            >
              <ScanIcon />
            </span>
            <span className={styles.iconDivider}></span>

            {/* {swInfo.appVisible ? 'visible' : 'hidden'}
            {swInfo.callUpdate ? 'UP' : 'DOWN'} */}
            <span
              className={styles.iconAccount}
              onClick={ctx.toggleSettingsMenu}
            >
              <AccountCircleFillIcon />
            </span>
          </Navbar>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
