import { useEffect, useState } from "react";
import Select from "react-select";
import { OptionType } from "../FacetFilter/MultiSelectFacet";
import selectStyles from "../../styles/react-select.module.scss";
import { useTranslation } from "react-i18next";
import { OptionsType } from "../../@types/webapp-api";

interface Props {
  onChange: (value: string) => void;
  value?: string | undefined;
  className?: string;
  placeholder?: string;
}

export const GENDERS = ["male", "female", "other"];

const GenderInput = ({ value, onChange, className, placeholder }: Props) => {
  const { t } = useTranslation("i18n");
  const [options, setOptions] = useState<OptionsType<OptionType>>([]);

  useEffect(() => {
    if (onChange && value) {
      onChange(value);
    }

    setOptions(
      GENDERS.map((gender) => ({
        label: t(`pages.user.genderValues.${gender}`),
        value: gender,
        count: 0,
      })),
    );
  }, [t]);

  const handleOnChange = (valueSelected: any) => {
    if (onChange) {
      onChange(valueSelected.value);
    }
  };

  return (
    <Select
      onChange={handleOnChange}
      isSearchable={false}
      className={`${selectStyles.reactSelect} ${className}`}
      classNamePrefix="custom-react-select"
      value={options.find((option) => option.value === value)}
      options={options}
      isMulti={false}
      placeholder={placeholder || ""}
      isClearable={false}
      noOptionsMessage={() => t("common.noValue")}
    />
  );
};

export default GenderInput;
