import { Container, Row, Col } from "react-bootstrap";
import { HistoryLineIcon } from "../RemixIcons";
import styles from "./DateView.module.scss";
import { FeelingEvaluationPeriod } from "../../@types/webapp-api";
import { useTranslation } from "react-i18next";

interface Props {
  period: FeelingEvaluationPeriod;
}

const PeriodView = ({ period }: Props) => {
  const { t } = useTranslation("i18n");
  if (!period) {
    return <div></div>;
  }
  return (
    <Container>
      <Row>
        <Col xs={1} className={styles.iconCol}>
          <HistoryLineIcon className={styles.icon} />
        </Col>
        <Col className={styles.noMarginText}>
          {t(
            `pages.feeling.subTitle.feeling.duration.${period.value} ${period.type}`,
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PeriodView;
